import React, { Dispatch, Fragment, SetStateAction, useCallback } from "react";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";

import Modal from "components/common/Modal";
import TermInfo from "components/terms/TermsInfo";

import { TERM } from "utils/types/term";

interface Props {
  terms: TERM[];
  schoolYear: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  loading: boolean;
}

function TermInfosModal(props: Props) {
  const {
    terms,
    schoolYear,
    showModal,
    setShowModal,
    loading: loadingProps
  } = props;

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <Modal
      isOpen={showModal}
      onClose={closeModal}
      title={`Terms for school year ${schoolYear}`}
    >
      <div>
        {!loadingProps && (
          <PDFDownloadLink
            fileName={`${schoolYear} Terms.pdf`}
            document={
              <Document>
                <TermInfo terms={terms} schoolYear={schoolYear} />
              </Document>
            }
            className='btn-download-header'
          ></PDFDownloadLink>
        )}
        {terms.map((x: any) => (
          <Fragment key={`details-${x.termNb}`}>
            <p className='border border-slate-700 border-l-0 border-r-0 py-4'>
              <span className='mr-3 font-bold'>{`Term ${x.termNb}`}</span>
              {x.start !== "" && x.end !== ""
                ? `from ${x.start} to ${x.end}`
                : ""}
            </p>
            <div className='py-4'>
              {x.weeks.map((y: any) => (
                <Fragment key={`details-week-${y.weekNb}`}>
                  {y.start !== "" && y.end !== "" ? (
                    <p>{`Week ${y.weekNb} : ${y.start} - ${y.end}`} </p>
                  ) : null}
                </Fragment>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </Modal>
  );
}

export default TermInfosModal;
