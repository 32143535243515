import React, { useMemo } from "react";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface Props {
  color: string;
}

const RegistrationInfos = ({ color }: Props) => {
  const displayedYears = useMemo(() => {
    const date = new Date();
    const thisYear = date.getFullYear();
    const thisMonth = date.getMonth();
    if (thisMonth > 1) {
      return `${thisYear}-${thisYear + 1}`;
    }
    return `${thisYear - 1}-${thisYear}`;
  }, []);

  return (
    <div className='registration-infos'>
      <h2 className={`${color} text-xl font-bold text-center`}>
        How to register
      </h2>
      <LazyLoadImage
        src='/images/daycare2.png'
        width={150}
        height={60}
        className='m-auto mt-4'
      />

      <p>Complete the form below to register</p>
      <a
        href='https://forms.gle/Y67oopKgY3aM7Scu8'
        target='_blank'
        rel='noreferrer'
      >
        <FontAwesomeIcon icon={faCaretRight} className={`mr-2 ${color}`} />
        <span className={`${color} font-semibold`}>Registration form</span>
      </a>

      <p className='mt-8 pb-2 font-semibold'>Documents needed</p>
      <a href='REGISTRATION-DOCUMENTS.pdf' target='_blank' rel='noreferrer'>
        <FontAwesomeIcon icon={faCaretRight} className={`mr-2 ${color}`} />
        <span className={`${color} font-semibold`}>Registration documents</span>
        <br />
        <span className='ml-4'>- Identification form</span>
        <br />
        <span className='ml-4'>- Emergency contacts</span>
        <br />
        <span className='ml-4'>- Medical Report</span>
        <br />
        <span className='ml-4'>- School Contract</span>
      </a>

      <p className='py-2'>
        <FontAwesomeIcon icon={faCaretRight} className={`mr-2 ${color}`} />
        <span>1 Residence certificate</span>
      </p>

      <p className='pb-2'>
        <FontAwesomeIcon icon={faCaretRight} className={`mr-2 ${color}`} />
        <span>4 ID Photos</span>
      </p>

      <p className='pb-2'>
        <FontAwesomeIcon icon={faCaretRight} className={`mr-2 ${color}`} />
        <span>Total Application Amount</span>
      </p>

      <p className='pb-2'>
        <FontAwesomeIcon icon={faCaretRight} className={`mr-2 ${color}`} />
        {`${displayedYears} Report card (from Grade 1 to Grade 8)`}
      </p>
    </div>
  );
};

export default RegistrationInfos;
