import { COLUMN } from "utils/types/general";

export const STUDENT_TABLE_COLUMNS: COLUMN[] = [
  { title: "Code", sortable: false, slug: "codeEleve" },
  { title: "Name", sortable: false, slug: "name" },
  { title: "Level", sortable: false, slug: "level" }
];

export const TEACHERS_TABLE_COLUMNS: COLUMN[] = [
  { title: "Username", sortable: false, slug: "username" },
  { title: "Password", sortable: false, slug: "password" },
  { title: "Level", sortable: false, slug: "level" }
];
