import React, { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { InputSwitch } from "primereact/inputswitch";

import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import DdlUser from "components/dropdowns/DdlUser";
import DdlPaymentMethod from "components/dropdowns/DdlPaymentMethod";
import InvoicePricesForm from "components/invoices/InvoicePricesForm";
import InvoiceRepeater from "components/invoices/InvoiceRepeater";
import Loader from "components/common/Loader";

import { InvoicesAPI } from "api";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { OPTIONS } from "utils/types/general";
import { INVOICE, INVOICE_ACTIONS, INVOICE_PAYMENT } from "utils/types/invoice";
import { INVOICE_USER, USER } from "utils/types/user";
import {
  generateInvoiceReference,
  getAmountDue,
  getAmountPaid,
  getAmountStr,
  getTotalAmount
} from "utils/format/invoice";
import moment from "moment";

interface Props {
  data: INVOICE;
  action: INVOICE_ACTIONS;
  refetch: any;
  closeModal: any;
  users: USER[];
}

function InvoiceForm(props: Props) {
  const { data, users, action, refetch, closeModal } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<INVOICE>({
    defaultValues: data
  });

  const onSubmit: SubmitHandler<INVOICE> = useCallback(async (formData) => {
    const dataToSend: INVOICE = {
      ...formData,
      penalties: formData.penalties.filter(
        (penalty: INVOICE_PAYMENT) => penalty.amount > 0
      ),
      payments: formData.payments.filter(
        (payment: INVOICE_PAYMENT) => payment.amount > 0
      )
    };

    if (formData.id) {
      await InvoicesAPI.update(dataToSend);
    } else {
      await InvoicesAPI.add(dataToSend);
    }
    setLoading(false);
    setTimeout(() => {
      refetch();
      closeModal();
    }, 1000);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <div id='lg-left'>
          <div id='student'>
            <p className='font-bold pb-2'>Student</p>
            {watch("id") === undefined ? (
              <>
                <div
                  className={`${FORM_GROUP_STYLE} ${
                    isSubmitted || errors.student
                      ? errors.student
                        ? "border-rose-600"
                        : "border-lime-500"
                      : ""
                  }`}
                >
                  <Controller
                    control={control}
                    name='student'
                    rules={{ required: "Please choose a student" }}
                    render={({ field: { onChange, value } }) => (
                      <DdlUser
                        placeholder='Choose student'
                        className='w-full mr-4'
                        hideTeachers
                        data={users}
                        onChange={async (e: USER) => {
                          const selectedUser: INVOICE_USER = {
                            id: e.id,
                            codeEleve: e.codeEleve,
                            role: e.role,
                            level: e.level,
                            name: e.name,
                            active: e.active,
                            username: e.username,
                            address: e.address,
                            dad: e.dad,
                            mom: e.mom
                          };
                          setLoading(true);
                          onChange(selectedUser);
                          const resReference = await generateInvoiceReference(
                            e.codeEleve
                          );
                          const results = await Promise.all([resReference]);
                          setValue("reference", results[0]);
                          setValue("codeEleve", selectedUser.codeEleve);
                          setLoading(false);
                        }}
                        disabled={loading || isSubmitting}
                        value={
                          value
                            ? {
                                label: `${value.codeEleve} ${value.name}`,
                                value: value.codeEleve
                              }
                            : undefined
                        }
                      />
                    )}
                  />
                </div>
                {errors.student && (
                  <span className={ERROR_STYLE}>{errors.student.message}</span>
                )}
              </>
            ) : (
              <p className='mb-6'>{`${watch("student").codeEleve} ${watch("student").name}`}</p>
            )}
          </div>
          <div
            id='reference-school_year'
            className='grid grid-cols-1 lg:grid-cols-2 gap-4'
          >
            <div id='reference'>
              <p className='font-bold pb-2'>Reference</p>
              <div
                className={`${FORM_GROUP_STYLE} ${
                  isSubmitted || errors.reference
                    ? errors.reference
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <input
                  disabled={true}
                  className={`${INPUT_STYLE} min-h-[51px]`}
                  placeholder='Invoice reference'
                  type='text'
                  {...register("reference", {
                    required: "Reference is required"
                  })}
                />
                {(isSubmitted || errors.reference) && (
                  <div
                    className={`block absolute rounded-3xl top-6 right-5 w-2 h-2 ${
                      errors.reference ? "bg-rose-600" : "bg-lime-500"
                    }`}
                  ></div>
                )}
              </div>
              {errors.reference && (
                <span className={ERROR_STYLE}>{errors.reference.message}</span>
              )}
            </div>

            <div id='school_year'>
              <p className='font-bold pb-2'>School Year</p>
              <div
                className={`${FORM_GROUP_STYLE} ${
                  isSubmitted || errors.school_year
                    ? errors.school_year
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <Controller
                  control={control}
                  name='school_year'
                  rules={{ required: "Please provide a school year" }}
                  render={({ field: { onChange, value } }) => (
                    <DdlSchoolYear
                      onChange={(e: OPTIONS) => onChange(e.value)}
                      value={value ? { label: value, value } : undefined}
                    />
                  )}
                />
              </div>
              {errors.school_year && (
                <span className={ERROR_STYLE}>
                  {(errors as any).school_year.message}
                </span>
              )}
            </div>
          </div>
          <div id='dates' className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
            {["date_issue", "date_due"].map((dateKey: string) => {
              const label =
                dateKey === "date_issue" ? "issuing date" : "due date";
              return (
                <div id={dateKey} key={dateKey}>
                  <p className='font-bold pb-2 capitalize'>{label}</p>
                  <div
                    className={`${FORM_GROUP_STYLE} ${
                      isSubmitted || errors[dateKey]
                        ? errors[dateKey]
                          ? "border-rose-600"
                          : "border-lime-500"
                        : ""
                    }`}
                  >
                    <Controller
                      control={control}
                      name={dateKey as any}
                      rules={{
                        required:
                          dateKey === "date_issue"
                            ? "Please choose a date"
                            : false
                      }}
                      render={({ field: { onChange, value } }) => (
                        <input
                          disabled={isSubmitting}
                          className={INPUT_STYLE}
                          placeholder='MM/DD/YYYY'
                          type='date'
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                    />
                    {(isSubmitted || errors[dateKey]) && (
                      <div
                        className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                          errors[dateKey] ? "bg-rose-600" : "bg-lime-500"
                        }`}
                      ></div>
                    )}
                  </div>
                  {errors[dateKey] && (
                    <span className={ERROR_STYLE}>
                      {errors[dateKey].message}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
          <div id='paid'>
            <p className='font-bold pb-2'>Paid</p>
            <div className='flex flex-row flex-wrap justify-between items-center'>
              <Controller
                control={control}
                name='paid'
                render={({ field: { onChange, value } }) => (
                  <div className='flex flex-row items-center'>
                    <InputSwitch
                      checked={value === 1}
                      onChange={(e) => {
                        const paidValue = e.value;
                        onChange(paidValue ? 1 : 0);

                        setValue(
                          "date_paid",
                          paidValue
                            ? moment(new Date()).format("YYYY-MM-DD")
                            : ""
                        );
                        setValue("type", paidValue ? "receipt" : "invoice");
                        setValue(
                          "amount_paid",
                          paidValue ? getTotalAmount(watch()) : 0
                        );
                      }}
                    />
                  </div>
                )}
              />
              <p
                className={`font-bold ${watch("paid") === 1 ? "bg-esaMinty" : "bg-rose-500"} px-4 py-2 rounded-lg text-white`}
              >
                {watch("paid") === 1 ? "receipt" : "invoice"}
              </p>
            </div>
          </div>

          <div id='paymentMethod' className='mt-2'>
            <p className='font-bold pb-2'>Payment method</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.paymentMethod
                  ? errors.paymentMethod
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='paymentMethod'
                rules={{
                  required: false
                }}
                render={({ field: { onChange, value } }) => (
                  <DdlPaymentMethod onChange={onChange} value={value} />
                )}
              />
            </div>
            {errors.paymentMethod && (
              <span className={ERROR_STYLE}>
                {(errors as any).paymentMethod.message}
              </span>
            )}
          </div>

          <div id='description'>
            <p className='font-bold pb-2'>Description</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.description
                  ? errors.description
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <textarea
                disabled={isSubmitting}
                className={INPUT_STYLE}
                placeholder='Add notes here'
                {...register("description", { required: false })}
              ></textarea>

              {(isSubmitted || errors.description) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.description ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.description && (
              <span className={ERROR_STYLE}>{errors.description.message}</span>
            )}
          </div>
        </div>
        <div id='lg-right'>
          <div id='details'>
            <InvoicePricesForm
              watch={watch}
              register={register}
              unregister={unregister}
              setValue={setValue}
              isSubmitted={isSubmitted}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          </div>

          <p className='py-4'>{`10 % of total Amount = ${getAmountStr((getTotalAmount(watch()) * 10) / 100)} Ar`}</p>

          <InvoiceRepeater
            formKey='penalties'
            watch={watch}
            control={control}
            register={register}
            unregister={unregister}
            setValue={setValue}
            errors={errors}
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            title='Penalties'
            textTotal='Total penalties'
          />

          <InvoiceRepeater
            formKey='payments'
            watch={watch}
            control={control}
            register={register}
            unregister={unregister}
            setValue={setValue}
            errors={errors}
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            title='Payments'
            textTotal='Total paid'
          />

          <div className='rounded-lg mt-8 border-lime-500 border-2 flex flex-wrap items-center justify-between p-4'>
            <p>Amount paid</p>
            <p>{`${getAmountStr(getAmountPaid(watch()) < 0 ? 0 : getAmountPaid(watch()))} Ar`}</p>
          </div>
          <div className='rounded-lg mt-8 border-rose-500 border-2 flex flex-wrap items-center justify-between p-4'>
            <p>Rest Due</p>
            <p>{`${getAmountStr(getAmountDue(watch()) < 0 ? 0 : getAmountDue(watch()))} Ar`}</p>
          </div>
          {getAmountDue(watch()) < 0 && (
            <p className='text-right italic text-slate-400'>{`${getAmountStr(Math.abs(getAmountDue(watch())))} Ar overpaid`}</p>
          )}
          <div
            id='btnSubmit'
            className='w-full flex items-center justify-end mt-4'
          >
            {isSubmitting || loading ? (
              <div className={`${SUBMIT_STYLE} mr-0 ml-0 bg-gray-100`}>
                <Loader width='w-6' height='h-6' text='text-gray-300' />
              </div>
            ) : (
              <input
                type='submit'
                disabled={isSubmitting}
                value={action === "add" ? "Create" : "Save"}
                className={`${SUBMIT_STYLE} mr-0 ml-0 bg-blue-300`}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default InvoiceForm;
