/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from "react";
import axios from "axios";
import { USER } from "utils/types/user";

interface UserContextType {
  token: string;
  isAuth: boolean;
  ctxUser: USER;
  changeToken: (token: string) => void;
  changeAuthStatus: (auth: boolean) => void;
  changeUser: (data: any) => void;
}

const UserContext = createContext<UserContextType>({
  token: "",
  isAuth: false,
  ctxUser: {
    id: "",
    name: "",
    address: "",
    mom: {
      tel: "",
      email: "",
      name: ""
    },
    codeEleve: "",
    dob: "",
    username: "",
    dad: {
      tel: "",
      email: "",
      name: ""
    },
    role: "",
    level: "",
    emergencyContact: [
      {
        name: "",
        email: "",
        tel: ""
      },
      {
        name: "",
        email: "",
        tel: ""
      }
    ],
    nationality: "",
    active: false
  },
  changeToken: () => {},
  changeAuthStatus: () => {},
  changeUser: () => {}
});

export const UserProvider = ({ children }: any) => {
  const [token, setToken] = useState<string>("");
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [ctxUser, setCtxUser] = useState<any>({});

  useEffect(() => {
    let authenticated = false;
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") !== "null"
    ) {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      authenticated = true;
    } else {
      authenticated = false;
    }
    setIsAuth(authenticated);
  }, []);

  const changeToken = useCallback((newToken) => {
    setToken(newToken);
  }, []);

  const changeAuthStatus = useCallback((newAuth) => {
    setIsAuth(newAuth);
  }, []);

  const changeUser = useCallback((newUser) => {
    setCtxUser(newUser);
  }, []);

  return (
    <UserContext.Provider
      value={{
        token,
        isAuth,
        ctxUser,
        changeToken,
        changeAuthStatus,
        changeUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext<UserContextType>(UserContext);
