import React, { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";

import { UsersAPI } from "api";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { USER } from "utils/types/user";
import { getArrayOfValues, getDefaultLevelOptions } from "utils/format";
import DdlLevel from "components/dropdowns/DdlLevel";

interface Props {
  data?: USER;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function TeacherForm(props: Props) {
  const { data, action, refetch, closeModal } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<USER>({
    mode: "onChange",
    defaultValues: {
      ...data
    }
  });

  const addUpdate = useCallback(
    (dataToSend: any) => {
      if (dataToSend.id || action === "edit") {
        UsersAPI.update(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      } else {
        const addData = {
          ...dataToSend,
          codeEleve: dataToSend.username,
          name: dataToSend.username,
          role: "TUTOR",
          level: dataToSend.level === "" ? [] : dataToSend.level,
          address: "",
          dob: "",
          mom: {
            tel: "",
            email: "",
            name: ""
          },
          dad: {
            tel: "",
            email: "",
            name: ""
          },
          emergencyContact: [
            {
              name: "",
              email: "",
              tel: ""
            },
            {
              name: "",
              email: "",
              tel: ""
            }
          ]
        };

        UsersAPI.add(addData)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      }
    },
    [action]
  );

  const onSubmit: SubmitHandler<USER> = async (data) => {
    setLoading(true);
    addUpdate({ ...data });
  };

  useEffect(() => {
    if (action === "edit") {
      trigger(); // triggers validation but does not submit the form
    }
  }, [action]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 gap-4'>
        <div id='username'>
          <p className='font-bold pb-2'>Username</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.username
                ? errors.username
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              className={INPUT_STYLE}
              placeholder='Username'
              type='text'
              {...register("username", {
                required: "Username is required"
              })}
            />
            {(isSubmitted || errors.username) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.username ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.username && (
            <span className={ERROR_STYLE}>{errors.username.message}</span>
          )}
        </div>

        <div id='password'>
          <p className='font-bold pb-2'>Password</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.password
                ? errors.password
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              className={INPUT_STYLE}
              placeholder='Password'
              type='text'
              {...register("password", { required: true })}
            />
            {(isSubmitted || errors.password) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.password ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.password && (
            <span className={ERROR_STYLE}>Password is required</span>
          )}
        </div>

        <div id='Level'>
          <p className='font-bold pb-2'>Level</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.level
                ? errors.level
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='level'
              rules={{ required: "Please provide a level" }}
              render={({ field: { onChange, value } }) => (
                <DdlLevel
                  isMulti
                  onChange={(e) => {
                    onChange(getArrayOfValues(e));
                  }}
                  value={value ? getDefaultLevelOptions(value) : undefined}
                />
              )}
            />
          </div>
          {errors.level && (
            <span className={ERROR_STYLE}>{(errors as any).level.message}</span>
          )}
        </div>
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create teacher" : "Update teacher"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default TeacherForm;
