import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { LevelsAPI } from "api";
import { LEVEL } from "utils/types/level";
import { OPTIONS } from "utils/types/general";

interface Props {
  onChange: any;
  data?: LEVEL[];
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlLevel(props: Props) {
  const {
    onChange,
    data = undefined,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false
  } = props;
  const [levels, setLevels] = useState<LEVEL[]>([]);

  const getLevels = useCallback(async () => {
    const resLevelPromise = LevelsAPI.search(null, null, true).then((r) => r);
    const res = await Promise.all([resLevelPromise]);
    setLevels(res[0].data);
  }, []);

  const options = useMemo(() => {
    return levels.map((level: LEVEL) => {
      return {
        label: level.label,
        value: level.value
      };
    });
  }, [levels]);

  const handleChange = useCallback((e: any) => {
    onChange(e);
  }, []);

  useEffect(() => {
    if (!data) {
      getLevels();
    } else {
      setLevels(data);
    }
  }, [data]);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder='Level'
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlLevel;
