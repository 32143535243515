import React from "react";

import PricesInfos from "components/PricesInfos";
import RegistrationInfos from "components/RegistrationInfos";

import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  LG_CENTERED
} from "utils/styles";

const TITLE_PRESCHOOL = "text-esaTextLightBlue font-bold pt-8";
function Page() {
  return (
    <div id='preschool-page' className={BG_COLORED}>
      <section
        id='main-banner'
        className='min-h-96 relative flex flex-col justify-center'
        style={{
          background: "url('/images/preschool1.PNG')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom"
        }}
      >
        <div className='absolute top-0 left-0 bg-black w-full h-full opacity-60 z-0' />
        <div
          className={`relative ${CONTAINER} ${LG_CENTERED} text-white text-center h-full flex flex-col justify-center`}
        >
          <h1 className='text-3xl font-bold uppercase'>PRESCHOOL</h1>
          <p className='font-semibold text-xl pb-8'>
            Preschool - Pre-kindergarden - Kindergarden
          </p>
          <p className='text-lg'>
            This age is all about expressions, when kids really start to form
            their own ideas about what they want to play and how they want to
            create. Every day in our preschool classroom, your child will
            explore science experiments, create artwork, and play pretend-all
            the skills needed for their big next step: elementary school !
          </p>
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING} pt-0`}>
        <div
          className={`rounded-lg shadow-lg overflow-hidden bg-white p-6 rounded-t-none`}
        >
          <h2 className={`${TITLE_PRESCHOOL}`}>
            DURING THE DAY OF A PRESCHOOLER
          </h2>
          <p>
            Our caring teachers set a daily rythm for preschoolers that consists
            of group time, story time, outdoor play, meals and snacks, and naps,
            and especially, lots of unstructured playtime.
          </p>

          <h2 className={`${TITLE_PRESCHOOL}`}>
            EVERY DAY IS A LITTLE DIFFERENT BUT IT"S ALWAYS FUN !
          </h2>
          <p>
            Our classrooms, from discovery preschool to kindergarten, follow a
            daily rythm. Our incredible teachers create a schedule that works
            for their room and age group.
          </p>

          <h2 className={`${TITLE_PRESCHOOL}`}>MEALS AND SNACKS</h2>
          <p>
            Every day, preschoolders sit down to a meal, where they eat healthy
            fruits and vegetables, lean proteins, and whole grains-never juice
            or fried food.
          </p>

          <h2 className={`${TITLE_PRESCHOOL}`}>OUTOOR PLAYTIME</h2>
          <p>
            Most of our classes spend 20-30 minutes outside, weather permitting.
            They can really get going at this age ! It"s a time to explore and
            play using their imagination.
          </p>
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <RegistrationInfos color='text-esaTextLightBlue' />
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <PricesInfos type='pre_school' color='text-esaTextLightBlue' />
        </div>
      </section>
    </div>
  );
}

export default Page;
