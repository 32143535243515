import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import { OPTIONS } from "utils/types/general";

import { WEEK } from "utils/types/term";

interface Props {
  onChange: any;
  data: WEEK[];
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlWeek(props: Props) {
  const {
    onChange,
    data = undefined,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false
  } = props;

  const options = useMemo(() => {
    const weeks = (data || [])
      .filter((a: WEEK) => a.start !== "" && a.end !== "")
      .map((week: WEEK) => {
        return {
          label: `Week ${week.weekNb}`,
          value: week.weekNb
        };
      });

    return weeks;
  }, [data]);

  const handleChange = useCallback(
    (e: any) => {
      if (isMulti) {
        const selectedItems = (e || []).map((item: any) => item.value);

        const resultingItems = data.filter((week: WEEK) =>
          selectedItems.includes(week.weekNb)
        );
        onChange(resultingItems);
      } else {
        const resultingItem = data.find(
          (week: WEEK) => week.weekNb === e.value
        );
        onChange(
          e.value === "overall"
            ? {
                weekNb: "overall",
                start: "",
                end: "",
                files: []
              }
            : resultingItem
        );
      }
    },
    [data]
  );

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder='Week'
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlWeek;
