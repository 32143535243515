/* eslint-disable react/prop-types */
import {
  faClose,
  faFileAlt,
  faSave,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";
import { FileUpload as PRFileUpload } from "primereact/fileupload";

interface Props {
  onSubmit: (e: any) => void;
  data: any;
  autoSubmit: boolean;
}

function FileUpload(props: Props) {
  const { onSubmit, data, autoSubmit } = props;

  const fileUploadRef = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const [existingFiles, setExistingFiles] = useState<any>(data);
  const [removedFiles, setRemovedFiles] = useState<any>([]);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [disableClear, setDisableClear] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleSubmit = useCallback(() => {
    // let temp = 0;
    // [...e.files].forEach((file) => { temp += file.size; return temp; });
    // setTotalSize(temp);

    onSubmit({
      uploadedFiles: uploadedFiles || [],
      existingFiles,
      removedFiles
    });
    toast.success("Files saved successfully", { position: "bottom-right" });
  }, []);

  const onTemplateSelect = useCallback((e) => {
    // let temp = totalSize;
    // [...e.files].forEach((file) => { temp += file.size; return temp; });
    // setTotalSize(temp);
    const temp = [...uploadedFiles];
    [...e.files].forEach((file) => temp.push(file));
    setDisableSave(false);

    setUploadedFiles(temp);

    if (autoSubmit) {
      onSubmit({
        uploadedFiles: temp.length > 0 ? temp : [],
        existingFiles,
        removedFiles
      });
    }
  }, []);

  const onTemplateRemove = useCallback(
    (file, callback) => {
      setTotalSize(totalSize - file.size);
      callback();
      const files = [...uploadedFiles].filter(
        (a: File) => a.name !== file.name
      );

      if (autoSubmit) {
        onSubmit({
          uploadedFiles: files.length > 0 ? files : [],
          existingFiles,
          removedFiles
        });
      }
    },
    [uploadedFiles]
  );

  const onTemplateClear = useCallback(() => {
    setTotalSize(0);
    setExistingFiles([]);
    setRemovedFiles([]);
    setDisableSave(false);
    setDisableClear(true);

    if (autoSubmit) {
      onSubmit({
        uploadedFiles: [],
        existingFiles: [],
        removedFiles: []
      });
    }
  }, []);

  const headerTemplate = (options) => {
    const { chooseButton } = options;
    return (
      <div className='flex flex-row flex-wrap items-center p-3 bg-slate-50 gap-3'>
        {chooseButton}
        {!autoSubmit && (
          <button
            className='px-3 py-2 rounded-md bg-esaMinty flex items-center justify-center min-h-12 text-white'
            onClick={handleSubmit}
            disabled={disableSave}
          >
            <FontAwesomeIcon icon={faSave} className='text-lg' />
            <span className='ml-3'>Save files</span>
          </button>
        )}
        <button
          className='px-3 py-2 rounded-md bg-red-500 text-white flex items-center justify-center min-h-12'
          onClick={onTemplateClear}
          disabled={disableClear}
        >
          <FontAwesomeIcon icon={faClose} className='text-lg' />
          <span className='ml-2'>Clear</span>
        </button>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className='flex items-center justify-between py-2 px-0 border-b'>
        <p>{file.name}</p>

        <div className='flex flex-wrap items-center justify-center'>
          <p className='bg-amber-400 px-3 py-2 mr-3 text-white rounded-lg'>
            {props.formatSize}
          </p>
          <button
            className='min-w-9 w-9 h-9 flex items-center justify-center rounded-full bg-rose-500 hover:bg-rose-200 text-white'
            onClick={() => onTemplateRemove(file, props.onRemove)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className='flex flex-col items-center justify-center'>
        <FontAwesomeIcon icon={faFileAlt} className='text-3xl' />
        <p className='py-3 text-slate-500'>Drag and Drop Files Here</p>
      </div>
    );
  };

  const chooseOptions = {
    iconOnly: false,
    className: "bg-blue-400",
    label: "Choose from computer"
  };
  const uploadOptions = {
    iconOnly: false,
    className: "bg-esaMinty",
    label: "Save changes"
  };
  const cancelOptions = {
    iconOnly: false,
    className: "bg-rose-500",
    label: "Clear"
  };

  return (
    <div>
      <PRFileUpload
        ref={fileUploadRef}
        name='files'
        multiple
        accept='*'
        customUpload
        uploadHandler={handleSubmit}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        className='file-upload-multiple'
      />

      {existingFiles &&
        existingFiles.length > 0 &&
        existingFiles.map((b: any, idx: number) => (
          <div
            key={`file-${idx}`}
            className='flex items-center justify-between p-2 border-b'
            onClick={() => window.open(b.url, "_blank")}
          >
            <p>{b.name}</p>

            <div className='flex flex-wrap items-center justify-center'>
              <p className='bg-amber-400 px-3 py-2 mr-3 text-white rounded-lg'>
                {`${b.size / 100} KB`}
              </p>
              <button
                className='min-w-9 w-9 h-9 flex items-center justify-center rounded-full bg-rose-500 hover:bg-rose-200 text-white'
                onClick={(e) => {
                  e.stopPropagation();
                  const exists = [...existingFiles];
                  exists.splice(idx, 1);
                  setExistingFiles(exists);

                  const removed = [...removedFiles];
                  removed.push(b);
                  setRemovedFiles(removed);

                  onSubmit({
                    uploadedFiles,
                    existingFiles: exists,
                    removedFiles: removed
                  });
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default FileUpload;
