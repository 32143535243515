import React from "react";
import { useParams } from "react-router-dom";

import DashboardAdmin from "components/dashboard/DashboardAdmin";
import DashboardLecturer from "components/dashboard/DashboardLecturer";
import DashboardParent from "components/dashboard/DashboardParent";

function Page() {
  const { role } = useParams();

  switch (role) {
    case "admin":
      return <DashboardAdmin />;
    case "secretariat":
      return <DashboardAdmin />;
    case "tutor":
      return <DashboardLecturer />;
    case "parent":
      return <DashboardParent />;
    default:
      return null;
  }
}

export default Page;
