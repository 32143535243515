import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <div className='font-bold w-screen h-screen flex flex-col items-center justify-center'>
      Oops ! an error occured
      <Link to='/' className='underline text-sky-500 cursor-pointer'>
        Go back to the home page
      </Link>
    </div>
  );
}

export default Error;
