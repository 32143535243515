import moment from "moment";
import { DEFAULT_HEADER_VALUES } from "utils/types/agenda";
import { TERM } from "utils/types/term";

export const getDefaultAgendaHeaders = (
  terms: TERM[]
): DEFAULT_HEADER_VALUES => {
  const DEFAULT: DEFAULT_HEADER_VALUES = {
    term: undefined,
    week: {
      weekNb: "1",
      start: moment().day(1).format("YYYY-MM-DD"),
      end: moment().day(5).format("YYYY-MM-DD"),
      files: []
    }
  };

  if (terms && terms.length > 0) {
    const myDate = moment(new Date()).format("YYYY-MM-DD");

    const currentInterval = terms
      .filter((t: any) => {
        const today = moment(myDate);
        const termStart = moment(t.start);
        const termEnd = moment(t.end);

        if (today >= termStart && today <= termEnd) {
          return t;
        }
        return false;
      })
      .filter((value: any) => value);

    const result =
      currentInterval.length > 0
        ? currentInterval[0]
        : terms.filter((interval: any) => interval.termNb === "1")[0];

    if (result) {
      let currentW: any = result.weeks.filter((week: any) =>
        moment(moment(myDate).format("YYYY-MM-DD")).isBetween(
          week.start,
          week.end,
          null,
          "[]"
        )
      );
      currentW = currentW.length > 0 ? currentW : [result.weeks[0]];

      return {
        term: result,
        week: currentW[0]
      };
    }

    return DEFAULT;
  }

  return DEFAULT;
};
