import React, { useCallback, useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch
} from "react-hook-form";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DdlPrices from "components/dropdowns/DdlPrices";

import { PriceAPIv2 } from "api";

import { getAmountStr } from "utils/format/invoice";
import { ERROR_STYLE, INPUT_STYLE } from "utils/styles";
import { INVOICE, INVOICE_PRICE } from "utils/types/invoice";
import { PRICE_DETAIL } from "utils/types/prices";
import { formatAmountOfPrices, formatDdlPrices } from "utils/format/prices";

interface Props {
  watch: UseFormWatch<INVOICE>;
  register: UseFormRegister<INVOICE>;
  unregister: UseFormUnregister<INVOICE>;
  setValue: UseFormSetValue<INVOICE>;
  isSubmitted: boolean;
  errors: FieldErrors<INVOICE>;
  isSubmitting: boolean;
}

function InvoicePricesForm(props: Props) {
  const {
    watch,
    register,
    unregister,
    setValue,
    errors,
    isSubmitted,
    isSubmitting
  } = props;

  const [prices, setPrices] = useState<PRICE_DETAIL[]>([]);
  const [showDdlPrices, setShowDdlPrices] = useState<boolean>(false);
  const [expandPrices, setExpandPrices] = useState<boolean>(false);
  const data = watch("details");

  const fetchPrices = useCallback(async () => {
    const fromPriceV1 = data.map((item: INVOICE_PRICE) => {
      return {
        id: item.id,
        amount: item.amount,
        description: item.description
      };
    });

    const resPriceV2 = await PriceAPIv2.getInvoicePricesV2().then(
      (r: any) => r.data
    );
    const results = await Promise.all([resPriceV2]);
    const formattedResults = formatDdlPrices(results[0]);

    if (fromPriceV1) {
      const mergedArray = [
        ...formattedResults,
        ...formatAmountOfPrices(fromPriceV1)
      ];
      const uniquesById = [
        ...new Map(mergedArray.map((item) => [item.id, item])).values()
      ];
      setPrices(
        uniquesById
          .sort((a, b) => a.description.localeCompare(b.description))
          .filter(
            (item: PRICE_DETAIL) =>
              item.amount !== 0 && !item.id.includes("2021_2022")
          )
      );
    } else {
      setPrices(
        formattedResults
          .sort((a, b) => a.description.localeCompare(b.description))
          .filter(
            (item: PRICE_DETAIL) =>
              item.amount !== 0 && !item.id.includes("2021_2022")
          )
      );
    }
  }, [data]);

  useEffect(() => {
    fetchPrices();
  }, []); // only on first load as we take first details values

  return (
    <>
      <div
        className={`bg-slate-100 px-4 py-4 mt-4 rounded-lg rounded-b-none border  ${data.length === 0 || !expandPrices ? "border-b" : "border-b-0"}`}
      >
        <div className='flex justify-between items-center'>
          <p>
            <span className='font-bold mr-2'>Price Details</span>
            <span>{`(${data.length} items)`}</span>
          </p>
          <div className='flex items-center justify-center gap-4'>
            <button
              type='button'
              className='w-max shadow-lg bg-esaMintyDark px-2 py-1 rounded-lg h-8'
              onClick={async () => {
                setShowDdlPrices(!showDdlPrices);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className='text-white' />
            </button>
            {data.length > 0 && (
              <button
                type='button'
                className='w-max px-2 py-1 h-8'
                onClick={() => setExpandPrices(!expandPrices)}
              >
                <FontAwesomeIcon
                  icon={expandPrices ? faChevronUp : faChevronDown}
                />
              </button>
            )}
          </div>
        </div>

        {showDdlPrices && (
          <DdlPrices
            className='mt-4'
            data={prices}
            onChange={(e: PRICE_DETAIL) => {
              register(`details.${data.length}`);
              setValue(
                "details",
                [{ ...e, quantity: 1 }, ...data].filter((b) => b)
              );
              setExpandPrices(true);
              setShowDdlPrices(false);
            }}
          />
        )}
      </div>
      <div
        id='prices-item-wrapper'
        className={`${expandPrices ? "h-auto" : "h-0 overflow-hidden"}`}
      >
        {data.map((item: INVOICE_PRICE, iItem: number) => {
          return (
            <div
              key={item.id}
              className={`border ${iItem === data.length - 1 ? "" : "border-b-0"}`}
            >
              <div className='bg-slate-50 py-2 px-4 flex justify-between'>
                <button
                  type='button'
                  className='w-max shadow-lg bg-white px-2 py-1 rounded-lg h-8 mr-3'
                  onClick={() => {
                    unregister(`details.${iItem}`);

                    // Reindex the remaining items
                    setValue(
                      "details",
                      data.filter((_, i) => i !== iItem)
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className='text-rose-500'
                  />
                </button>
                <p className='font-bold'>{item.description}</p>
              </div>

              <p className='text-right py-2 px-4 bg-slate-50'>
                <span className='capitalize text-slate-500'>Amount: </span>
                <span className='font-semibold text-slate-500'>{`${getAmountStr(item.amount)} Ar`}</span>
              </p>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 pt-4'>
                {["quantity", "discount"].map((fieldKey: string) => (
                  <div key={fieldKey}>
                    <p className='font-semibold text-slate-500 pb-2 capitalize text-right'>
                      {fieldKey}
                    </p>
                    <div
                      className={`border relative rounded-lg mb-4 ${
                        isSubmitted || errors[`details.${iItem}.${fieldKey}`]
                          ? errors[`details.${iItem}.${fieldKey}`]
                            ? "border-rose-600"
                            : "border-lime-500"
                          : ""
                      }`}
                    >
                      <textarea
                        disabled={isSubmitting}
                        className={`${INPUT_STYLE} !pr-4 text-right`}
                        placeholder={fieldKey}
                        {...register(`details.${iItem}.${fieldKey}` as any, {
                          required: false,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please provide a valid amount"
                          }
                        })}
                      ></textarea>
                    </div>
                    {errors[`details.${iItem}.${fieldKey}`] && (
                      <span className={ERROR_STYLE}>
                        {errors[`${iItem}.${fieldKey}`].message}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`bg-slate-100 px-4 py-4 rounded-lg rounded-t-none flex items-center justify-between flex-wrap border border-t-0 ${expandPrices ? "" : ""}`}
      >
        <p className='font-semibold'>Total</p>
        <p className='font-semibold'>{`${getAmountStr(
          data.reduce((sum, item) => {
            return sum + item.amount * (item.quantity || 1);
          }, 0)
        )} Ar`}</p>
      </div>
    </>
  );
}

export default InvoicePricesForm;
