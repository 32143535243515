import axios from "axios";
import { isAuth } from "utils/context";

const search = async (
  page?: number,
  elementsPerPage?: number,
  all?: boolean,
  value?: string
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by name"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}subjects/search?${
      page ? `page=${page}` : ""
    }${
      elementsPerPage ? `&element=${elementsPerPage}` : ""
    }${all ? `&all=${String(all)}` : ""}`;
    try {
      const response = await axios.post(url, { value });
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (subject) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}subjects`;
    const response = await axios.post(url, subject);

    return response.data.data;
  }
  return true;
};

const update = async (subject) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}subjects/${subject.id}`;
    const response = await axios.put(url, subject);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}subjects/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { search, add, update, deletebyId };
