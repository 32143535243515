import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import RegistrationInfos from "components/RegistrationInfos";
import PricesInfos from "components/PricesInfos";
import Card from "components/common/Card";

import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  LG_CENTERED
} from "utils/styles";

const TITLE = "uppercase font-bold mb-4 text-esaTextLightBlue pt-2";

function Page() {
  return (
    <div id='middlschool-page' className={BG_COLORED}>
      <section
        id='main-banner'
        className='min-h-96 relative flex flex-col justify-center'
        style={{
          background: "url('/images/school1.PNG')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <div className='absolute top-0 left-0 bg-black w-full h-full opacity-60 z-0' />
        <div
          className={`relative ${CONTAINER} ${LG_CENTERED} text-white text-center h-full flex flex-col justify-center`}
        >
          <h1 className='text-3xl font-bold uppercase'>Middle school</h1>
          <p className='font-semibold text-xl py-8'>
            Expand your horizons and pave the way for the future !
          </p>
          <p className='text-2xl'>
            We will open Grade 9 in 2024-2025 school year{" "}
          </p>
        </div>
      </section>

      <section
        id='daycare-features'
        className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8 ${CONTAINER} ${CONTAINER_PADDING} pt-20`}
      >
        <Card>
          <h2 className={TITLE}>ESA Middle School</h2>
          <p className='pb-4'>
            Middle school is the transitional period between elementary school
            and high school. It"s often a challenging time for preteens, but can
            also be quite interesting and typically a time of growth and change.
          </p>
          <p>
            Middle school is the transitional period between elementary school
            and high school. It"s often a challenging time for preteens, but can
            also be quite interesting and typically a time of growth and change.
          </p>
        </Card>

        <Card>
          <h2 className={TITLE}>Middle School Subjects</h2>
          {[
            "Grammar and Language",
            "Spelling",
            "Vocabulary",
            "Mathematics",
            "Science",
            "Social Study",
            "Health and Life skills",
            "French",
            "Malagasy",
            "Art",
            "Music",
            "Technology",
            "ESL(English as a Second Language)"
          ].map((value: string) => (
            <p key={value} className='flex items-center pb-2'>
              <FontAwesomeIcon
                icon={faCaretRight}
                className='mr-2 text-esaTextLightBlue'
              />
              <span>{value}</span>
            </p>
          ))}
        </Card>

        <Card>
          <h2 className={TITLE}>Ways to prepare tweens for Middle School</h2>
          {[
            "Check-in with teachers periodically",
            "Allow your tween to pick out new clothes and a backpack before school begins",
            "Allow your tween to talk about any concerns he or she has about middle school, and how you might be able to help",
            "Allow your child to enjoy newfound independance and reward him or her for responsible behavior",
            "Begin a new school tradition",
            "Talk about your own fun middle school experiences with our child",
            "Be positive and upbeat about change - Let your teen know that change can be exciting and fun"
          ].map((value: string) => (
            <p key={value} className='flex items-start pb-3'>
              <FontAwesomeIcon
                icon={faCaretRight}
                className='mt-1 mr-2 text-esaTextLightBlue'
              />
              <span>{value}</span>
            </p>
          ))}
        </Card>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <RegistrationInfos color='text-esaTextLightBlue' />
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <PricesInfos type='middle_school' color='text-esaTextLightBlue' />
        </div>
      </section>
    </div>
  );
}

export default Page;
