import React, { useEffect, useState } from "react";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLUMN, PAGINATION, SORT_OPTIONS } from "utils/types/general";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

interface Props extends React.PropsWithChildren {
  data: any;
  setData: any;
  headers: COLUMN[];
  gridStyle: string;
  cellStyle: string;
  pagination?: PAGINATION;
  getData: CallableFunction;
}

function DataTable(props: Props) {
  const {
    data,
    setData,
    headers,
    gridStyle,
    cellStyle,
    pagination,
    getData,
    children
  } = props;
  const [sorting, setSorting] = useState<SORT_OPTIONS>();

  useEffect(() => {
    if (sorting) {
      const column = sorting.columnName;
      const direction = sorting.direction;
      const sortValueUp = direction === "asc" ? -1 : 1;
      const sortValueDown = direction === "asc" ? 1 : -1;

      setData(
        [...data].sort((a, b) =>
          a[column] > b[column] ? sortValueUp : sortValueDown
        )
      );
    }
  }, [data, sorting]);

  return (
    <article>
      <div className={gridStyle}>
        {headers.map((column: COLUMN) => (
          <div key={column.title} className={`${cellStyle} flex flex-row`}>
            <p className='mr-2 font-bold'>{column.title}</p>
            {column.sortable && (
              <button
                onClick={() =>
                  setSorting({
                    columnName: column.slug || "",
                    direction:
                      sorting && sorting.direction === "asc" ? "desc" : "asc"
                  })
                }
              >
                <FontAwesomeIcon icon={faSort} />
              </button>
            )}
          </div>
        ))}
      </div>
      {children}

      {pagination && (
        <div>
          <div className='h-10' />
          <ResponsivePagination
            current={pagination.currentPage}
            total={pagination.totalPages}
            onPageChange={(e) => getData(e)}
          />
          <div className='h-10' />
        </div>
      )}
    </article>
  );
}

export default DataTable;
