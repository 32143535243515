import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DdlUser from "components/dropdowns/DdlUser";

import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import Loader from "components/common/Loader";
import AttendanceAdminTable from "components/delayAbsence/AttendanceAdminTable";
import AttendanceModal from "components/delayAbsence/AttendanceModal";

import { AttendanceAPI } from "api";
import { ACTIONS, PAGINATION } from "utils/types/general";
import { ATTENDANCE } from "utils/types/attendance";

const ELEMENTS_PER_PAGE = 10;

const AttendanceAdmins = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attendances, setAttendances] = useState<ATTENDANCE[]>([]);
  const [pagination, setPagination] = useState<PAGINATION>();

  const [selectedItem, setSelectedItem] = useState<ATTENDANCE>();
  const [action, setAction] = useState<ACTIONS | undefined>();
  const [filterCodes, setFilterCodes] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState("");

  const getAttendances = async (
    page: number,
    schoolYear?: string,
    codes?: string[]
  ) => {
    setLoading(true);
    AttendanceAPI.get(
      page,
      ELEMENTS_PER_PAGE,
      false,
      schoolYear ? schoolYear : selectedSchoolYear,
      codes ? codes : filterCodes
    )
      .then((r: any) => {
        const { data, ...rest } = r;
        setAttendances(data);
        setPagination(rest);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAttendances(1, selectedSchoolYear);
  }, []);

  return (
    <>
      <div className='flex items-center justify-between flex-wrap pb-6'>
        <h1 className='w-full md:w-[25%] uppercase font-bold text-center md:text-left pb-3'>
          Manage delays and absence
        </h1>
        <div className='w-full md:w-[75%] flex flex-row flex-wrap gap-3 justify-start md:justify-end items-center'>
          <button
            className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-lg px-4 py-2 shadow-md'
            onClick={() => {
              setAction("add");
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className='ml-2 font-semibold'>Add a new record</span>
          </button>
          <DdlSchoolYear
            onChange={(e) => {
              setSelectedSchoolYear(e.value);
              getAttendances(1, e.value);
            }}
            value={
              selectedSchoolYear
                ? { label: selectedSchoolYear, value: selectedSchoolYear }
                : undefined
            }
            className='min-w-[300px] border rounded-3xl'
          />
        </div>
      </div>

      <div className='w-full flex flex-nowrap my-4 items-center'>
        <DdlUser
          placeholder='Find student'
          className='w-full mr-4'
          hideTeachers
          onChange={(e) => {
            const newFilterCodes = e.map((a) => a.codeEleve);
            setFilterCodes(newFilterCodes);
          }}
          isMulti
          includeAll
          value={undefined}
        />
        <button
          className='bg-esaBlue hover:bg-esaBlueHover text-white rounded-lg px-4 py-2 shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed max-h-12'
          onClick={() => {
            getAttendances(1);
          }}
        >
          Find
        </button>
      </div>

      <div
        className={`rounded-lg shadow-lg overflow-hidden bg-white py-4 px-2`}
      >
        {loading ? (
          <Loader />
        ) : (
          <AttendanceAdminTable
            attendances={attendances}
            setAttendances={setAttendances}
            setSelectedItem={setSelectedItem}
            setAction={setAction}
            pagination={pagination}
            getData={getAttendances}
          />
        )}
      </div>

      <AttendanceModal
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        action={action}
        setAction={setAction}
        refetch={getAttendances}
      />
    </>
  );
};

export default AttendanceAdmins;
