import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Modal from "components/common/Modal";
import SubjectForm from "components/subjects/SubjectForm";

import { SubjectsAPI } from "api";
import { ACTIONS } from "utils/types/general";
import { SUBJECT } from "utils/types/subject";

interface Props {
  selectedItem: SUBJECT;
  setSelectedItem: Dispatch<SetStateAction<SUBJECT>>;
  action: ACTIONS;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  refetch: any;
}

function SubjectModal(props: Props) {
  const { selectedItem, setSelectedItem, action, setAction, refetch } = props;

  const title = useMemo(() => {
    switch (action) {
      case "add":
        return "Create subject";
      case "edit":
        return "Update subject";
      case "delete":
        return "Delete subject";
      default:
        return "";
    }
  }, [action]);

  const closeModal = useCallback(() => {
    setSelectedItem(undefined);
    setAction(undefined);
  }, []);

  return (
    <Modal
      isOpen={selectedItem !== undefined || action === "add"}
      onClose={closeModal}
      title={title}
    >
      {["add", "edit"].includes(action) && (
        <SubjectForm
          data={selectedItem}
          action={action}
          refetch={refetch}
          closeModal={closeModal}
        />
      )}
      {action === "delete" && (
        <div>
          <div className='flex flex-col  items-center mb-8'>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className='text-5xl text-rose-500'
            />
            <p className='pt-2'>
              Are you sure you want to delete this element ?
            </p>
          </div>

          <div className='flex items-center justify-end gap-3'>
            <button
              className='bg-blue-500 text-white py-2 px-4 rounded-lg'
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className='bg-rose-500 text-white py-2 px-4 rounded-lg'
              onClick={() => {
                SubjectsAPI.deletebyId(selectedItem.id).then(() => {
                  toast.success("Subject deleted successfully", {
                    position: "bottom-right"
                  });
                  refetch(1);
                  closeModal();
                });
              }}
            >
              Confirm Deletion
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default SubjectModal;
