import axios from "axios";
import { isAuth } from "utils/context";

const getByName = async (name: string) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by name"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}levels?name=${name}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const search = async (
  page?: number,
  elementsPerPage?: number,
  all?: boolean,
  label?: string
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by code"
  };

  if (isAuth()) {
    try {
      const url = `${process.env.REACT_APP_API_URL}levels/search?${
    page ? `page=${page}` : ""
  }${
    elementsPerPage ? `&element=${elementsPerPage}` : ""
  }${all ? `&all=${String(all)}` : ""}`;

      const res = await axios.post(url, {label});
      return res.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (level) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}levels`;
    const response = await axios.post(url, level);

    return response.data.data;
  }
  return true;
};

const update = async (level) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}levels/${level.id}`;
    const response = await axios.put(url, level);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}levels/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { getByName, search, add, update, deletebyId };
