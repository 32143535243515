import { StudentsAPI, UsersAPI } from "api";
import { USER } from "utils/types/user";

export const getUsers = async (
  hideStudents: boolean,
  hideTeachers: boolean,
  includeAll: boolean
): Promise<USER[]> => {
  let resStudents: Promise<any[]> = Promise.resolve([]);
  if (!hideStudents) {
    resStudents = StudentsAPI.get(null, null, true).then((r: any) => r.data);
  }

  let resTeachers: Promise<any[]> = Promise.resolve([]);

  if (!hideTeachers) {
    resTeachers = UsersAPI.getTeachers(null, null, true).then(
      (r: any) => r.data
    );
  }

  const res = await Promise.all([resStudents, resTeachers]);

  const temp = [...res[0], ...res[1]];
  if (includeAll) {
    temp.push({ name: "All Teachers", codeEleve: "TUTORS" });
    temp.push({ name: "All Parents", codeEleve: "PARENTS" });
  }
  const result = [...temp]
    .filter((user: USER) => user.codeEleve !== "")
    .sort((a, b) => (a.codeEleve > b.codeEleve ? 1 : -1));

  return result;
};
