/* eslint-disable no-plusplus */
import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import {
  pdfStyles,
  tableStyles
} from "components/templates/GradesTemplate.style";
import { GRADE, GRADE_PARAMS } from "utils/types/grade";

export interface Props {
  data: GRADE;
  info: GRADE_PARAMS;
  nbQuizz: number;
}

const GradesTemplate = ({ data, info, nbQuizz: nbColumnsQuiz }: Props) => {
  Font.register({
    family: "Montserrat",
    fonts: [
      { src: "/fonts/Montserrat-Light.ttf" },
      { src: "/fonts/Montserrat-SemiBold.ttf", fontWeight: 700 },
      { src: "/fonts/Montserrat-Italic.ttf", fontStyle: "italic" }
    ]
  });

  const renderHeaderQuiz = () => {
    let result = <></>;

    if (nbColumnsQuiz > 0) {
      for (let index = 0; index < nbColumnsQuiz; index++) {
        result = (
          <>
            {result}
            <Text style={[tableStyles.thead, tableStyles.colQuiz]}>
              {`Quiz ${index + 1}`}
            </Text>
            <Text style={[tableStyles.thead, tableStyles.colComment]}>
              {`Comment ${index + 1}`}
            </Text>
          </>
        );
      }
    }

    return result;
  };

  const renderQuizLines = (column: any) => {
    let result = <></>;

    if (nbColumnsQuiz > 0) {
      for (let index = 0; index < nbColumnsQuiz; index++) {
        result = (
          <>
            {result}
            <Text
              style={[
                tableStyles.colQuiz,
                pdfStyles.textStyle,
                tableStyles.bordered
              ]}
            >
              {column[`quiz${index + 1}`]}
            </Text>
            <Text
              style={[
                tableStyles.colComment,
                pdfStyles.textStyle,
                tableStyles.bordered
              ]}
            >
              {column[`comment${index + 1}`]}
            </Text>
          </>
        );
      }
    }

    return result;
  };

  return (
    <Page
      size='A4'
      style={{ padding: "25px", display: "flex", flexDirection: "column" }}
    >
      <View style={pdfStyles.headerWrapper}>
        <View style={pdfStyles.imgWrapper}>
          <Image style={pdfStyles.imgStyles} src='/images/logo-big.png' cache />
        </View>
        <View style={pdfStyles.esaInfo}>
          <Text style={pdfStyles.textBold}>
            ELEMENTARY SCHOOL OF ANTANANARIVO
          </Text>
          <Text style={pdfStyles.textStyle}>Amboara Ambohitrarahaba</Text>
          <Text style={pdfStyles.textStyle}>contact@esa-mada.com</Text>
          <Text style={pdfStyles.textStyle}>+261 34 40 216 81</Text>
          <Text style={pdfStyles.textStyle}>
            NIF: 4003517556 - STAT: 11 2019 0 05 848
          </Text>
        </View>
      </View>

      <View style={pdfStyles.infoWrapper}>
        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>Name:</Text>
          <Text style={pdfStyles.textStyle}>{info.student.name}</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>Level:</Text>
          <Text style={pdfStyles.textStyle}>{info.student.level}</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>Term:</Text>
          <Text style={pdfStyles.textStyle}>{info.term.termNb}</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>School Year:</Text>
          <Text style={pdfStyles.textStyle}>{info.schoolYear}</Text>
        </View>
      </View>

      <View style={pdfStyles.viewContent}>
        <Text style={[pdfStyles.textBold, { marginVertical: "15px" }]}>
          {`Quiz Term ${info.term.termNb} - ${info.student.name} - ${info.student.level} - ${info.schoolYear} `}
        </Text>

        <View>
          <View style={[tableStyles.theadRow, { maxHeight: "50px" }]}>
            <Text style={[tableStyles.thead, tableStyles.colSubject]}>
              Subject
            </Text>
            {renderHeaderQuiz()}
            <Text style={[tableStyles.thead, tableStyles.colQuiz]}>
              Quiz Average
            </Text>
          </View>

          {data.gradeQuiz.map((column: any, idx: number) => (
            <View wrap={false} style={tableStyles.row} key={`line-${idx}`}>
              <Text
                style={[
                  tableStyles.colSubject,
                  pdfStyles.textStyle,
                  tableStyles.borderedLeft
                ]}
              >
                {column.subject}
              </Text>
              {renderQuizLines(column)}
              <Text
                style={[
                  tableStyles.colQuiz,
                  pdfStyles.textStyle,
                  tableStyles.borderedRight
                ]}
              >
                {column.quizAverage}
              </Text>
            </View>
          ))}
        </View>
      </View>

      <View break>
        <Text style={[pdfStyles.textBold, { marginVertical: "15px" }]}>
          {`Exam Term ${info.term.termNb} - ${info.student.name} - ${info.student.level} - ${info.schoolYear} `}
        </Text>

        <View
          wrap={false}
          style={[tableStyles.theadRow, { maxHeight: "40px" }]}
        >
          <Text style={[tableStyles.thead, tableStyles.colExam]}>Subject</Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>Exam</Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>Comments</Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>
            Quiz Average
          </Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>
            Total Average
          </Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>Grade</Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>
            General Effort
          </Text>
          <Text style={[tableStyles.thead, tableStyles.colExam]}>
            Attendance
          </Text>
        </View>

        {data.gradeExam.map((column: any, idx: number) => (
          <View wrap={false} style={tableStyles.row} key={`line-${idx}`}>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.borderedLeft,
                pdfStyles.textStyle
              ]}
            >
              {column.subject}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.bordered,
                pdfStyles.textStyle
              ]}
            >
              {column.exam}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.bordered,
                pdfStyles.textStyle
              ]}
            >
              {column.comments}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.bordered,
                pdfStyles.textStyle
              ]}
            >
              {column.quizAverage}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.bordered,
                pdfStyles.textStyle
              ]}
            >
              {column.totalAverage}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.bordered,
                pdfStyles.textStyle
              ]}
            >
              {column.grade}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.bordered,
                pdfStyles.textStyle
              ]}
            >
              {column.generalEffort}
            </Text>
            <Text
              style={[
                tableStyles.colExam,
                tableStyles.borderedRight,
                pdfStyles.textStyle
              ]}
            >
              {column.attendance}
            </Text>
          </View>
        ))}
      </View>
    </Page>
  );
};

export default GradesTemplate;
