import React, { useMemo } from "react";

import useWindowSize from "hooks/useWindowSize";
import { BG_COLORED } from "utils/styles";

function Page() {
  const { width } = useWindowSize();

  const frameWidth = useMemo(() => {
    return width > 500 ? 500 : width - 20;
  }, [width]);
  return (
    <div id='news-page' className={`h-full ${BG_COLORED}`}>
      <div className='w-full'>
        <iframe
          id='news-frame'
          title='fb-feeds'
          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2Fpg%2FElementary-School-of-Antananarivo-147788855917552%2Fposts%2F%3Fref%3Dpage_internal&tabs=timeline&width=${frameWidth}&height=600&small_header=true&hide_cover=false&show_facepile=true&appId=2755084398117458`}
          allowFullScreen
          allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
          style={{ border: "none" }}
          height={600}
          width={frameWidth}
          className='mx-auto w-full max-w-[500px]'
        />
      </div>
    </div>
  );
}

export default Page;
