import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import { PRICE_CATEGORIES } from "utils/constants/prices";
import { OPTIONS } from "utils/types/general";
import { PRICE_CATEGORY } from "utils/types/prices";

interface Props {
  onChange: any;
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlPriceCategory(props: Props) {
  const {
    onChange,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false
  } = props;

  const options = useMemo(() => {
    return [{ name: "", color: "" }, ...PRICE_CATEGORIES].map(
      (item: PRICE_CATEGORY) => {
        return { label: item.name, value: item.name };
      }
    );
  }, []);

  const handleChange = useCallback((e: any) => {
    onChange(e);
  }, []);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        option: (provided, state) => ({
          ...provided,
          minHeight: 40,
          borderBottom: "1px solid #E5E7EB"
        })
      }}
      placeholder='Category'
      value={value || null}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlPriceCategory;
