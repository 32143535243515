import { FieldErrors } from "react-hook-form";
import { TERM, WEEK } from "utils/types/term";

export const emptyTerms: TERM[] = [
  {
    termNb: "1",
    start: "",
    end: "",
    schoolYear: "",
    weeks: [
      { weekNb: "1", start: "", end: "", files: [] },
      { weekNb: "2", start: "", end: "", files: [] }
    ]
  },
  {
    termNb: "2",
    start: "",
    end: "",
    schoolYear: "",
    weeks: [
      { weekNb: "1", start: "", end: "", files: [] },
      { weekNb: "2", start: "", end: "", files: [] }
    ]
  },
  {
    termNb: "3",
    start: "",
    end: "",
    schoolYear: "",
    weeks: [
      { weekNb: "1", start: "", end: "", files: [] },
      { weekNb: "2", start: "", end: "", files: [] }
    ]
  }
];

export const validateFormValues = (values: TERM) => {
  const errors: { [key: string]: string } = {};
  if (!values.start || values.start.trim() === "") {
    errors.start = "Start date is required";
  }

  if (!values.end || values.end.trim() === "") {
    errors.end = "End date is required";
  }

  const weeks = values.weeks || [];

  // Iterate through each week and perform validation
  weeks.forEach((week: WEEK, iWeek: number) => {
    const { start, end } = week;
    if (!start || start.trim() === "") {
      errors[`weeks.${iWeek}.start`] = "Week start date is required";
    }
    if (!end || end.trim() === "") {
      errors[`weeks.${iWeek}.end`] = "Week end date is required";
    }
  });

  return errors;
  // Return true if no errors, otherwise false
  // Object.keys(errors).length === 0
};

export const countErrors = (errors: FieldErrors<TERM>, term: TERM): number => {
  let count = 0;

  // Count errors for fields directly in the term
  if (errors.start) count++;
  if (errors.end) count++;

  // Count errors for weeks
  term.weeks.forEach((_, index) => {
    if (errors.weeks && errors.weeks[index]) {
      if (errors.weeks[index].start) count++;
      if (errors.weeks[index].end) count++;
    }
  });

  return count;
};
