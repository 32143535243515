import { GRADE_HEADER, MARK, REPORT_ITEM } from "utils/types/grade";
import { TERM } from "utils/types/term";

export const DUMMY_TERM: TERM[] = [
  {
    termNb: "1",
    start: "",
    end: "",
    schoolYear: "",
    weeks: []
  },
  {
    termNb: "2",
    start: "",
    end: "",
    schoolYear: "",
    weeks: []
  },
  { termNb: "3", start: "", end: "", schoolYear: "", weeks: [] }
];

export const PRE_K_TEMPLATE_SUBJECTS: string[] = [
  "Personal, Social, and Emotional Development",
  "Communication Skills",
  "Social studies",
  "Social sciences",
  "Phonics (language and literacy)",
  "Mathematical skills",
  "Fine and gross motor skills"
];

export const PRE_K_TEMPLATE_REPORT_CARD: REPORT_ITEM[] = [
  {
    subject: "Personal, Social, and Emotional Development",
    data: [
      { id: 0, mark: "", skill: "Works well independently" },
      { id: 1, mark: "", skill: "Plays and shares well with others" },
      { id: 2, mark: "", skill: "Follows directions and routines" },
      { id: 3, mark: "", skill: "Takes responsibilities for action" },
      { id: 4, mark: "", skill: "Uses good and acceptable manners" },
      { id: 5, mark: "", skill: "Shows interest in learning" },
      { id: 6, mark: "", skill: "Expresses feelings and ideas" }
    ]
  },
  {
    subject: "Communication Skills",
    data: [
      { id: 0, mark: "", skill: "Speaks Clearly" },
      { id: 1, mark: "", skill: "Listens well in classroom" },
      { id: 2, mark: "", skill: "Understands instructions" },
      { id: 3, mark: "", skill: "Responds to direct questions" },
      { id: 4, mark: "", skill: "Talks with others" },
      { id: 5, mark: "", skill: "Understand and can retell stories" }
    ]
  },
  {
    subject: "Social studies",
    data: [
      { id: 0, mark: "", skill: "" },
      { id: 1, mark: "", skill: "" }
    ]
  },
  {
    subject: "Social sciences",
    data: [
      { id: 0, mark: "", skill: "" },
      { id: 1, mark: "", skill: "" }
    ]
  },
  {
    subject: "Phonics (language and literacy)",
    data: [
      { id: 0, mark: "", skill: "Reading skills" },
      { id: 1, mark: "", skill: "Writing skills" }
    ]
  },
  {
    subject: "Mathematical skills",
    data: [
      { id: 0, mark: "", skill: "" },
      { id: 1, mark: "", skill: "" },
      { id: 2, mark: "", skill: "" },
      { id: 3, mark: "", skill: "" }
    ]
  },
  {
    subject: "Fine and gross motor skills",
    data: [
      {
        id: 0,
        mark: "",
        skill: "Uses tools properly (scissors, glue, paintbrush, pencil, pen)"
      },
      { id: 1, mark: "", skill: "Draws, traces and writes well" },
      { id: 2, mark: "", skill: "Can make letters and numbers with objects" },
      { id: 3, mark: "", skill: "Participateds well in song, poem, dance" },
      {
        id: 4,
        mark: "",
        skill: "Does body movement according to given directions"
      }
    ]
  },
  {
    comments: ""
  } as any
];

export const PRE_K_TEMPLATE_MARKS: MARK[] = [
  { mark: "S+", effort: "Excellent" },
  { mark: "S", effort: "Very Good" },
  { mark: "S-", effort: "Satisfactory" },
  { mark: "P", effort: "Progressing" },
  { mark: "N", effort: "Need improvement" }
];

export const DEFAULT_MARKS: MARK[] = [
  { mark: "A", effort: "Excellent effort" },
  { mark: "B", effort: "Good effort" },
  { mark: "C", effort: "Satisfactory" },
  { mark: "D", effort: "Need some improvement" }
];

export const EXAM_HEADERS: GRADE_HEADER[] = [
  { header: "Subject", key: "subject" },
  { header: "Exam", key: "exam" },
  { header: "Comments", key: "comments" },
  { header: "Quiz Average", key: "quizAverageExam" },
  { header: "Total Average", key: "totalAverage" },
  { header: "Grade", key: "grade" },
  { header: "General Effort", key: "generalEffort" },
  { header: "Attendance", key: "attendance" }
];
