import React from "react";
import Card from "components/common/Card";
import { CARD_TITLE } from "utils/styles";

const Lunch = () => {
  return (
    <Card images={[{ src: "/images/lunch.jpg", alt: "lunch" }]}>
      <p className={`${CARD_TITLE} pb-4`}>Lunch</p>
      <hr />
      <p className='pt-4 pb-8'>
        We provide a canteen at school. We work with FOODMARK, leader of a
        canteen in Antananarivo. They give for our children healthy and tasty
        food (starter, dish and dessert).
      </p>

      <p className={`${CARD_TITLE} pb-4`}>Snack</p>
      <hr />
      <p className='pt-4'>
        Sometimes life is just busy and time to make a meal or a snack is hard!
        If you are running out of ideas, or getting a hard time thinking about
        your kids snacks, we would love the opportunity to get you set up with
        healthy snacks from one of our exclusive catering partner FoodMark.
      </p>
    </Card>
  );
};

export default Lunch;
