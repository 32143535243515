import { StyleSheet } from "@react-pdf/renderer";

export const pdfStyles = StyleSheet.create({
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    minHeight: "60px",
    marginTop: "15px"
  },
  imgWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "75px",
    height: "60px"
  },
  imgStyles: {
    width: "50px",
    height: "60px",
    marginRight: "15px"
  },
  esaInfo: {
    width: "100%"
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    minHeight: "60px"
  },
  viewContent: {
    marginTop: 15,
    width: "100%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
    minHeight: "20px",
    marginLeft: "300px"
  },
  textStyle: {
    fontSize: 9,
    lineHeight: 1.2,
    fontFamily: "Montserrat",
    wordBreak: "break-all",
    whiteSpace: "wrap"
  },
  textStyleSmall: {
    fontSize: 8,
    lineHeight: 1.2,
    fontFamily: "Montserrat"
  },
  textBold: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat"
  },
  textUnderlined: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    textDecoration: "underline",
    marginRight: "4px"
  }
});

export const tableStyles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    minHeight: "50px",
    maxHeight: "100px"
  },
  theadRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    minHeight: "40px"
  },
  thead: {
    backgroundColor: "#1f3864",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    border: "1px solid #1f3864"
  },
  tfoot: {
    backgroundColor: "#c00000",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    border: "1px solid #c00000"
  },
  colSubject: {
    width: "150px",
    padding: "8px",
    minHeight: "100%",
    whiteSpace: "wrap",
    wordBreak: "break-all"
  },
  col: {
    padding: "8px",
    minHeight: "100%",
    border: "1px solid #dadada",
    display: "flex",
    alignItems: "center"
  },
  colQuiz: {
    padding: "8px 3px",
    minHeight: "100%",
    width: "60px"
  },
  colComment: {
    padding: "8px",
    minHeight: "100%",
    width: "100px"
  },
  colExam: {
    width: "12.5%",
    padding: "8px",
    minHeight: "100%"
  },
  bordered: {
    borderBottom: "1px solid #dadada"
  },
  borderedLeft: {
    borderBottom: "1px solid #dadada",
    borderLeft: "1px solid #dadada"
  },
  borderedRight: {
    borderBottom: "1px solid #dadada",
    borderRight: "1px solid #dadada"
  }
});
