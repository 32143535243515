import { TermAPI } from "api";
import { getCurrentSchoolYear } from "utils/dates";
import { TERM } from "utils/types/term";

export const getTerms = async (paramSchoolYear?: string): Promise<TERM[]> => {
  const schoolYear = paramSchoolYear || getCurrentSchoolYear();

  const r1 = TermAPI.get(schoolYear).then((r) => r);
  const res = await Promise.all([r1]);

  if (res[0] && res[0].terms) {
    return res[0].terms.sort((a, b) => (a.termNb > b.termNb ? 1 : -1));
  } else {
    return [];
  }
};
