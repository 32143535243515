import { COLUMN } from "utils/types/general";

export const ATTENDANCE_TABLE_COLUMNS: COLUMN[] = [
  { title: "Date", sortable: false, slug: "date" },
  { title: "Code élève", sortable: false, slug: "codeEleve" },
  { title: "Reason", sortable: false, slug: "reason" },
  { title: "Term", sortable: false, slug: "term" },
  { title: "School year", sortable: false, slug: "schoolYear" },
  { title: "Type", sortable: false, slug: "type" }
];
