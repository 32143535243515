import Loader from "components/common/Loader";
import moment from "moment";
import React, { Fragment } from "react";
import {
  amountLine,
  getAmountDue,
  getAmountPaid,
  getAmountStr,
  getTotalAmount,
  sortInvoicePaymentByDate
} from "utils/format/invoice";
import { INVOICE, INVOICE_PAYMENT, INVOICE_PRICE } from "utils/types/invoice";
import { USER } from "utils/types/user";

interface Props {
  data: INVOICE;
  student: Partial<USER>;
  loading: string;
}

function InvoicePreview(props: Props) {
  const { data, student, loading } = props;

  return loading !== "" ? (
    <Loader />
  ) : (
    <>
      <div className='grid grid-cols-1 lg:grid-cols-2 pb-4'>
        <div
          id='report-header'
          className='flex items-center flex-wrap pb-6 lg:pb-0'
        >
          <div id='report-logo' className='mx-auto mb-3 md:mx-3 xl:mb-0'>
            <img src='/images/logo.png' width={80} height={93} />
          </div>
          <div className='flex flex-col justify-center text-slate-600 pl-0 xl:pl-6'>
            <p className='font-bold'>Elementary School of Antananarivo</p>
            <p>Amboara Ambohitrarahaba</p>
            <p>contact@esa-mada.com</p>
            <p>+261 34 40 216 81</p>
            <p>NIF: 4003517556 - STAT: 11 2019 0 05 848</p>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 pb-4'>
        <div
          id='payment-infos'
          className='flex flex-col items-center justify-center my-3'
        >
          <span
            className={`font-bold px-3 py-2 uppercase rounded-lg ${data.type === "receipt" ? "bg-esaMinty" : "bg-rose-600"} text-white mb-2`}
          >
            {data.type}
          </span>
          {data.paid === 1 && (
            <span>{`PAID ${data.paymentMethod.label ? `by ${data.paymentMethod.label}` : ""} ${data.date_paid ? `on ${data.date_paid}` : ""}`}</span>
          )}
        </div>
        <div id='report-infos'>
          <div className='flex flex-wrap'>
            <p className='font-bold mr-3 underline'>INVOICE NUMBER:</p>
            <p>{data.reference}</p>
          </div>
          <div className='flex flex-wrap'>
            <p className='font-bold mr-3 underline'>DATE:</p>
            <p>{moment(data.date_issue).format("DD/MM/YYYY")}</p>
          </div>
          <div className='flex flex-wrap'>
            <p className='font-bold mr-3 underline'>STUDENT CODE:</p>
            <p>{data.codeEleve}</p>
          </div>
          {student.dad && <p className='mb-0 mt-3'>{student.dad.name}</p>}
          {student.mom && <p className='mb-0'>{student.mom.name}</p>}
          {student.address && <p className='mb-0'>{student.address}</p>}
        </div>
      </div>
      <div className='w-full flex flex-wrap'>
        <p className='font-bold mr-3 underline'>Concerns:</p>
        <p>{student.name}</p>
      </div>

      <div id='table-recap' className='pt-6'>
        <div className='hidden lg:grid grid-cols-1 lg:grid-cols-[5fr_0.5fr_2fr_2fr] rounded-lg overflow-hidden rounded-b-none bg-sky-700'>
          <div className='p-4 text-white font-bold'>Description</div>
          <div className='p-4 text-white font-bold text-right'>Quantity</div>
          <div className='p-4 text-white font-bold text-right'>
            Unit Price (Ar)
          </div>
          <div className='p-4 text-white font-bold text-right'>Amount (Ar)</div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-[5fr_0.5fr_2fr_2fr] border-t border-x shadow-lg lg:shadow-none rounded-lg rounded-b-none lg:rounded-none'>
          {data.details.map((detail: INVOICE_PRICE) => (
            <Fragment key={detail.id}>
              <div className='px-4 py-0 lg:py-4 flex flex-col'>
                <span className='block lg:hidden font-bold pt-4 lg:pt-0'>
                  Description:{" "}
                </span>
                <span>{detail.description}</span>
                {detail.discount && (
                  <span className='bg-green-500 mt-2 rounded-lg px-2 w-max font-semibold text-white'>
                    {`${getAmountStr(detail.discount)} Ar`}
                  </span>
                )}
              </div>
              <div className='px-4 py-0 lg:py-4 text-left lg:text-right'>
                <p className='block lg:hidden font-bold'>Quantity</p>
                {detail.quantity || 1}
              </div>
              <div className='px-4 py-0 lg:py-4 text-left lg:text-right'>
                <p className='block lg:hidden font-bold'>Unit Price</p>
                {`${getAmountStr(detail.amount)} Ar`}
              </div>
              <div className='px-4 pb-4 lg:pb-4 pt-0 lg:pt-4 text-left lg:text-right border-b lg:border-b-0'>
                <p className='block lg:hidden font-bold'>Amount</p>
                {`${amountLine(detail)} Ar`}
              </div>
            </Fragment>
          ))}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-[2fr_4fr_1fr_1.5fr_1.5fr] rounded-lg overflow-hidden rounded-t-none bg-rose-600'>
          <div className='flex flex-row justify-between lg:flex-col flex-wrap p-4'>
            <span className='font-bold text-white'>TOTAL</span>
            <span className='inline-block lg:hidden font-bold text-white'>{`${getAmountStr(getTotalAmount(data))} Ar`}</span>
          </div>
          <div className='p-4 hidden lg:block'></div>
          <div className='p-4 hidden lg:block'></div>
          <div className='p-4 hidden lg:block'></div>
          <div className='p-4 hidden lg:block text-right text-white font-bold'>{`${getAmountStr(getTotalAmount(data))} Ar`}</div>
        </div>
      </div>

      <p className='pt-4'>
        <span className='font-bold mr-3'>Payment deadline: </span>
        <span>
          {data.date_due !== ""
            ? moment(data.date_due).format("DD/MM/YYYY")
            : data.date_due}
        </span>
      </p>
      <p className='italic'>
        Beyond this date, a penalty of 10% (of the total payment) every
        fortnight will be applied.
      </p>

      {data.penalties && data.penalties.length > 0 && (
        <>
          <p className='font-bold pt-8'>Penalties:</p>

          {sortInvoicePaymentByDate(data.penalties).map(
            (penalty: INVOICE_PAYMENT, iPenalty: number) => {
              const title = `Penalty n°${iPenalty + 1} ${penalty.date ? `on ${moment(penalty.date).format("DD/MM/YYYY")}` : ""}`;

              const amount = `${penalty.amount ? `+ ${getAmountStr(penalty.amount)}` : "0"} Ar`;
              return (
                <div
                  className='grid grid-cols-1 lg:grid-cols-[6fr_0fr_1fr_1.5fr_1.5fr]'
                  key={`penalty${iPenalty}`}
                >
                  <div className='flex flex-row justify-between lg:flex-col flex-wrap'>
                    <span>{title}</span>
                    <span className='inline-block lg:hidden'>{amount}</span>
                  </div>
                  <div className='hidden lg:block px-4'></div>
                  <div className='hidden lg:block px-4'></div>
                  <div className='hidden lg:block px-4'></div>
                  <div className='hidden lg:block px-4 text-right'>
                    {amount}
                  </div>
                </div>
              );
            }
          )}

          <div className='grid grid-cols-1 lg:grid-cols-[6fr_0fr_1fr_1.5fr_1.5fr] rounded-lg rounded-t-none border mt-4'>
            <div className='flex flex-row justify-between lg:flex-col flex-wrap p-4'>
              <span className='font-bold'>Total with penalties</span>
              <span className='inline-block lg:hidden font-bold'>{`${getAmountStr(getAmountDue(data) + getAmountPaid(data))} Ar`}</span>
            </div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block text-right font-bold'>{`${getAmountStr(getAmountDue(data) + getAmountPaid(data))} Ar`}</div>
          </div>
        </>
      )}

      {(data.amount_paid !== 0 ||
        (data.payments && data.payments.length > 0)) && (
        <>
          <p className='font-bold pt-8'>Payments:</p>
          {data.amount_paid !== 0 && (
            <div className='grid grid-cols-1 lg:grid-cols-[6fr_0fr_1fr_1.5fr_1.5fr]'>
              <div className='flex flex-row justify-between lg:flex-col flex-wrap'>
                <span>Previous Payment</span>
                <span className='inline-block lg:hidden'>{`- ${getAmountStr(data.amount_paid)} Ar`}</span>
              </div>
              <div className='hidden lg:block px-4'></div>
              <div className='hidden lg:block px-4'></div>
              <div className='hidden lg:block px-4'></div>
              <div className='hidden lg:block px-4 text-right'>{`- ${getAmountStr(data.amount_paid)} Ar`}</div>
            </div>
          )}

          {data.payments &&
            data.payments.length > 0 &&
            sortInvoicePaymentByDate(data.payments).map(
              (payment: INVOICE_PAYMENT, iPayment: number) => {
                const title = `Payment n°${iPayment + 1} ${payment.date ? `on ${moment(payment.date).format("DD/MM/YYYY")}` : ""}`;
                const amount = `${payment.amount ? `- ${getAmountStr(payment.amount)}` : "0"} Ar`;
                return (
                  <div
                    key={`payment${iPayment}`}
                    className='grid grid-cols-1 lg:grid-cols-[6fr_0fr_1fr_1.5fr_1.5fr]'
                  >
                    <div className='flex flex-row justify-between lg:flex-col flex-wrap'>
                      <span>{title}</span>
                      <span className='inline-block lg:hidden'>{amount}</span>
                    </div>
                    <div className='hidden lg:block px-4'></div>
                    <div className='hidden lg:block px-4'></div>
                    <div className='hidden lg:block px-4'></div>
                    <div className='hidden lg:block px-4 text-right'>
                      {amount}
                    </div>
                  </div>
                );
              }
            )}

          <div className='grid grid-cols-1 lg:grid-cols-[6fr_0fr_1fr_1.5fr_1.5fr] rounded-lg rounded-t-none border mt-4'>
            <div className='flex flex-row justify-between lg:flex-col flex-wrap p-4'>
              <span className='font-bold'>Total paid</span>
              <span className='inline-block lg:hidden font-bold'>{`${getAmountStr(getAmountPaid(data))} Ar`}</span>
            </div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block text-right font-bold'>{`${getAmountStr(getAmountPaid(data))} Ar`}</div>
          </div>
        </>
      )}

      {getAmountDue(data) !== 0 &&
        ((data.penalties && data.penalties.length > 0) ||
          (data.payments && data.payments.length > 0)) && (
          <div className='grid grid-cols-1 lg:grid-cols-[6fr_0fr_1fr_1.5fr_1.5fr] rounded-lg border mt-4 bg-rose-600'>
            <div className='flex flex-row justify-between lg:flex-col flex-wrap p-4'>
              <span className='font-bold text-white'>Remaining</span>
              <span className='inline-block lg:hidden font-bold text-white'>{`${getAmountStr(getAmountDue(data))} Ar`}</span>
            </div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block'></div>
            <div className='p-4 hidden lg:block text-right text-white font-bold'>{`${getAmountStr(getAmountDue(data))} Ar`}</div>
          </div>
        )}

      {data.description && (
        <>
          <hr className='mt-8' />
          <p className='font-bold pt-8'>Description:</p>
          <div
            className='news-content'
            dangerouslySetInnerHTML={{
              __html: data.description.replace(/\n/g, "<br />")
            }}
          />
        </>
      )}
    </>
  );
}

export default InvoicePreview;
