import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import NewsTable from "components/news/NewsTable";
import NewsModal from "components/news/NewsModal";
import Loader from "components/common/Loader";

import { NewsAPI } from "api";
import { useUser } from "context/user.context";

import { ACTIONS, PAGINATION } from "utils/types/general";
import { NEWS } from "utils/types/news";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";

const ELEMENTS_PER_PAGE = 4;

function DashboardAdmin() {
  const { ctxUser } = useUser();
  const navigate = useNavigate();

  const [news, setNews] = useState<NEWS[]>([]);
  const [selectedItem, setSelectedItem] = useState<NEWS>();
  const [loading, setLoading] = useState<string>("");
  const [pagination, setPagination] = useState<PAGINATION>();

  const [action, setAction] = useState<ACTIONS | undefined>();

  const getNews = (page: number) => {
    setLoading("fetch");
    NewsAPI.getNews(page, ELEMENTS_PER_PAGE)
      .then((r: any) => {
        const { data, ...rest } = r;
        setNews(
          data.sort((a, b) =>
            moment(a.lastUpdated, "YYYY-MM-DD HH:mm") >
            moment(b.lastUpdated, "YYYY-MM-DD HH:mm")
              ? -1
              : 1
          )
        );
        setPagination(rest);
      })
      .finally(() => setLoading(""));
  };

  useEffect(() => {
    if (ctxUser && ["ADMIN", "SECRETARIAT"].includes(ctxUser.role)) {
      getNews(1);
    } else {
      navigate("/");
    }
  }, [ctxUser]);

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className='flex items-center justify-between flex-wrap pb-6'>
          <h1 className='uppercase font-bold text-center pb-3'>
            Dashboard administration
          </h1>
          <div className=' flex flex-row flex-wrap gap-3'>
            <button
              disabled={loading !== ""}
              className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-lg px-4 py-2 shadow-md'
              onClick={() => {
                setAction("add");
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className='ml-2 font-semibold'>Add news</span>
            </button>

            <button
              disabled={loading !== ""}
              className='bg-blue-500 hover:bg-blue-300 text-white rounded-lg px-4 py-2 shadow-md'
              onClick={() => {
                navigate("/dashboard/parent");
              }}
            >
              <FontAwesomeIcon icon={faEye} />
              <span className='ml-2 font-semibold'>Preview Dashboard</span>
            </button>
          </div>
        </div>

        <div className='rounded-lg shadow-lg bg-white py-4 px-2'>
          {loading === "fetch" ? (
            <Loader />
          ) : (
            <NewsTable
              news={news}
              setNews={setNews}
              setSelectedItem={setSelectedItem}
              setAction={setAction}
              pagination={pagination}
              getData={getNews}
            />
          )}
        </div>
      </section>

      <NewsModal
        loading={loading}
        setLoading={setLoading}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        action={action}
        setAction={setAction}
        refetch={getNews}
      />
    </div>
  );
}

export default DashboardAdmin;
