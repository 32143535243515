import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { SubjectsAPI } from "api";
import { OPTIONS } from "utils/types/general";
import { SUBJECT } from "utils/types/subject";

interface Props {
  onChange: any;
  data?: SUBJECT[];
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlSubject(props: Props) {
  const {
    onChange,
    data = undefined,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false
  } = props;
  const [subjects, setSubjects] = useState<SUBJECT[]>([]);

  const getSubjects = useCallback(async () => {
    SubjectsAPI.search(null, null, true)
    .then((r: any) => {
        const { data } = r;
        setSubjects(data);
      });
  }, []);

  const options = useMemo(() => {
    return subjects.map((s: SUBJECT) => {
      return {
        label: s.label,
        value: s.value
      };
    });
  }, [subjects]);

  const handleChange = useCallback((e: any) => {
    onChange(e);
  }, []);

  useEffect(() => {
    if (!data) {
      getSubjects();
    } else {
      setSubjects(data);
    }
  }, [data]);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder='Subject'
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlSubject;
