import axios from "axios";
import moment from "moment";
import { isAuth } from "utils/context";
import { TIMETABLE } from "utils/types/agenda";

const get = async (level: string, dateFrom: string, dateTo: string) => {
  if (isAuth()) {
    try {
      const getUrl = `${process.env.REACT_APP_API_URL}timetable?page=1&from=${dateFrom}&to=${dateTo}&level=${level}`;

      const response = await axios.get(getUrl);

      const events: any = [];

      response.data.map((event: TIMETABLE) => {
        const isRecurrent = Boolean(event.recurrent);
        const date = moment(event.date);
        const dow = date.day();

        const temp: any = {
          start: `${event.date.substr(0, 10)}T${event.timeStart}:00`,
          end: `${event.date.substr(0, 10)}T${event.timeEnd}:00`,
          title: event.subjectName,
          data: {
            id: event.id,
            level: event.level,
            date: event.date,
            timeStart: event.timeStart,
            timeEnd: event.timeEnd,
            weekNumber: event.weekNumber,
            termNumber: event.termNumber,
            subjectName: event.subjectName,
            recurrent: event.recurrent,
            cahierDeTexte: event.cahierDeTexte,
            files: event.files ? event.files : [],
            school_year: event.school_year
          }
        };

        if (isRecurrent) {
          temp.daysOfWeek = [dow];
          temp.startTime = `${event.timeStart}:00`;
          temp.endTime = `${event.timeEnd}:00`;
          temp.startRecur = dateFrom;
          temp.endRecur = dateTo;
        }

        events.push(temp);
        return true;
      });

      return events;
    } catch (e) {
      return e;
    }
  }
  return [];
};

const uploadImage = (file) => {
  const url = `${process.env.REACT_APP_API_URL}upload`;

  const formData = new FormData();
  formData.append("file", file);

  const res = axios
    .post(url, formData)
    .then(() => {
      console.log("The file is successfully uploaded");
    })
    .catch(() => {
      console.log("Error while uploading file");
    });

  return res;
};

const add = async (event) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}timetable`;
    const response = await axios.post(url, event);

    return response.data;
  }
  return true;
};

const update = async (event) => {
  if (isAuth()) {
    try {
      const url = `${process.env.REACT_APP_API_URL}timetable/${event.id}`;
      const response = await axios.put(url, event);

      return response.data;
    } catch {
      return {
        error: true,
        status: 404,
        message: "Could not fetch user."
      };
    }
  }
  return true;
};

const deletebyId = async (id: number) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}timetable/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { get, add, update, deletebyId, uploadImage };
