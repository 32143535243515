import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { toast } from "react-toastify";

import Modal from "components/common/Modal";
import Popup from "components/common/Popup";
import OtherPricesForm from "components/prices/OtherPricesForm";

import { PriceAPIv2 } from "api";

import { ACTIONS } from "utils/types/general";
import { PRICE_DETAIL } from "utils/types/prices";

interface Props {
  selectedItem: PRICE_DETAIL;
  setSelectedItem: Dispatch<SetStateAction<PRICE_DETAIL>>;
  action: ACTIONS;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
  refetch: any;
}

function OtherPricesModal(props: Props) {
  const {
    loading,
    setLoading,
    selectedItem,
    setSelectedItem,
    action,
    setAction,
    refetch
  } = props;

  const title = useMemo(() => {
    switch (action) {
      case "add":
        return "Create price";
      case "edit":
        return "Update price";
      case "delete":
        return "Delete price";
      default:
        return "";
    }
  }, [action]);

  const closeModal = useCallback(() => {
    setSelectedItem(undefined);
    setAction(undefined);
  }, []);

  return (
    <Modal
      isOpen={selectedItem !== undefined || action === "add"}
      onClose={closeModal}
      title={title}
      className='md:max-w-[700px] lg:max-w-[700px]'
    >
      {["add", "edit"].includes(action) && (
        <OtherPricesForm
          data={selectedItem}
          action={action}
          refetch={refetch}
          closeModal={closeModal}
        />
      )}
      {action === "delete" && (
        <Popup
          onConfirm={() => {
            setLoading("deleting");
            PriceAPIv2.deletebyId(selectedItem.id).then(() => {
              toast.success("Price deleted successfully", {
                position: "bottom-right"
              });
              refetch();
              closeModal();
            });
          }}
          onClose={() => closeModal()}
          loading={["fetch", "deleting"].includes(loading)}
        />
      )}
    </Modal>
  );
}

export default OtherPricesModal;
