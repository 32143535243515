import { StyleSheet } from "@react-pdf/renderer";

export const pdfStyles = StyleSheet.create({
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%"
  },
  imgWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "75px",
    height: "60px"
  },
  imgStyles: {
    width: "50px",
    height: "60px",
    marginRight: "15px"
  },
  textStyle: {
    fontSize: 9,
    lineHeight: 1.2,
    fontFamily: "Montserrat"
  },
  textStyleSmall: {
    fontSize: 8,
    lineHeight: 1.2,
    fontFamily: "Montserrat"
  },
  textBold: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat"
  },
  textUnderlined: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    textDecoration: "underline",
    marginRight: "4px"
  },
  textTitle: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    textAlign: "center",
    width: "100%",
    marginVertical: 15
  },
  esaInfo: {
    width: "100%"
  },
  infoWrapper: {
    // marginLeft: 'auto',
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-end"
  },
  delaysWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: 10,
    borderTop: "1px solid #f0f0f0",
    borderBottom: "1px solid #f0f0f0",
    paddingVertical: 10
  },
  comment: {
    marginBottom: 8
  }
});

export const tableStyles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 15
  },
  thead: {
    backgroundColor: "#f0f0f0",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    width: "100%",
    paddingVertical: 8,
    textAlign: "center"
  },
  theadBold: {
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    width: "100%"
  },
  col: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    width: "50%",
    paddingVertical: 7
  },
  colLg3: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "25%",
    paddingVertical: 7,
    fontSize: 8,
    fontWeight: 600
  },
  card: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    width: "100%"
  },
  cell: {
    border: "1px solid #dedede",
    padding: "5px",
    fontSize: 8
  },
  cellLeft: {
    // borderRight: 'none !important',
  },
  cellRight: {
    borderLeft: "none !important"
  },
  tableWrapper: {
    margin: "5px 0"
  }
});
