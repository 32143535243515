import axios from "axios";
import { isAuth } from "utils/context";

const search = async (options: any) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by name"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}grades/search`;
    try {
      const response = await axios.post(url, options);
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (grade) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}grades`;
    const response = await axios.post(url, grade);

    return response.data.data;
  }
  return true;
};

const update = async (grade) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}grades/${grade.id}`;
    const response = await axios.put(url, grade);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: number) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}grades/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { search, add, update, deletebyId };
