import axios from "axios";
import { isAuth } from "utils/context";

const getByName = async (name: string) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by name"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}students/searchByName?name=${name}`;
    try {
      const response = await axios.get(url);
      return response.data.data;
    } catch {
      return error;
    }
  }
  return error;
};

const getByLevel = async (name: string) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by name"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}students/searchBylevel?name=${name}`;
    try {
      const response = await axios.get(url);
      return response.data.data;
    } catch {
      return error;
    }
  }
  return error;
};

const get = async (
  page?: number,
  elementsPerPage?: number,
  all?: boolean,
  active?: boolean,
  codes?: string[]
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by code"
  };

  if (isAuth()) {
    try {
      const url = `${process.env.REACT_APP_API_URL}users/search/role?${
        page ? `page=${page}` : ""
      }${
        elementsPerPage ? `&element=${elementsPerPage}` : ""
      }${all ? `&all=${String(all)}` : ""}`;

      const response = await axios.post(url, {
        value: "PARENT",
        active,
        codes
      });

      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (student) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}users`;

    const temp = { ...student };
    const emptyContact = {
      name: "",
      email: "",
      tel: ""
    };
    if (temp.emergencyContact === undefined) {
      temp.emergencyContact = [emptyContact, emptyContact];
    }
    if (temp.dad === undefined) {
      temp.dad = emptyContact;
    }
    if (temp.mom === undefined) {
      temp.mom = emptyContact;
    }

    const response = await axios.post(url, {
      ...temp,
      role: "PARENT"
    });

    return response.data.data;
  }
  return true;
};

const update = async (student) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}users/${student.id}`;

    const temp = { ...student };
    const emptyContact = {
      name: "",
      email: "",
      tel: ""
    };
    if (temp.emergencyContact === undefined) {
      temp.emergencyContact = [emptyContact, emptyContact];
    }
    if (temp.dad === undefined) {
      temp.dad = emptyContact;
    }
    if (temp.mom === undefined) {
      temp.mom = emptyContact;
    }

    const response = await axios.put(url, temp);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}users/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { getByLevel, getByName, get, add, update, deletebyId };
