import PricesInfos from "components/PricesInfos";
import RegistrationInfos from "components/RegistrationInfos";
import React, { useState } from "react";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";

type PriceType = {
  key: string;
  title: string;
  color: string;
};
const PRICE_TYPES: PriceType[] = [
  { key: "daycare", title: "Day care", color: "text-esaPink" },
  { key: "pre_school", title: "Preschool", color: "text-esaTextLightBlue" },
  { key: "elementary", title: "Primary school", color: "text-esaYellow" },
  {
    key: "middle_school",
    title: "Middle school",
    color: "text-esaTextLightBlue"
  }
];

function Page() {
  const [tab, setTab] = useState<PriceType>(PRICE_TYPES[0]);

  return (
    <div id='registration-page' className={BG_COLORED}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <RegistrationInfos color={tab.color} />
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white`}>
          <div className='grid grid-cols-4 border-b'>
            {PRICE_TYPES.map((item: PriceType) => (
              <button
                key={item.key}
                onClick={() => setTab(item)}
                className={`py-4 ${
                  tab.key === item.key
                    ? `font-bold shadow-lg ${item.color}`
                    : ""
                }`}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className='p-6'>
            <PricesInfos type={tab.key} color={tab.color} showTitle={false} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Page;
