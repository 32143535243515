import React, { Dispatch, Fragment, SetStateAction, useCallback } from "react";
import { toast } from "react-toastify";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { InputSwitch } from "primereact/inputswitch";

import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import Loader from "components/common/Loader";

import { PriceAPIv2 } from "api";

import { PRICES_LEVELS } from "utils/constants/prices";
import { formatPriceFormData, formatPriceToSend } from "utils/format/prices";
import { ERROR_STYLE, INPUT_STYLE, SUBMIT_STYLE } from "utils/styles";
import { PRICE, PRICE_LEVEL } from "utils/types/prices";

const FORM_GROUP_STYLE = "border rounded-3xl relative";
const CELL_SPACING = "px-4 lg:px-2 py-2 mx-0 my-0 lg:mx-1 lg:my-1";

interface Props {
  data: PRICE;
  getPrices: (value: string) => void;
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
}
function TuitionFees({ data, getPrices, loading, setLoading }: Props) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted, isSubmitting, isDirty }
  } = useForm<PRICE>({
    defaultValues: formatPriceFormData(data),
    mode: "onBlur"
  });

  const onSubmit: SubmitHandler<PRICE> = useCallback(
    async (formData) => {
      setLoading("addUpdate");
      const priceToSend: PRICE = formatPriceToSend(formData, data);
      if (priceToSend.id) {
        // update price_v2
        const resUpdate = PriceAPIv2.update(priceToSend);
        resUpdate
          .then(() => {
            toast.success("Price updated", { position: "bottom-right" });
            getPrices(priceToSend.school_year);
          })
          .catch(() => {
            toast.error("Error while updating price", {
              position: "bottom-right"
            });
          })
          .finally(() => setLoading(""));
      } else {
        // create price_v2
        const resCreate = PriceAPIv2.add(priceToSend);
        resCreate
          .then(() => {
            toast.success("Price added", { position: "bottom-right" });
            getPrices(priceToSend.school_year);
          })
          .catch(() => {
            toast.error("Error while adding price", {
              position: "bottom-right"
            });
          })
          .finally(() => setLoading(""));
      }
    },
    [data]
  );

  const renderPriceInput = useCallback(
    (priceKey: string, priceLevel: PRICE_LEVEL, bgColor: string) => {
      const hasError =
        errors &&
        errors.prices &&
        errors.prices[priceKey] &&
        errors.prices[priceKey][priceLevel.key];

      return (
        <div
          key={`${priceLevel.key}${priceKey}`}
          className={`${bgColor} ${CELL_SPACING}`}
        >
          <p className='block lg:hidden font-bold'>{priceLevel.title}</p>
          <div
            className={`${FORM_GROUP_STYLE} rounded-lg ${
              isSubmitted || hasError
                ? hasError
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              disabled={isSubmitting}
              className={`${INPUT_STYLE} rounded-lg`}
              type='text'
              {...register(`prices.${priceKey}.${priceLevel.key}` as any, {
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please provide a valid price"
                }
              })}
            />
            {(isSubmitted || hasError) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  hasError ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
        </div>
      );
    },
    [register, errors, isSubmitted]
  );

  const renderSimplePriceRow = useCallback(
    (priceKey: string, bgColor: string) => (
      <div
        key={priceKey}
        className={`grid grid-cols-1 lg:grid-cols-5 ${bgColor} lg:bg-slate-50 mb-5 lg:mb-0 py-6 lg:py-0 rounded-lg lg:rounded-none shadow-md lg:shadow-none`}
      >
        <div className={`${bgColor} ${CELL_SPACING}`}>
          {data.prices[priceKey].label}
        </div>
        {PRICES_LEVELS.map((priceLevel: PRICE_LEVEL) =>
          renderPriceInput(priceKey, priceLevel, bgColor)
        )}
      </div>
    ),
    [register, data, errors, isSubmitted]
  );

  const renderDetailedPriceRow = useCallback(
    (priceKey: string, bgColor: string) => (
      <div
        key={priceKey}
        className={`grid grid-cols-1 lg:grid-cols-5 ${bgColor} lg:bg-slate-50 mb-5 lg:mb-0 py-6 lg:py-0 rounded-lg lg:rounded-none shadow-md lg:shadow-none`}
      >
        <div className={`${bgColor} ${CELL_SPACING}`}>
          {data.prices[priceKey].label}
        </div>
        {PRICES_LEVELS.map((priceLevel: PRICE_LEVEL) => (
          <Fragment key={`${priceLevel.key}${priceKey}`}>
            {priceLevel.key === "daycare" ? (
              <div className={`${bgColor} ${CELL_SPACING}`}>
                <p className='block lg:hidden font-bold'>{priceLevel.title}</p>
                {["part_time", "full_time"].map((timeInterval: string) => {
                  const hasError =
                    errors &&
                    errors.prices &&
                    errors.prices[priceKey] &&
                    errors.prices[priceKey][priceLevel.key] &&
                    errors.prices[priceKey][priceLevel.key][timeInterval];

                  return (
                    <div
                      key={`${priceLevel.key}${priceKey}${timeInterval}`}
                      className='pl-8 lg:pl-0 pr-4 lg:pr-0'
                    >
                      <p>
                        {timeInterval === "part_time"
                          ? "Part time"
                          : "Full time"}
                      </p>
                      <div
                        className={`${FORM_GROUP_STYLE} rounded-lg ${
                          isSubmitted || hasError
                            ? hasError
                              ? "border-rose-600"
                              : "border-lime-500"
                            : ""
                        }`}
                      >
                        <input
                          disabled={isSubmitting}
                          className={`${INPUT_STYLE} rounded-lg`}
                          type='text'
                          {...register(
                            `prices.${priceKey}.${priceLevel.key}.${timeInterval}` as any,
                            {
                              required: true,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please provide a valid price"
                              }
                            }
                          )}
                        />
                        {(isSubmitted || hasError) && (
                          <div
                            className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                              hasError ? "bg-rose-600" : "bg-lime-500"
                            }`}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              renderPriceInput(priceKey, priceLevel, bgColor)
            )}
          </Fragment>
        ))}
      </div>
    ),
    [register, data, errors, isSubmitted]
  );

  const renderTableHeader = useCallback(
    (bgColor: string, title: string) => (
      <div className='hidden lg:grid grid-cols-1 lg:grid-cols-5'>
        <div
          className={`${bgColor} ${CELL_SPACING} font-bold flex items-center justify-center`}
        >
          {title}
        </div>
        {PRICES_LEVELS.map((priceLevel: PRICE_LEVEL) => (
          <div key={priceLevel.key}>
            <p
              className={`${bgColor} ${CELL_SPACING} text-center font-bold text-md`}
            >
              {priceLevel.title}
            </p>
            <p
              className={`${bgColor} ${CELL_SPACING} text-center font-semibold text-slate-500 min-h-16`}
            >
              {priceLevel.description}
            </p>
          </div>
        ))}
      </div>
    ),
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='flex flex-wrap gap-6 pb-8'>
        <div id='school_year' className='min-w-72 flex flex-col'>
          <p className='font-bold pb-2'>School Year</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.school_year
                ? errors.school_year
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='school_year'
              rules={{ required: "Please provide a school year" }}
              render={({ field: { onChange, value } }) => (
                <DdlSchoolYear
                  disabled={isSubmitting || loading !== ""}
                  onChange={(e) => {
                    onChange(e.value);
                    getPrices(e.value);
                  }}
                  value={value ? { label: value, value } : undefined}
                />
              )}
            />
          </div>
          {errors.school_year && (
            <span className={ERROR_STYLE}>
              {(errors as any).school_year.message}
            </span>
          )}
        </div>

        <div id='application_deadline'>
          <p className='font-bold pb-2'>Application deadline</p>
          <div
            className={`${FORM_GROUP_STYLE} mb-6 ${
              isSubmitted || errors.application_deadline
                ? errors.application_deadline
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              disabled={isSubmitting || loading !== ""}
              className={`${INPUT_STYLE} min-h-[52px] min-w-52`}
              placeholder='Application deadlne'
              type='date'
              {...register("application_deadline", {
                required: "Application deadline is required"
              })}
            />
            {(isSubmitted || errors.application_deadline) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.application_deadline ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.application_deadline && (
            <span className={ERROR_STYLE}>
              {errors.application_deadline.message}
            </span>
          )}
        </div>

        <div id='apply_to_website'>
          <p className='font-bold pb-2'>Apply to website</p>
          <Controller
            control={control}
            name='apply_to_website'
            render={({ field: { onChange, value } }) => (
              <div className='flex flex-row items-center'>
                <InputSwitch
                  disabled={isSubmitting || loading !== ""}
                  checked={value}
                  onChange={(e) => onChange(e.value)}
                />
              </div>
            )}
          />
        </div>

        <div className='flex items-center ml-auto'>
          <button
            disabled={isSubmitting || loading !== ""}
            type='button'
            className={`${SUBMIT_STYLE} bg-rose-500 text-white`}
            onClick={() => {
              reset();
              toast.info("Changes discarded", { position: "bottom-right" });
            }}
          >
            Discard changes
          </button>
          {isSubmitting || loading ? (
            <div className={`${SUBMIT_STYLE} bg-gray-100`}>
              <Loader width='w-6' height='h-6' text='text-gray-300' />
            </div>
          ) : (
            <input
              type='submit'
              disabled={isSubmitting || !isDirty || loading !== ""}
              value='Save'
              className={`${SUBMIT_STYLE} bg-esaMinty text-white disabled:opacity-50 min-h-11`}
            />
          )}
        </div>
      </div>

      {data && (
        <>
          {renderTableHeader("bg-blue-100", "For the application")}

          {["registration_fee", "book_photocopy", "materials", "insurance"].map(
            (priceKey: string) => renderSimplePriceRow(priceKey, "bg-blue-100")
          )}

          {["september_fee", "total"].map((priceKey: string) =>
            renderDetailedPriceRow(priceKey, "bg-blue-100")
          )}

          {renderTableHeader("bg-pink-300", "From October, you have 3 choices")}

          {["monthly", "trimester", "annual"].map((priceKey: string) =>
            renderDetailedPriceRow(priceKey, "bg-pink-100")
          )}

          {renderTableHeader("bg-indigo-100", "Re-registration")}

          {["re_registration"].map((priceKey: string) =>
            renderSimplePriceRow(priceKey, "bg-indigo-50")
          )}
        </>
      )}
    </form>
  );
}

export default TuitionFees;
