import React from "react";
import Card from "components/common/Card";
import { CARD_TITLE } from "utils/styles";

const SchoolBus = () => {
  return (
    <Card
      images={[
        { src: "/images/bus1.PNG", alt: "bus 1" },
        { src: "/images/bus2.PNG", alt: "bus 2" }
      ]}
    >
      <p className={`${CARD_TITLE} pb-4`}>School bus</p>
      <hr />
      <p className='py-4'>
        The school bus is at your disposal. The driver can take your child on a
        meeting point every morning and every afternoon. With the driver, there
        will be a driver helper to supervise all children in the school bus.
      </p>
      <p> School bus should be at school no later than 8:00 AM.</p>
    </Card>
  );
};

export default SchoolBus;
