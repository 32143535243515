/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { TERM } from "utils/types/term";

export const getDaysBetweenDates = (
  start: string,
  end: string,
  dayName: string
) => {
  const startM = moment(start);
  const endM = moment(end);

  const result: any[] = [];
  const days = {
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6
  };
  const day = days[dayName.toLowerCase().substr(0, 3)];

  const current = startM.clone();

  while (current.day(7 + day).isBefore(endM)) {
    result.push(current.clone());
  }

  return result.map((m) => m.format("YYYY-MM-DD"));
};

export const getCurrentSchoolYear = () => {
  const todayYear = moment().format("YYYY");
  const todayMonth = moment().format("MM");

  let schoolYear = `${Number(todayYear) - 1}-${todayYear}`;
  if (Number(todayMonth) >= 9) {
    schoolYear = `${todayYear}-${Number(todayYear) + 1}`;
  }

  return schoolYear;
};

export const dateFallsInSchoolYear = (strDate: string, schoolYear: string) => {
  // Extract years and months from schoolYear and strDate
  const [currentYearStart, currentYearEnd] = schoolYear.split("-");
  const [dateYear, dateMonth, dateDay] = strDate.split("-");

  // Compare strDate against school year boundaries
  if (
    (Number(dateYear) > Number(currentYearStart) &&
      Number(dateYear) < Number(currentYearEnd)) || // If the year is within the school year range
    (dateYear === currentYearStart && Number(dateMonth) >= 9) || // If the year is the starting year and the invoice month is September or later
    (dateYear === currentYearEnd && Number(dateMonth) <= 6) // If the year is the ending year and the invoice month is June or earlier
  ) {
    return true;
  }
  return false;
};

// Function to get the school year based on a given date
export const getSchoolYearForDate = (myDate: string): string => {
  const dateMoment = moment(myDate, "YYYY-MM-DD");
  const dateYear = dateMoment.year();
  const dateMonth = dateMoment.month() + 1; // Month is zero-indexed in moment.js

  // If the month is September or later, it's the start of a new school year
  if (dateMonth >= 9) {
    return `${dateYear}-${dateYear + 1}`;
  }

  // If the month is before September, it's the previous school year
  return `${dateYear - 1}-${dateYear}`;
};

export const whichTerm = (myDate: string, terms: TERM[]): TERM | undefined => {
  const targetDate = moment(myDate).format("YYYY-MM-DD");

  const matchingTerm = terms.find((term: TERM) => {
    const termStart = moment(term.start);
    const termEnd = moment(term.end);

    // Check if the date is between the start and end of the term (inclusive)
    return moment(targetDate).isBetween(termStart, termEnd, undefined, "[]");
  });

  return matchingTerm;
};

export const whichTermOrClosest = (
  myDate: string,
  terms: TERM[]
): TERM | undefined => {
  const targetDate = moment(myDate).format("YYYY-MM-DD");

  // Step 1: Find the matching term
  const matchingTerm = terms.find((term: TERM) => {
    const termStart = moment(term.start);
    const termEnd = moment(term.end);

    // Check if the date is between the start and end of the term (inclusive)
    return moment(targetDate).isBetween(termStart, termEnd, undefined, "[]");
  });

  // Step 2: If a matching term is found, return it
  if (matchingTerm) {
    return matchingTerm;
  }

  // Step 3: If no matching term, find the closest term by date difference
  const closestTerm = terms.reduce((closest: TERM | undefined, term: TERM) => {
    const termStart = moment(term.start);
    const termEnd = moment(term.end);

    // Calculate distance to term start
    const startDifference = Math.abs(termStart.diff(targetDate));
    const endDifference = Math.abs(termEnd.diff(targetDate));

    // Determine the closest term based on the smallest time difference
    const currentClosestDiff = closest
      ? Math.min(
          Math.abs(moment(closest.start).diff(targetDate)),
          Math.abs(moment(closest.end).diff(targetDate))
        )
      : Infinity;

    const termClosestDiff = Math.min(startDifference, endDifference);

    return termClosestDiff < currentClosestDiff ? term : closest;
  }, undefined);

  return closestTerm;
};

export const getAllSchoolYearsOptions = () => {
  const currentDate = new Date();
  const arrSchoolYears = [];

  // Get the current year
  const currentYear = currentDate.getFullYear();
  if (currentYear > 2021) {
    // eslint-disable-next-line no-plusplus
    for (let index = 2021; index <= currentYear; index++) {
      arrSchoolYears.push(`${index}-${index + 1}`);
    }
  }

  return arrSchoolYears.map((year: string) => {
    return { label: year, value: year };
  });
};
