import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import packageJson from "../../../package.json";

const FOOTER_SOCIALS_STYLE =
  "bg-white w-10 h-10 rounded-full text-black flex items-center justify-center mx-2 hover:bg-black hover:text-white";
function Footer() {
  return (
    <footer className='text-white bg-[#1E2A4F] p-8 pb-4 grid grid-cols-1 lg:grid-cols-5 gap-2'>
      <div className='lg:col-span-1 flex justify-start items-center'>
        <Link to='/' className='w-20 h-24 mr-6'>
          <LazyLoadImage src='/images/logo.png' width={80} height={93} />
        </Link>
      </div>
      <div className='lg:col-span-4 flex flex-col justify-center lg:flex-row lg:justify-between'>
        <div className='footer-address'>
          <div className='flex items-center'>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <div className='ml-4'>
              <p>Elementary School of Antananarivo</p>
              <p>Near Atlantis Restaurant, Ambohitrarahaba</p>
            </div>
          </div>
          <a className='flex items-center py-4' href='tel:+261 34 19 302 87'>
            <FontAwesomeIcon icon={faPhone} />
            <span className='ml-2'>034 19 302 87</span>
          </a>
          <a className='flex items-center' href='mailto:contact@esa-mada.com'>
            <FontAwesomeIcon icon={faEnvelope} />
            <span className='ml-2'>contact@esa-mada.com</span>
          </a>
        </div>

        <div className='footer-socials flex items-center mt-4 lg:mt-0'>
          <a
            className={FOOTER_SOCIALS_STYLE}
            href='https://www.facebook.com/Elementary-School-of-Antananarivo-147788855917552/'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>

          <a
            className={FOOTER_SOCIALS_STYLE}
            href='https://www.instagram.com/elementaryschoolantananarivo/'
            target='_blank'
            rel='noreferrer'
          >
            <i className='icon fab fa-instagram' />
            <FontAwesomeIcon icon={faInstagram} />
          </a>

          <a
            className={FOOTER_SOCIALS_STYLE}
            href='https://www.youtube.com/channel/UCS79-myEV5TH2wHkLwKsNEA'
            target='_blank'
            rel='noreferrer'
          >
            <i className='icon fab fa-youtube' />
            <FontAwesomeIcon icon={faYoutube} />
          </a>

          <a
            className={FOOTER_SOCIALS_STYLE}
            href='https://www.linkedin.com/company/esa-mada'
            target='_blank'
            rel='noreferrer'
          >
            <i className='icon fab fa-linkedin-in' />
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div className='lg:col-span-5 border-t border-b-none text-center pt-4 mt-4'>
        {`v.${packageJson.version} © 2024`}
      </div>
    </footer>
  );
}

export default Footer;
