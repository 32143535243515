import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Document, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import Loader from "components/common/Loader";
import GradesHeaderForm from "components/grades/GradesHeaderForm";
import Mark from "components/grades/Mark";
import ReportCard from "components/grades/ReportCard";

import { GradesAPI, LevelsAPI } from "api";
import { useUser } from "context/user.context";

import { getStatsAttendance } from "utils/api_calls/grade";
import {
  DEFAULT_MARKS,
  PRE_K_TEMPLATE_MARKS,
  PRE_K_TEMPLATE_REPORT_CARD,
  PRE_K_TEMPLATE_SUBJECTS
} from "utils/constants/grade";
import {
  formatExamGrades,
  formatHeaderQuizz,
  formatQuizzGrades,
  formatReportGrades,
  generateEmptyGrades
} from "utils/format/grade";
import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  SUBMIT_STYLE
} from "utils/styles";
import {
  GRADE,
  GRADE_PARAMS,
  GRADE_HEADER,
  REPORT_ITEM,
  STATS_ATTENDANCE
} from "utils/types/grade";
import { LEVEL } from "utils/types/level";
import GradeQuizz from "components/grades/GradeQuizz";
import GradeExam from "components/grades/GradeExam";
import { toast } from "react-toastify";
import GradesTemplate from "components/templates/GradesTemplate";
import ReportCardTemplate from "components/templates/ReportCardTemplate";

const BTN_ACTIONS = `${SUBMIT_STYLE} w-full shadow-md min-h-12 flex items-center justify-center  font-semibold text-white my-6 shadow-slate-400 disabled:bg-slate-200 disabled:text-slate-300 disabled:cursor-not-allowed disabled:shadow-slate-300`;

function Page() {
  const { isAuth, ctxUser } = useUser();
  const navigate = useNavigate();

  const [statsAttendance, setStatsAttendance] = useState<STATS_ATTENDANCE>();
  const [subjects, setSubjects] = useState<string[]>([]);
  const [level, setLevel] = useState<LEVEL>();
  const [isSmall, setIsSmall] = useState<boolean>(false);
  const [grade, setGrade] = useState<GRADE>();
  const [headerQuizz, setHeaderQuizz] = useState<GRADE_HEADER[]>([]);
  const [params, setParams] = useState<GRADE_PARAMS>();
  const [loading, setLoading] = useState<string>("");

  const getGrades = async (params: GRADE_PARAMS) => {
    setLoading("grade");
    setParams(params);
    const resStatsAttendance = await getStatsAttendance(params);
    setStatsAttendance(resStatsAttendance);

    // get first student grades
    const resGrades: GRADE = await GradesAPI.search({
      codeEleve: params.student.codeEleve,
      term: params.term.termNb,
      schoolYear: params.schoolYear,
      name: params.student.name,
      level: params.student.level
    }).then((r) => r[0]);

    // student level at the time of search
    // can be different than the current student level if is in past schoolYears
    const gradeLevel = resGrades.level;
    // get subjects for the corresponding level
    const resLevel: LEVEL = await LevelsAPI.getByName(gradeLevel).then(
      (r: any) => r[0]
    );

    setLevel(resLevel);

    const IS_SMALL_CLASSES = [
      "Pre Kindergarten",
      "Kindergarten",
      "Preschool"
    ].includes(gradeLevel);

    setHeaderQuizz(formatHeaderQuizz(resLevel));
    const emptyGrades = generateEmptyGrades(resLevel);

    if (IS_SMALL_CLASSES) {
      setIsSmall(true);
      setSubjects(PRE_K_TEMPLATE_SUBJECTS);

      setGrade({
        ...resGrades,
        gradeQuiz:
          resGrades.gradeQuiz.length === 0
            ? emptyGrades.emptyQuizzGrades
            : resGrades.gradeQuiz,
        gradeExam:
          resGrades.gradeExam.length === 0
            ? emptyGrades.emptyExamGrades
            : resGrades.gradeExam,
        reportData:
          resGrades.reportData === undefined ||
          resGrades.reportData.length === 0
            ? PRE_K_TEMPLATE_REPORT_CARD
            : resGrades.reportData[0].subject !== PRE_K_TEMPLATE_SUBJECTS[0]
              ? PRE_K_TEMPLATE_REPORT_CARD
              : resGrades.reportData
      });
    } else {
      setSubjects(resLevel.subjects);
      const TEMPLATE_REPORT_CARD: REPORT_ITEM[] = [
        ...emptyGrades.emptyReportData,
        { comments: "" } as any
      ];

      setGrade({
        ...resGrades,
        gradeQuiz:
          resGrades.gradeQuiz.length === 0
            ? emptyGrades.emptyQuizzGrades
            : resGrades.gradeQuiz,
        gradeExam:
          resGrades.gradeExam.length === 0
            ? emptyGrades.emptyExamGrades
            : resGrades.gradeExam,
        reportData:
          resGrades.reportData === undefined ||
          resGrades.reportData.length === 0
            ? TEMPLATE_REPORT_CARD
            : resGrades.reportData
      });
    }
    setLoading("");
  };

  const handleSave = useCallback(
    (gradeParam: GRADE) => {
      setLoading("save");
      if (gradeParam.id) {
        GradesAPI.update(gradeParam)
          .then(() => {
            setLoading("");
            toast.success("Grades updated successfully", {
              position: "bottom-right"
            });
          })
          .finally(() => getGrades(params));
      } else {
        // create grade
        GradesAPI.add(gradeParam)
          .then(() => {
            setLoading("");
            toast.success("Grades saved successfully", {
              position: "bottom-right"
            });
          })
          .finally(() => getGrades(params));
      }
    },
    [params]
  );

  const generateGradesPDF = useCallback(
    async (data: GRADE) => {
      setLoading("download");
      const doc = (
        <Document>
          <GradesTemplate
            data={data}
            info={params}
            nbQuizz={level.quizz ? Number(level.quizz) : 0}
          />
        </Document>
      );

      await pdf(doc)
        .toBlob()
        .then((r: Blob) => {
          if (r.size === 660) {
            generateGradesPDF(data);
          } else {
            saveAs(
              r,
              `Grades ${params.student.name} ${params.student.level} - Term ${params.term.termNb} ${params.schoolYear}`
            );
            setLoading("");
          }
        });
    },
    [params, level]
  );

  const generateReportCardPDF = useCallback(
    async (
      data: any,
      info: GRADE_PARAMS,
      reportSubjects: string[],
      delays: any
    ) => {
      setLoading("download");

      const commentaireObj = data.filter((a: any) => a.comments);
      const commentaire =
        commentaireObj && commentaireObj.length > 0
          ? commentaireObj[0].comments
          : "";

      const doc = (
        <Document>
          <ReportCardTemplate
            data={data}
            info={params}
            subjects={reportSubjects}
            delays={delays}
            isSmall={isSmall}
            commentaire={commentaire}
          />
        </Document>
      );

      await pdf(doc)
        .toBlob()
        .then((r: Blob) => {
          if (r.size === 660) {
            generateReportCardPDF(data, info, reportSubjects, delays);
          } else {
            saveAs(
              r,
              `Report Card ${params.student.name} ${params.student.level} - Term ${params.term.termNb} ${params.schoolYear}`
            );
            setLoading("");
          }
        });
    },
    [params]
  );

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <div id='grades-page' className={`${BG_COLORED} py-8`}>
      {isAuth && (
        <>
          <section
            id='grades-header'
            className={`${CONTAINER} ${CONTAINER_PADDING} lg:px-12 pb-4`}
          >
            <div className='grid grid-cols-1 md:grid-cols-[7fr_3fr] gap-4'>
              <div
                id='grades-filters'
                className='rounded-lg shadow-lg bg-white p-4'
              >
                <GradesHeaderForm
                  callback={getGrades}
                  loading={["grade", "save"].includes(loading)}
                />
                {params && (
                  <p className='font-bold'>{`${params.student.name} - ${params.student.level || ""}`}</p>
                )}
              </div>
              <div id='grades-actions'>
                <div className='rounded-lg shadow-lg bg-white py-2'>
                  <Mark
                    marks={isSmall ? PRE_K_TEMPLATE_MARKS : DEFAULT_MARKS}
                  />
                </div>

                <button
                  disabled={loading === "download" || !grade}
                  className={`${BTN_ACTIONS}  bg-esaMintyDark hover:bg-esaMinty `}
                  onClick={() => generateGradesPDF(grade)}
                >
                  {loading ? (
                    <Loader fill='fill-green-600' />
                  ) : (
                    <span>Download Quiz and Exams</span>
                  )}
                </button>

                <button
                  disabled={loading === "download" || !grade}
                  className={`${BTN_ACTIONS} bg-blue-400 hover:bg-blue-200`}
                  onClick={() =>
                    generateReportCardPDF(
                      grade.reportData,
                      params,
                      isSmall ? subjects : level.subjects,
                      statsAttendance
                    )
                  }
                >
                  {loading ? (
                    <Loader fill='fill-blue-600' />
                  ) : (
                    <span>Download Report card</span>
                  )}
                </button>
              </div>
            </div>
          </section>

          {params &&
            (["grade", "save"].includes(loading) ? (
              <div
                className={`${CONTAINER} ${CONTAINER_PADDING} lg:px-12 pt-4`}
              >
                <Loader />
              </div>
            ) : (
              grade && (
                <>
                  <section
                    id='quizz'
                    className={`${CONTAINER} ${CONTAINER_PADDING} lg:px-12 pt-4`}
                  >
                    <GradeQuizz
                      grade={grade}
                      data={grade.gradeQuiz}
                      formData={formatQuizzGrades(grade.gradeQuiz, headerQuizz)}
                      role={ctxUser.role}
                      headers={headerQuizz}
                      params={params}
                      callback={handleSave}
                    />
                  </section>

                  <section
                    id='exam'
                    className={`${CONTAINER} ${CONTAINER_PADDING} lg:px-12 pt-0`}
                  >
                    <GradeExam
                      grade={grade}
                      data={grade.gradeExam}
                      formData={formatExamGrades(grade.gradeExam)}
                      role={ctxUser.role}
                      params={params}
                      callback={handleSave}
                    />
                  </section>

                  <section
                    id='report-card'
                    className={`${CONTAINER} ${CONTAINER_PADDING} lg:px-12 pt-0`}
                  >
                    <ReportCard
                      grade={grade}
                      data={grade.reportData}
                      formData={formatReportGrades(grade.reportData)}
                      params={params}
                      isSmall={isSmall}
                      subjects={subjects}
                      attendance={statsAttendance}
                      callback={handleSave}
                      canEdit={["TUTOR", "ADMIN"].includes(ctxUser.role)}
                    />
                  </section>
                </>
              )
            ))}
        </>
      )}
    </div>
  );
}

export default Page;
