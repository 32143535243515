import axios from "axios";
import { isAuth } from "utils/context";

const upload = async (folder: string, filesData: any, subFolder = "") => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to upload Files"
  };

  if (isAuth()) {
    try {
      let res: any[] = [];
      let resUploads;
      let urlUpload = `${process.env.REACT_APP_API_URL}upload/${folder}`;
      if (subFolder !== "") {
        urlUpload = `${process.env.REACT_APP_API_URL}upload/${folder}/${subFolder}`;
      }
      if (filesData.uploadedFiles && filesData.uploadedFiles.length > 0) {
        const formData = new FormData();

        [...filesData.uploadedFiles].map((f, i) => {
          formData.append(`files[${i}]`, f);
          return i;
        });

        // uplaod files in storage
        const response = await axios.post(urlUpload, formData);
        resUploads = response.data;
      }

      if (resUploads) {
        res = resUploads.data;
      }

      if (filesData.existingFiles && filesData.existingFiles.length > 0) {
        filesData.existingFiles.map((a: any) => res.push(a));
      }

      // remove files from storage
      if (filesData.removedFiles && filesData.removedFiles.length > 0) {
        await axios.put(urlUpload, filesData.removedFiles);
      }
      return res;
    } catch {
      return error;
    }
  }
  return error;
};

const download = async (path: string, filePath: string) => {
  let contentType = "application/pdf";
  // set Content-type
  if (filePath.indexOf(".doc") !== -1) {
    contentType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  }

  if (filePath.indexOf(".xls") !== -1) {
    contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }

  if (filePath.indexOf(".pdf") !== -1) {
    contentType = "application/pdf";
  }

  if (filePath.indexOf(".jpeg") !== -1 || filePath.indexOf(".jpg") !== -1) {
    contentType = "image/jpeg; charset=utf-8";
  }

  if (filePath.indexOf(".png") !== -1) {
    contentType = "image/jpeg; charset=utf-8";
  }

  const urlDownload = `${process.env.REACT_APP_API_URL}download?path=${path}&filePath=${filePath}`;
  const response = await axios
    .get(urlDownload, { headers: { "Content-type": contentType } })
    .then((r) => new Blob([r.data]))
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filePath);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  return response;
};

export { upload, download };
