import { OPTIONS } from "utils/types/general";

export const lpad = (value: number, padding: number) => {
  const zeroes = new Array(padding + 1).join("0");
  return (zeroes + value).slice(-padding);
};

export const formatPrice = (str: string) => {
  return str === ""
    ? ""
    : `${Number(str).toLocaleString().toString().replace(/,/g, " ")} AR`;
};

export const getDefaultLevelOptions = (level: string | string[]) => {
  if (typeof level === "string") {
    return { label: level, value: level };
  }
  if (level instanceof Array && level.length > 0) {
    return level.map((val: string) => {
      return { label: val, value: val };
    });
  }
};

export const getArrayOfValues = (options: OPTIONS | OPTIONS[]) => {
  const isArray = Array.isArray(options);
  if (isArray) {
    return (options as OPTIONS[]).map((val: OPTIONS) => val.value);
  } else {
    return options.value;
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
