import React, { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";

import { LEVEL } from "utils/types/level";
import { LevelsAPI } from "api";
import DdlSubject from "components/dropdowns/DdlSubject";

interface Props {
  data?: LEVEL;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function LevelForm(props: Props) {
  const { data, action, refetch, closeModal } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<LEVEL>({
    mode: "onChange",
    defaultValues: {
      ...data
    }
  });

  const addUpdate = useCallback(
    (dataToSend: any) => {
      const sbjs = dataToSend.subjects.map((a) => a.value);

      const newData = { ...dataToSend, subjects: sbjs };
      if (dataToSend.id || action === "edit") {
        LevelsAPI.update(newData)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch();
            closeModal();
          });
      } else {
        LevelsAPI.add(newData)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch();
            closeModal();
          });
      }
    },
    [action]
  );

  const onSubmit: SubmitHandler<LEVEL> = async (data) => {
    setLoading(true);
    addUpdate({ ...data });
  };

  useEffect(() => {
    if (action === "edit") {
      trigger(); // triggers validation but does not submit the form
    }
  }, [action]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 gap-4'>
        <div id='label'>
          <p className='font-bold pb-2'>Level</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.label
                ? errors.label
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              className={INPUT_STYLE}
              placeholder='Level'
              type='text'
              {...register("label", {
                required: "Level label is required"
              })}
            />
            {(isSubmitted || errors.label) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.label ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.label && (
            <span className={ERROR_STYLE}>{errors.label.message}</span>
          )}
        </div>

        <div id='quizz'>
          <p className='font-bold pb-2'>Number of quizz</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.quizz
                ? errors.quizz
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              className={INPUT_STYLE}
              placeholder='Number of quizz'
              type='number'
              {...register("quizz", {
                required: "Number of quizz is required and should be a number"
              })}
            />
            {(isSubmitted || errors.quizz) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.quizz ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.quizz && (
            <span className={ERROR_STYLE}>{errors.quizz.message}</span>
          )}
        </div>

        <div id='subjects'>
          <p className='font-bold pb-2'>Subjects</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.subjects
                ? errors.subjects
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='subjects'
              rules={{ required: "Subjects is required" }}
              render={({ field: { onChange, value } }) => {
                return (
                  <DdlSubject
                    onChange={(e) => {
                      onChange(
                        e.map((item: any) => {
                          return { value: item.value, label: item.label };
                        })
                      );
                    }}
                    isMulti
                    value={
                      value
                        ? value.map((val: any) => {
                            if (typeof val === "string") {
                              return { label: val, value: val };
                            }
                            return { label: val.label, value: val.value };
                          })
                        : undefined
                    }
                  />
                );
              }}
            />
          </div>
          {errors.subjects && (
            <span className={ERROR_STYLE}>{errors.subjects.message}</span>
          )}
        </div>
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create level" : "Update level"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default LevelForm;
