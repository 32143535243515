import React, { Dispatch, SetStateAction } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import DdlUser from "components/dropdowns/DdlUser";

import { useUser } from "context/user.context";

import {
  DEFAULT_INVOICE_FILTERS,
  INVOICE_FILTER_PAID
} from "utils/constants/invoice";
import { OPTIONS } from "utils/types/general";
import {
  INVOICE_ACTIONS,
  T_INVOICE_PAID_FILTER,
  INVOICE_SEARCH_PARAMS
} from "utils/types/invoice";
import { USER } from "utils/types/user";
import { INPUT_STYLE } from "utils/styles";

interface Props {
  callback: (value: INVOICE_SEARCH_PARAMS) => void;
  loading: boolean;
  setAction: Dispatch<SetStateAction<INVOICE_ACTIONS>>;
  users: USER[];
}

function InvoicesFilterForm(props: Props) {
  const { ctxUser } = useUser();
  const { callback, loading, setAction, users } = props;

  const { handleSubmit, control, watch, resetField } =
    useForm<INVOICE_SEARCH_PARAMS>({
      defaultValues: {
        ...DEFAULT_INVOICE_FILTERS,
        paid: ctxUser.role === "PARENT" ? "all" : "invoice",
        codes:
          ctxUser.role === "PARENT"
            ? [ctxUser.codeEleve]
            : DEFAULT_INVOICE_FILTERS.codes
      }
    });

  const onSubmit: SubmitHandler<INVOICE_SEARCH_PARAMS> = async (data) => {
    callback(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex items-center justify-between flex-wrap pb-6'>
        <h1 className='w-full md:w-[25%] uppercase font-bold text-center md:text-left pb-3'>
          {["ADMIN", "SECRETARIAT"].includes(ctxUser.role) && "Manage Invoices"}
        </h1>
        <div className='flex flex-wrap items-end justify-end ml-auto gap-4'>
          {["ADMIN", "SECRETARIAT"].includes(ctxUser.role) && (
            <button
              disabled={loading}
              type='button'
              className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-lg px-4 py-2 shadow-md m-0 min-h-14'
              onClick={() => {
                setAction("add");
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className='ml-2 font-semibold'>Add a new invoice</span>
            </button>
          )}
          <div id='filter-schoolYear'>
            <p className='text-right mb-2 font-bold pr-3'>School year</p>
            <Controller
              control={control}
              name='schoolYear'
              rules={{ required: "Please provide a school year" }}
              render={({ field: { onChange, value } }) => (
                <DdlSchoolYear
                  className='min-w-[300px] border rounded-3xl'
                  onChange={(e: OPTIONS) => {
                    onChange(e.value);
                    callback(watch());
                  }}
                  value={{ label: value, value: value }}
                  disabled={loading}
                />
              )}
            />
          </div>
          <div
            id='filter-all-invoice-receipt'
            className='flex items-center gap-4'
          >
            {INVOICE_FILTER_PAID.map(
              (item: T_INVOICE_PAID_FILTER, iItem: number) => {
                const styles = [
                  {
                    inactive: "bg-white text-slate-800",
                    active: "bg-blue-500 scale-110 text-white",
                    default: "hover:bg-blue-500 hover:text-white"
                  },
                  {
                    inactive: "bg-white text-slate-800",
                    active: "bg-esaMinty scale-110 text-white",
                    default: "hover:bg-esaMinty hover:text-white"
                  },
                  {
                    inactive: "bg-white text-slate-800",
                    active: "bg-rose-500 scale-110 text-white",
                    default: "hover:bg-rose-500 hover:text-white"
                  }
                ];
                return (
                  <Controller
                    key={item.key}
                    control={control}
                    name='paid'
                    render={({ field: { onChange } }) => (
                      <button
                        type='submit'
                        className={`${watch("paid") !== item.paid ? styles[iItem].inactive : styles[iItem].active} ${styles[iItem].default} rounded-3xl px-4 py-2 shadow-md min-h-12 disabled:opacity-60`}
                        onClick={() => {
                          onChange(item.paid);
                          callback(watch());
                        }}
                        disabled={loading}
                      >
                        <span className='font-semibold capitalize'>
                          {item.text}
                        </span>
                      </button>
                    )}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-[4fr_4fr_1fr] gap-4 my-6'>
        <div
          id='codes'
          className={`${["ADMIN", "SECRETARIAT"].includes(ctxUser.role) ? "border rounded-3xl" : ""} lg:max-w-[550px]`}
        >
          {["ADMIN", "SECRETARIAT"].includes(ctxUser.role) ? (
            <Controller
              control={control}
              name='codes'
              rules={{ required: false }}
              render={({ field: { onChange } }) => (
                <DdlUser
                  placeholder='Choose students'
                  className='w-full mr-4'
                  data={users}
                  hideTeachers
                  isMulti
                  onChange={(e: USER[]) => {
                    const newCodes = e.map((a) => a.codeEleve);
                    resetField("reference");
                    onChange(newCodes);
                  }}
                  disabled={loading}
                />
              )}
            />
          ) : (
            <p className='font-bold'>{`${ctxUser.codeEleve} - ${ctxUser.name}`}</p>
          )}
        </div>
        <div
          id='reference'
          className={`${["ADMIN", "SECRETARIAT"].includes(ctxUser.role) ? "border rounded-3xl" : ""} max-h-14`}
        >
          <Controller
            control={control}
            name='reference'
            render={({ field: { onChange, value } }) => (
              <input
                disabled={loading}
                className={`${INPUT_STYLE} min-h-12 disabled:bg-gray-100`}
                placeholder='Find by reference'
                type='text'
                onChange={(e) => {
                  onChange(e);
                  resetField("codes");
                }}
                value={value}
              />
            )}
          />
        </div>
        <button
          type='submit'
          disabled={loading}
          className='bg-esaBlue hover:bg-esaBlueHover text-white rounded-3xl px-4 py-2 shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed max-h-14 flex items-center justify-center'
        >
          Search
        </button>
      </div>
    </form>
  );
}

export default InvoicesFilterForm;
