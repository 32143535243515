import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { PriceAPIv2 } from "api";
import { formatPrice } from "utils/format";
import Loader from "components/common/Loader";

export interface Props {
  type: string;
  color: string;
  showTitle?: boolean;
}

const TITLE = "uppercase font-bold";
interface IPrice {
  regFees?: string;
  materials?: string;
  bookPhotocopy?: string;
  insurance?: string;
  septFees?: string;
  septFeesPartTime?: string;
  tuitionPerMonth?: string;
  tuitionPerMonthPartTime?: string;
  tuitionPerTerm?: string;
  tuitionPerTermPartTime?: string;
  tuitionPerYear?: string;
  tuitionPerYearPartTime?: string;
}

const PricesInfos = ({ type, color, showTitle = true }: Props) => {
  const [prices, setPrices] = useState<any[]>([]);
  const [tuitions, setTuitions] = useState<any>();
  const [displayPrice, setDisplayPrice] = useState<IPrice>();
  const [loading, setLoading] = useState<boolean>(false);

  const getPrices = () => {
    setLoading(true);
    PriceAPIv2.getPricesV2({
      apply_to_website: true
    })
      .then((r: any) => {
        const tmpTuitions = r.data.filter(
          (item: any) => item.apply_to_website === true
        );
        setTuitions(tmpTuitions.length > 0 ? tmpTuitions[0] : undefined);
        setPrices(r.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const priceSnack = useMemo(() => {
    const tmpSnack = prices.findIndex(
      (item: any) =>
        item.description && item.description.toLowerCase() === "snack 1 month"
    );
    return tmpSnack !== -1 ? prices[tmpSnack].amount : 0;
  }, [prices]);

  const priceLunch = useMemo(() => {
    const tmpLunch = prices.findIndex(
      (item: any) =>
        item.description && item.description.toLowerCase() === "canteen 1 month"
    );
    return tmpLunch !== -1 ? prices[tmpLunch].amount : 0;
  }, [prices]);

  useEffect(() => {
    getPrices();
  }, [type]);

  useEffect(() => {
    if (tuitions) {
      switch (type) {
        case "daycare":
          setDisplayPrice({
            regFees: formatPrice(
              String(tuitions.prices.registration_fee[type])
            ),
            materials: formatPrice(String(tuitions.prices.materials[type])),
            bookPhotocopy: formatPrice(
              String(tuitions.prices.book_photocopy[type])
            ),
            insurance: formatPrice(String(tuitions.prices.insurance[type])),
            septFees: formatPrice(
              String(tuitions.prices.september_fee[type].full_time)
            ),
            septFeesPartTime: formatPrice(
              String(tuitions.prices.september_fee[type].part_time)
            ),
            tuitionPerMonth: formatPrice(
              String(tuitions.prices.monthly[type].full_time)
            ),
            tuitionPerMonthPartTime: formatPrice(
              String(tuitions.prices.monthly[type].part_time)
            ),
            tuitionPerTerm: formatPrice(
              String(tuitions.prices.trimester[type].full_time)
            ),
            tuitionPerTermPartTime: formatPrice(
              String(tuitions.prices.trimester[type].part_time)
            ),
            tuitionPerYear: formatPrice(
              String(tuitions.prices.annual[type].full_time)
            ),
            tuitionPerYearPartTime: formatPrice(
              String(tuitions.prices.annual[type].part_time)
            )
          });
          break;
        default:
          setDisplayPrice({
            regFees: formatPrice(
              String(tuitions.prices.registration_fee[type])
            ),
            materials: formatPrice(String(tuitions.prices.materials[type])),
            bookPhotocopy: formatPrice(
              String(tuitions.prices.book_photocopy[type])
            ),
            insurance: formatPrice(String(tuitions.prices.insurance[type])),
            septFees: formatPrice(String(tuitions.prices.september_fee[type])),
            septFeesPartTime: "",
            tuitionPerMonth: formatPrice(String(tuitions.prices.monthly[type])),
            tuitionPerMonthPartTime: "",
            tuitionPerTerm: formatPrice(
              String(tuitions.prices.trimester[type])
            ),
            tuitionPerTermPartTime: "",
            tuitionPerYear: formatPrice(String(tuitions.prices.annual[type])),
            tuitionPerYearPartTime: ""
          });
          break;
      }
    }
  }, [type, tuitions]);

  return (
    <>
      {loading && <Loader width='w-6' height='h-6' text='text-gray-300' />}
      {displayPrice && (
        <>
          {showTitle && (
            <>
              <h2 className={`${color} text-xl font-bold text-center`}>
                Prices
              </h2>
              <LazyLoadImage
                src='/images/daycare3.png'
                width={150}
                height={60}
                className='m-auto mt-4'
              />
            </>
          )}
          <div className='mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            <div>
              <p className={`${TITLE} ${color} mb-4`}>General fees</p>
              <p className='pb-2'>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className={`${color} mr-2`}
                />
                <span>{`Registration fees : ${displayPrice.regFees}`}</span>
              </p>
              <p className='pb-2'>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className={`${color} mr-2`}
                />
                <span>{`Book / photocopy : ${displayPrice.bookPhotocopy}`}</span>
              </p>
              <p className='pb-2'>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className={`${color} mr-2`}
                />
                <span>{`Materials : ${displayPrice.materials}`}</span>
              </p>
              <p className='pb-2'>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className={`${color} mr-2`}
                />
                <span>{`Insurance : ${displayPrice.insurance}`}</span>
              </p>
              {type === "daycare" ? (
                <div>
                  <p>
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      className={`${color} mr-2`}
                    />
                    <span>September fee</span>
                  </p>
                  <p className='ml-4'>{`Full time : ${displayPrice.septFees}`}</p>
                  <p className='ml-4'>{`Part time : ${displayPrice.septFeesPartTime}`}</p>
                </div>
              ) : (
                <p>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    className={`${color} mr-2`}
                  />
                  {`September fee : ${displayPrice.septFees}`}
                </p>
              )}
            </div>
            <div className='mt-8 md:mt-0'>
              <p className={`${TITLE} ${color} mb-4`}>Tuition fees</p>
              <p className='descriptionPrice'>{`From october ${new Date().getFullYear()} you have 3 choices`}</p>

              <p className='font-bold mt-4'>Monthly payment</p>
              {type === "daycare" ? (
                <>
                  <p className='m-0'>{`Full time : ${displayPrice.tuitionPerMonth}`}</p>
                  <p className='m-0'>{`Part time : ${displayPrice.tuitionPerMonthPartTime}`}</p>
                </>
              ) : (
                <p>{displayPrice.tuitionPerMonth}</p>
              )}

              <p className='font-bold mt-4'>Trimester payment</p>
              {type === "daycare" ? (
                <>
                  <p className='m-0'>{`Full time : ${displayPrice.tuitionPerTerm}`}</p>
                  <p className='m-0'>{`Part time : ${displayPrice.tuitionPerTermPartTime}`}</p>
                </>
              ) : (
                <p>{displayPrice.tuitionPerTerm}</p>
              )}

              <p className='font-bold mt-4'>Annual payment</p>
              {type === "daycare" ? (
                <>
                  <p className='m-0'>{`Full time : ${displayPrice.tuitionPerYear}`}</p>
                  <p className='m-0'>{`Part time : ${displayPrice.tuitionPerYearPartTime}`}</p>
                </>
              ) : (
                <p>{displayPrice.tuitionPerYear}</p>
              )}
            </div>
            <div className='mt-8 md:mt-0'>
              <p className={`${TITLE} ${color} mb-4`}>Lunch</p>
              <p>{`${formatPrice(String(priceLunch))} per month`}</p>

              <p className={`${TITLE} ${color} mt-6 mb-4`}>School bus</p>
              <p>Depends on your location</p>

              <p className={`${TITLE} ${color} mt-6 mb-4`}>Snack</p>
              <p>{`${formatPrice(String(priceSnack))} per month`}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PricesInfos;
