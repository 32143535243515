import React from "react";
import { useUser } from "context/user.context";
import Login from "components/auth/Login";
import MySpace from "components/auth/MySpace";

function AuthMenu() {
  const { isAuth, ctxUser } = useUser();

  return isAuth && ctxUser ? <MySpace /> : <Login />;
}

export default AuthMenu;
