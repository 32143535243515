import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import Profile from "components/auth/Profile";
import { useUser } from "context/user.context";
import { NewsAPI } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faPenToSquare,
  faRightFromBracket,
  faUserAlt
} from "@fortawesome/free-solid-svg-icons";

const ITEM_STYLE = "px-8 py-2 w-full min-w-[max-content] hover:bg-gray-200";
const MySpace = () => {
  const { ctxUser, changeToken, changeUser, changeAuthStatus } = useUser();
  const mySpaceRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [showMySpace, setShowMySpace] = useState<boolean>(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [nbNotifs, setNbNotifs] = useState<any>({
    dashboard: 0,
    invoices: 0
  });
  const [loadingNotifs, setLoadingNotifs] = useState<boolean>(false);

  const handleClickOutsideMySpace = (e) => {
    if (e.target.id !== "btn-myspace") {
      setShowMySpace(false);
    }
  };

  useOnClickOutside(mySpaceRef, handleClickOutsideMySpace);

  const refreshNotifs = useCallback(async () => {
    if (ctxUser.codeEleve !== undefined) {
      setLoadingNotifs(true);
      const resDashboard = NewsAPI.getNotifs(ctxUser).then((r: any) => r);

      const result = await Promise.all([resDashboard]);
      setNbNotifs({
        ...nbNotifs,
        dashboard: result[0].nb_notifs,
        invoices: result[0].nb_unread_invoices
      });
      setLoadingNotifs(false);
    }
  }, [ctxUser]);

  const handleLogout = () => {
    changeToken("");
    changeUser({});
    changeAuthStatus(false);
    localStorage.removeItem("token");
    setShowMySpace(false);
    navigate("/");
  };

  useEffect(() => {
    const isAdminOrSecretariat =
      ctxUser && ["ADMIN", "SECRETARIAT"].includes(ctxUser.role);
    if (ctxUser.codeEleve && showMySpace && !isAdminOrSecretariat)
      refreshNotifs();
  }, [showMySpace, ctxUser]);

  return (
    <div id='my-space-wrapper'>
      <button
        id='btn-myspace'
        onClick={() => {
          setShowMySpace(!showMySpace);
          setShowProfile(false);
        }}
        className=' bg-indigo-600 hover:bg-indigo-300 font-semibold p-4 w-12 h-12 text-white hover:text-slate-800 rounded-full ml-4 flex items-center justify-center relative'
      >
        <FontAwesomeIcon icon={faUserAlt} />

        {["PARENT", "TUTOR"].includes(ctxUser.role) &&
          nbNotifs.dashboard > 0 && (
            <span className='rounded-lg bg-rose-500 text-white py-1 px-2 ml-2 text-sm absolute -top-2 left-6'>
              {loadingNotifs ? (
                <FontAwesomeIcon icon={faEllipsis} />
              ) : (
                nbNotifs.dashboard
              )}
            </span>
          )}
      </button>

      {showMySpace && (
        <div className='myspace-items fixed top-0 left-0 flex flex-col bg-slate-50 shadow-xl shadow-slate-400 h-full w-72 z-30'>
          <div
            ref={mySpaceRef}
            className='flex flex-col min-h-full overflow-y-auto overflow-x-hidden'
          >
            <p className='px-8 py-4 w-full text-right font-semibold bg-white'>{`Welcome ${ctxUser?.username}`}</p>
            <button
              type='button'
              className='px-8 py-3 w-full bg-white hover:bg-gray-200 flex justify-between items-center'
              onClick={() => {
                setShowProfile(!showProfile);
                setShowMySpace(false);
              }}
            >
              <span>Edit profile</span>
              <FontAwesomeIcon
                icon={faPenToSquare}
                className='text-slate-600'
              />
            </button>
            <button
              className={`${ITEM_STYLE} bg-white border-y flex justify-between items-center min-h-12`}
              onClick={() => handleLogout()}
            >
              <span>Log out</span>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className='text-slate-600'
              />
            </button>
            <div className='flex flex-col overflow-y-auto overflow-x-hidden pt-8'>
              <Link
                className={ITEM_STYLE}
                to={`/dashboard/${ctxUser.role.toLowerCase()}`}
                onClick={() => setShowMySpace(false)}
              >
                Dashboard
                {["PARENT", "TUTOR"].includes(ctxUser.role) &&
                  nbNotifs.dashboard > 0 && (
                    <span className='rounded-lg bg-rose-500 text-white py-1 px-2 ml-2 text-sm'>
                      {loadingNotifs ? (
                        <FontAwesomeIcon icon={faEllipsis} />
                      ) : (
                        nbNotifs.dashboard
                      )}
                    </span>
                  )}
              </Link>

              {["ADMIN", "TUTOR", "PARENT"].includes(ctxUser.role) && (
                <Link
                  to='/agenda'
                  onClick={() => setShowMySpace(false)}
                  className={ITEM_STYLE}
                >
                  Daily report
                </Link>
              )}
              {["ADMIN", "TUTOR", "PARENT"].includes(ctxUser.role) && (
                <Link
                  to='/grades'
                  onClick={() => setShowMySpace(false)}
                  className={ITEM_STYLE}
                >
                  Quiz and Exam
                </Link>
              )}
              {["ADMIN", "SECRETARIAT", "PARENT"].includes(ctxUser.role) && (
                <>
                  <Link
                    to='/delay-absence'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Delay And Absence
                  </Link>
                  <hr />
                  <Link
                    to='/invoices'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Invoices
                    {ctxUser.role === "PARENT" && nbNotifs.invoices > 0 && (
                      <span className='badge badge-danger badge-notif'>
                        {nbNotifs.invoices}
                      </span>
                    )}
                  </Link>
                </>
              )}
              {["ADMIN", "SECRETARIAT"].includes(ctxUser.role) && (
                <>
                  <hr />
                  <Link
                    to='/students'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Students
                  </Link>
                </>
              )}
              {["ADMIN"].includes(ctxUser.role) && (
                <>
                  <Link
                    to='/teachers'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Teachers
                  </Link>
                  <Link
                    to='/prices'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Prices
                  </Link>

                  <Link
                    to='/terms'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Terms
                  </Link>
                  <Link
                    to='/levels'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Levels
                  </Link>
                  <Link
                    to='/subjects'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Subjects
                  </Link>

                  <Link
                    to='/files'
                    onClick={() => setShowMySpace(false)}
                    className={ITEM_STYLE}
                  >
                    Files
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <Profile open={showProfile} setOpen={setShowProfile} />
    </div>
  );
};

export default MySpace;
