import React, { useCallback } from "react";
import Select from "react-select";
import { DEFAULT_INVOICE_PAYMENT_METHODS } from "utils/constants/invoice";
import { OPTIONS } from "utils/types/general";

interface Props {
  onChange: any;
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlPaymentMethod(props: Props) {
  const {
    onChange,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false
  } = props;

  const handleChange = useCallback((e: any) => {
    onChange(e);
  }, []);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={DEFAULT_INVOICE_PAYMENT_METHODS}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder='Payment method'
      value={value || null}
      className={className}
      isDisabled={disabled}
      isClearable
      isSearchable
    />
  );
}

export default DdlPaymentMethod;
