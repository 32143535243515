import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import { PriceAPIv2 } from "api";

import { OPTIONS } from "utils/types/general";
import { PRICE_DETAIL } from "utils/types/prices";
import { formatAmountOfPrices, formatDdlPrices } from "utils/format/prices";

interface Props {
  onChange: any;
  data?: PRICE_DETAIL[];
  fromPriceV1?: PRICE_DETAIL[]; // quantity and discount are not needed here for invoices
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
  placeholder?: string;
}

function DdlPrices(props: Props) {
  const {
    onChange,
    data = undefined,
    fromPriceV1 = undefined,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false,
    placeholder = "Find price item"
  } = props;
  const [prices, setPrices] = useState<PRICE_DETAIL[]>([]);

  const fetchPrices = useCallback(async () => {
    const resPriceV2 = await PriceAPIv2.getInvoicePricesV2().then(
      (r: any) => r.data
    );
    const results = await Promise.all([resPriceV2]);
    const formattedResults = formatDdlPrices(results[0]);

    if (fromPriceV1) {
      const mergedArray = [
        ...formattedResults,
        ...formatAmountOfPrices(fromPriceV1)
      ];
      const uniquesById = [
        ...new Map(mergedArray.map((item) => [item.id, item])).values()
      ];
      setPrices(
        uniquesById
          .sort((a, b) => a.description.localeCompare(b.description))
          .filter(
            (item: PRICE_DETAIL) =>
              item.amount !== 0 && !item.id.includes("2021_2022")
          )
      );
    } else {
      setPrices(
        formattedResults
          .sort((a, b) => a.description.localeCompare(b.description))
          .filter(
            (item: PRICE_DETAIL) =>
              item.amount !== 0 && !item.id.includes("2021_2022")
          )
      );
    }
  }, [fromPriceV1]);

  const options = useMemo(() => {
    return (prices || []).map((price: PRICE_DETAIL) => {
      return {
        label: price.description,
        value: price.id
      };
    });
  }, [prices]);

  const handleChange = useCallback(
    (e: any) => {
      if (isMulti) {
        const selectedItems = (e || []).map((item: any) => item.id);

        const resultingItems = prices.filter((price: PRICE_DETAIL) =>
          selectedItems.includes(price.id)
        );
        onChange(resultingItems);
      } else {
        const resultingItem = prices.find(
          (price: PRICE_DETAIL) => price.id === e.value
        );
        onChange(resultingItem);
      }
    },
    [prices]
  );

  useEffect(() => {
    if (!data) {
      fetchPrices();
    } else {
      setPrices(data);
    }
  }, [data, fromPriceV1]);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder={placeholder}
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlPrices;
