import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import NewsList from "components/news/NewsList";
import { useUser } from "context/user.context";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";

function DashboardLecturer() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();

  const includeRoles = useMemo(() => {
    if (ctxUser.role === "TUTOR") {
      return [ctxUser.role, "TUTORS", "PARENTS"];
    }
    if (ctxUser.role === "PARENT") {
      return [ctxUser.codeEleve, "PARENTS"];
    }
    if (ctxUser.role === "SECRETARIAT") {
      return ["TUTORS", "PARENTS"];
    }
    return [ctxUser.role];
  }, [ctxUser]);

  useEffect(() => {
    if (isAuth) {
      if (["PARENT", "SECRETARIAT"].includes(ctxUser.role)) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  return (
    <div id='dashboard-tutor-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <h1 className='uppercase font-bold text-center pb-6'>Dashboard</h1>

        <NewsList roles={includeRoles} />
      </section>
    </div>
  );
}

export default DashboardLecturer;
