import React, { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import DdlUser from "components/dropdowns/DdlUser";
import FileUpload from "components/common/FileUpload";
import Loader from "components/common/Loader";
import RichText from "components/common/RichText";

import { NewsAPI, UploadAPI } from "api";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { NEWS } from "utils/types/news";

interface Props {
  data?: NEWS;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function NewsForm(props: Props) {
  const { data, action, refetch, closeModal } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<NEWS>({ defaultValues: data });

  const addUpdate = useCallback(
    (dataToSend: any) => {
      if (dataToSend.id || action === "edit") {
        NewsAPI.update(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      } else {
        NewsAPI.add(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      }
    },
    [action]
  );

  const onSubmit: SubmitHandler<NEWS> = async (data) => {
    setLoading(true);
    if (data.files) {
      UploadAPI.upload("dashboard", data.files).then((r) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { files, ...rest } = data;
        addUpdate({ ...rest, files: r });
      });
    } else {
      addUpdate({ ...data, files: [] });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div id='target'>
        <p className='font-bold pb-2'>Target</p>
        <div
          className={`${FORM_GROUP_STYLE} ${
            isSubmitted || errors.target
              ? errors.target
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <Controller
            control={control}
            name='target'
            rules={{ required: "Please provide a target" }}
            render={({ field: { onChange, value } }) => (
              <DdlUser
                onChange={(e) => {
                  onChange(
                    e.map((item: any) => {
                      return { codeEleve: item.codeEleve, name: item.name };
                    })
                  );
                }}
                isMulti
                includeAll
                value={
                  value
                    ? value.map((val: any) => {
                        return { label: val.name, value: val.codeEleve };
                      })
                    : undefined
                }
              />
            )}
          />
        </div>
        {errors.target && (
          <span className={ERROR_STYLE}>{errors.target.message}</span>
        )}
      </div>

      <div id='title'>
        <p className='font-bold pb-2'>Title</p>
        <div
          className={`${FORM_GROUP_STYLE} ${
            isSubmitted || errors.title
              ? errors.title
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <input
            disabled={isSubmitting}
            className={INPUT_STYLE}
            placeholder='News title'
            type='text'
            {...register("title", { required: "Title is required" })}
          />
          {(isSubmitted || errors.title) && (
            <div
              className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                errors.title ? "bg-rose-600" : "bg-lime-500"
              }`}
            ></div>
          )}
        </div>
        {errors.title && (
          <span className={ERROR_STYLE}>{errors.title.message}</span>
        )}
      </div>

      <div id='description'>
        <p className='font-bold pb-2'>Description</p>
        <div
          className={`${FORM_GROUP_STYLE} rounded-none ${
            isSubmitted || errors.description
              ? errors.description
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <Controller
            control={control}
            name='description'
            rules={{ required: "Please provide a description" }}
            render={({ field: { onChange } }) => (
              <RichText
                onChange={onChange}
                currentHtmlValue={data ? data.description : null}
              />
            )}
          />
        </div>
        {errors.description && (
          <span className={ERROR_STYLE}>{errors.description.message}</span>
        )}
      </div>

      <div id='files'>
        <p className='font-bold pb-2'>Files</p>
        <div
          className={`${FORM_GROUP_STYLE} rounded-none ${
            isSubmitted || errors.files
              ? errors.files
                ? "border-rose-600"
                : "border-lime-500"
              : ""
          }`}
        >
          <Controller
            control={control}
            name='files'
            rules={{ required: false }}
            render={({ field: { onChange } }) => (
              <FileUpload
                data={data ? data.files : []}
                onSubmit={onChange}
                autoSubmit={true}
              />
            )}
          />
        </div>
        {errors.files && (
          <span className={ERROR_STYLE}>{errors.files.message}</span>
        )}
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create news" : "Update news"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default NewsForm;
