import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

interface Props {
  onChange: any;
  currentHtmlValue: string;
}

function RichText(props: Props) {
  const { onChange, currentHtmlValue } = props;

  const [editorState, setEditorState] = useState(currentHtmlValue);

  const handleChange = (content) => {
    setEditorState(content);
    onChange(content);
  };

  return (
    <SunEditor
      setContents={editorState}
      onChange={handleChange}
      lang='en'
      setOptions={{
        buttonList: [
          ["bold", "underline", "italic", "strike"],
          ["fontColor"],
          ["link"]
        ],
        height: "250px"
      }}
      setDefaultStyle='font-size: 1rem;'
    />
  );
}

export default RichText;
