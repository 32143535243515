import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface Props extends React.PropsWithChildren {
  isOpen: boolean;
  onClose: any;
  title: string;
  className?: string;
  withHeader?: boolean;
  hideClose?: boolean;
}

const Modal = (props: Props) => {
  const {
    isOpen,
    onClose,
    title,
    className,
    withHeader = true,
    hideClose = false,
    children
  } = props;
  if (!isOpen) return null;

  return (
    <div
      className='modal-bg fixed top-0 left-0 z-50 w-screen h-screen flex flex-col justify-center items-center'
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className={`modal bg-white w-[90%] rounded-xl ${className}`}>
        {withHeader && (
          <div className='modal-header flex flex-row items-start justify-between px-6 py-4 border rounded-xl rounded-b-none border-t-none relative z-0'>
            <p className='text-lg font-bold'>{title || ""}</p>
            {!hideClose && (
              <button type='button' onClick={onClose}>
                <FontAwesomeIcon icon={faClose} />
              </button>
            )}
          </div>
        )}
        <div className='p-6 max-h-[80dvh] overflow-y-auto relative z-10'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
