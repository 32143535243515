import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import Modal from "components/common/Modal";
import NewsForm from "components/news/NewsForm";
import Popup from "components/common/Popup";

import { NewsAPI } from "api";

import { ACTIONS } from "utils/types/general";
import { NEWS } from "utils/types/news";

interface Props {
  selectedItem: NEWS;
  setSelectedItem: Dispatch<SetStateAction<NEWS>>;
  action: ACTIONS;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
  refetch: any;
}

function NewsModal(props: Props) {
  const {
    loading,
    setLoading,
    selectedItem,
    setSelectedItem,
    action,
    setAction,
    refetch
  } = props;

  const title = useMemo(() => {
    switch (action) {
      case "add":
        return "Create news";
      case "edit":
        return "Update news";
      case "delete":
        return "Delete news";
      default:
        return "";
    }
  }, [action]);

  const closeModal = useCallback(() => {
    setSelectedItem(undefined);
    setAction(undefined);
  }, []);

  return (
    <Modal
      isOpen={selectedItem !== undefined || action === "add"}
      onClose={closeModal}
      title={title}
      className={`md:max-w-[700px] ${action === "delete" ? "lg:max-w-[700px]" : "lg:max-w-[90%]"}`}
    >
      {["add", "edit"].includes(action) && (
        <NewsForm
          data={selectedItem}
          action={action}
          refetch={refetch}
          closeModal={closeModal}
        />
      )}
      {action === "delete" && (
        <Popup
          onConfirm={() => {
            setLoading("deleting");
            NewsAPI.deletebyId(selectedItem.id).then(() => {
              toast.success("News deleted successfully", {
                position: "bottom-right"
              });
              refetch(1);
              closeModal();
            });
          }}
          onClose={() => closeModal()}
          loading={["fetch", "deleting"].includes(loading)}
        />
      )}
    </Modal>
  );
}

export default NewsModal;
