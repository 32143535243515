import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { InputSwitch } from "primereact/inputswitch";
import Loader from "components/common/Loader";
import StudentsTable from "components/students/StudentTable";
import DdlUser from "components/dropdowns/DdlUser";
import StudentModal from "components/students/StudentModal";

import { StudentsAPI } from "api";
import { useUser } from "context/user.context";

import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import { ACTIONS, PAGINATION } from "utils/types/general";
import { USER } from "utils/types/user";

const ELEMENTS_PER_PAGE = 10;

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<USER[]>([]);
  const [selectedItem, setSelectedItem] = useState<USER>();
  const [pagination, setPagination] = useState<PAGINATION>();
  const [action, setAction] = useState<ACTIONS | undefined>();

  const [filterCodes, setFilterCodes] = useState([]);
  const [isActive, setIsActive] = useState<boolean>(true);

  const getStudents = (page: number, active: boolean, codes?: string[]) => {
    setLoading(true);
    StudentsAPI.get(
      page,
      ELEMENTS_PER_PAGE,
      null,
      active,
      codes ? codes : filterCodes
    )
      .then((r: any) => {
        const { data, ...rest } = r;
        setStudents(data);
        setPagination(rest);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isAuth) {
      if (ctxUser && !["ADMIN", "SECRETARIAT"].includes(ctxUser.role)) {
        navigate(`/dashboard/${ctxUser.role === "TUTOR" ? "tutor" : "parent"}`);
      } else {
        getStudents(1, true);
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className='flex items-center justify-between flex-wrap pb-6'>
          <h1 className='w-full md:w-[25%] uppercase font-bold text-center md:text-left pb-3'>
            Manage students
          </h1>
          <div className='w-full md:w-[75%] flex flex-row flex-wrap gap-3 justify-start md:justify-end items-center'>
            <button
              className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-lg px-4 py-2 shadow-md'
              onClick={() => {
                setAction("add");
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className='ml-2 font-semibold'>Register a new student</span>
            </button>
            <div className='flex flex-row items-center'>
              <InputSwitch
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.value);
                  getStudents(1, e.value);
                }}
              />
              <p className='ml-2'>
                {isActive ? "Active" : "Inactive"} students
              </p>
            </div>
          </div>
        </div>

        <div className='w-full flex flex-nowrap my-4 items-center'>
          {!loading && (
            <DdlUser
              placeholder='Find student'
              className='w-full mr-4'
              hideTeachers
              onChange={(e) => {
                const newFilterCodes = e.map((a) => a.codeEleve);
                setFilterCodes(newFilterCodes);
              }}
              isMulti
              includeAll
              value={undefined}
            />
          )}
          <button
            className='bg-esaBlue hover:bg-esaBlueHover text-white rounded-lg px-4 py-2 shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed max-h-12'
            onClick={() => {
              getStudents(1, isActive);
            }}
          >
            Find
          </button>
        </div>

        <div className='rounded-lg shadow-lg bg-white py-4 px-2'>
          {loading ? (
            <Loader />
          ) : (
            <StudentsTable
              students={students}
              setStudents={setStudents}
              setSelectedItem={setSelectedItem}
              setAction={setAction}
              pagination={pagination}
              getData={getStudents}
            />
          )}
        </div>
      </section>

      <StudentModal
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        action={action}
        setAction={setAction}
        refetch={getStudents}
      />
    </div>
  );
}

export default Page;
