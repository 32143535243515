import React, { Fragment, useEffect, useState } from "react";

import { useUser } from "context/user.context";
import { useNavigate } from "react-router-dom";
import { TERM } from "utils/types/term";
import { getTerms } from "utils/api_calls/term";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import Loader from "components/common/Loader";
import moment from "moment";
import FileDropZone from "components/common/FileDropZone";
import { TermAPI, UploadAPI } from "api";
import { toast } from "react-toastify";

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<TERM[]>([]);
  const [schoolYear, setSchoolYear] = useState<string>("");

  const [toggleTerms, setToggleTerms] = useState<any>({
    1: false,
    2: false,
    3: false
  });

  const fetchData = async (schoolYearParam: string) => {
    if (!schoolYearParam) return;
    setLoading(true);
    const trs = await getTerms(schoolYearParam);
    setTerms(trs.length === 0 ? [] : trs);
    setSchoolYear(schoolYearParam);
    setLoading(false);
  };

  useEffect(() => {
    if (isAuth) {
      if (ctxUser && !["ADMIN", "SECRETARIAT"].includes(ctxUser.role)) {
        switch (ctxUser.role) {
          case "PARENT":
            navigate("/");
            navigate("/dashboard/parent");
            break;
          case "TUTOR":
            navigate("/dashboard/tutor");
            break;
          default:
            navigate("/");
            break;
        }
      } else {
        fetchData(undefined);
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  const handleChangeFiles = (
    sYear: string,
    term: number,
    week: number,
    files: any
  ) => {
    setLoading(true);
    const res = UploadAPI.upload("terms", files, sYear);
    res.then(async (r: any) => {
      const newData = [...terms];

      newData[term].weeks[week].files = r;
      // if (process === 'add') {
      //   const resp = TermAPI.add(schoolYear, terms);
      //   resp.then(() => { setLoading(false); });
      // }
      await TermAPI.update(schoolYear, terms).then(() => {
        toast.success("Term updated successfully", {
          position: "bottom-right"
        });
        setLoading(false);
      });
    });
    setLoading(false);
  };

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className='flex items-center justify-between flex-wrap pb-6'>
          <h1 className='text-center uppercase my-4'>
            <span>{`Manage files for terms:`}</span>
            {schoolYear ? (
              <span className='font-bold ml-2'>{schoolYear}</span>
            ) : (
              <span className='font-bold ml-2'>No school year chosen</span>
            )}
          </h1>
          <DdlSchoolYear
            onChange={(e) => {
              fetchData(e.value);
            }}
            value={
              schoolYear ? { label: schoolYear, value: schoolYear } : undefined
            }
            className='w-full md:w-[25%] border rounded-3xl my-4'
          />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div>
            {terms.length > 0 &&
              terms.map((term: TERM, iTerm: number) => (
                <Fragment key={`term-${iTerm}-wrapper`}>
                  <button
                    className='w-full bg-esaBlue text-white rounded-lg my-4 text-left px-4 py-2'
                    onClick={() =>
                      setToggleTerms({
                        ...toggleTerms,
                        [term.termNb]: !toggleTerms[term.termNb]
                      })
                    }
                  >{`Term ${term.termNb} from ${moment(term.start).format("DD MMM YYYY")} to ${moment(term.end).format("DD MMM YYYY")}`}</button>
                  {toggleTerms[term.termNb] && (
                    <div className='bg-white p-4'>
                      {term.weeks.length > 0 &&
                        term.weeks.map((b: any, iWeek: number) => (
                          <div
                            key={`term-${term.termNb}-week${iWeek}`}
                            className='border p-4 pt-6 pb-4 m-4 mb-10 rounded-lg bg-slate-100'
                          >
                            <p className='week-title'>{`Week ${b.weekNb} from ${moment(b.start).format("DD MMM YYYY")} to ${moment(b.end).format("DD MMM YYYY")}`}</p>
                            <FileDropZone
                              data={b.files}
                              onSubmit={(value: any) =>
                                handleChangeFiles(
                                  schoolYear,
                                  iTerm,
                                  iWeek,
                                  value
                                )
                              }
                              autoSubmit={false}
                            />
                          </div>
                        ))}
                    </div>
                  )}
                </Fragment>
              ))}

            {(terms.length === 0 || schoolYear === "") && (
              <p className='text-center text-orange-400'>{`The term dates for school year ${schoolYear} are not specified yet. Go to the Manage terms page to fill the dates.`}</p>
            )}
          </div>
        )}
      </section>
    </div>
  );
}

export default Page;
