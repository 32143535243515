import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { toast } from "react-toastify";

import Modal from "components/common/Modal";
import AgendaForm from "components/agenda/AgendaForm";
import Popup from "components/common/Popup";

import { TimetableAPI } from "api";

import { useUser } from "context/user.context";

import { ACTIONS } from "utils/types/general";
import { TERM } from "utils/types/term";
import { LEVEL } from "utils/types/level";

interface Props {
  selectedItem: any;
  setSelectedItem: Dispatch<SetStateAction<any>>;
  action: ACTIONS;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
  refetch: any;
  terms: TERM[];
  levels: LEVEL[];
}

function AgendaModal(props: Props) {
  const {
    selectedItem,
    setSelectedItem,
    action,
    setAction,
    loading,
    setLoading,
    refetch,
    terms,
    levels
  } = props;
  const { ctxUser } = useUser();

  const title = useMemo(() => {
    switch (action) {
      case "add":
        return "Create event";
      case "edit":
        return "Update event";
      case "delete":
        return "Delete event";
      default:
        return "";
    }
  }, [action]);

  const closeModal = useCallback(() => {
    setSelectedItem(undefined);
    setAction(undefined);
  }, []);

  return (
    <Modal
      isOpen={selectedItem !== undefined || action === "add"}
      onClose={closeModal}
      title={
        ["ADMIN", "TUTOR"].includes(ctxUser.role) ? title : "Event details"
      }
      className={`md:max-w-[700px] ${action === "delete" ? "lg:max-w-[700px]" : `lg:max-w-[${["ADMIN", "TUTOR"].includes(ctxUser.role) ? "90%" : "700px"}]`}`}
    >
      {["add", "edit"].includes(action) && (
        <AgendaForm
          data={selectedItem}
          action={action}
          closeModal={closeModal}
          refetch={refetch}
          terms={terms}
          levels={levels}
        />
      )}
      {action === "delete" && (
        <Popup
          onConfirm={() => {
            setLoading("deleting");
            TimetableAPI.deletebyId(selectedItem.id).then(() => {
              toast.success("Event deleted successfully", {
                position: "bottom-right"
              });
              refetch(1);
              closeModal();
            });
          }}
          onClose={() => closeModal()}
          loading={["fetch", "deleting"].includes(loading)}
        />
      )}
    </Modal>
  );
}

export default AgendaModal;
