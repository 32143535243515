import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { faPen, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResponsivePagination from "react-responsive-pagination";

import DataTable from "components/common/DataTable";
import OtherPricesModal from "components/prices/OtherPricesModal";

import {
  EMPTY_OTHER_PRICE,
  OTHER_PRICES_COLUMNS,
  PRICE_CATEGORIES_FILTERS
} from "utils/constants/prices";
import { ACTION_BTNS, INPUT_STYLE } from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { PRICE_DETAIL, PRICE_CATEGORY } from "utils/types/prices";
import Loader from "components/common/Loader";

interface Props {
  data: PRICE_DETAIL[];
  getData: any;
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
}

const NB_ELEMENTS = 10;
const GRID_ITEM_TEMPLATE = "grid grid-cols-1 md:grid-cols-4";
const CELL = "p-4";
function OtherPrices(props: Props) {
  const { data, getData, loading, setLoading } = props;
  const [filteredData, setFilteredData] = useState<PRICE_DETAIL[]>([]);
  const [category, setCategory] = useState<string>();
  const [action, setAction] = useState<ACTIONS>();
  const [selectedItem, setSelectedItem] = useState<PRICE_DETAIL>();
  const [page, setPage] = useState<number>(1);

  const actualData = useMemo(() => {
    return filteredData.length > 0 ? filteredData : data;
  }, [filteredData, data]);

  const chunkedData = useMemo(() => {
    return actualData.length > 0
      ? actualData.reduce((acc, _, i) => {
          if (i % NB_ELEMENTS === 0)
            acc.push(actualData.slice(i, i + NB_ELEMENTS));
          return acc;
        }, [])
      : [];
  }, [actualData]);

  return (
    <>
      {loading === "fetch" ? (
        <Loader />
      ) : (
        <>
          <section id='other-prices-header'>
            <div id='actions' className='flex flex-wrap gap-4 justify-end'>
              <button
                className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-3xl px-4 py-2 shadow-md min-h-12'
                onClick={() => {
                  setSelectedItem(EMPTY_OTHER_PRICE);
                  setAction("add");
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className='ml-2 font-semibold'>Add a new price</span>
              </button>

              <div className='border rounded-3xl shadow-md min-h-12'>
                <input
                  className={INPUT_STYLE}
                  placeholder='Search in other prices'
                  type='text'
                  onChange={(e) => {
                    setPage(1);
                    setCategory(undefined);
                    setFilteredData(
                      data.filter((op: PRICE_DETAIL) =>
                        op.description
                          .toLocaleLowerCase()
                          .includes(e.target.value.toLocaleLowerCase())
                      )
                    );
                  }}
                />
              </div>
            </div>

            <div id='filters' className='pt-4 flex flex-col items-end'>
              <p className='font-bold pb-2'>Filter by category</p>
              <div className='flex flex-wrap gap-4'>
                {PRICE_CATEGORIES_FILTERS.map((item: PRICE_CATEGORY) => {
                  const categoryFilter =
                    item.name === "all" ? undefined : item.name;

                  return (
                    <button
                      key={item.name}
                      className={`${
                        category !== categoryFilter
                          ? "bg-white text-esaMinty"
                          : "bg-esaMinty text-white"
                      } 
                      hover:bg-esaMintyDark hover:text-white rounded-3xl px-4 py-2 shadow-md min-h-12 border-esaMinty`}
                      onClick={() => {
                        setPage(1);
                        setCategory(categoryFilter);
                        setFilteredData(
                          data.filter(
                            (op: PRICE_DETAIL) => op.category === categoryFilter
                          )
                        );
                      }}
                    >
                      <span className='font-semibold capitalize'>
                        {item.name === "" ? "No category" : item.name}
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
          </section>
          <section
            id='other-prices-table'
            className='rounded-lg md:shadow-lg md:bg-white py-4 px-2 mt-8'
          >
            <DataTable
              data={data}
              setData={(value: any) => {
                setPage(1);
                if (category !== undefined) {
                  setFilteredData(
                    value.filter((item: any) => item.category === category)
                  );
                } else {
                  setFilteredData([...value]);
                }
              }}
              headers={OTHER_PRICES_COLUMNS}
              gridStyle={`${GRID_ITEM_TEMPLATE} hidden md:grid`}
              cellStyle={CELL}
              getData={null}
            >
              {(chunkedData.length > 0 ? chunkedData[page - 1] : []).map(
                (item: PRICE_DETAIL) => (
                  <div
                    key={item.id}
                    className={`${GRID_ITEM_TEMPLATE} py-2 shadow-lg bg-white md:shadow-none rounded-lg mb-6 md:mb-0`}
                  >
                    <div className={`${CELL}`}>
                      <p className='block md:hidden font-bold pb-4'>
                        Description :
                      </p>
                      <p>{item.description}</p>
                    </div>
                    <div className={`${CELL}`}>
                      <p className='block md:hidden font-bold pb-4'>Amount :</p>
                      <p>{`${Number(item.amount).toLocaleString().toString().replace(/,/g, " ")} Ar`}</p>
                    </div>
                    <div className={`${CELL}`}>
                      <p className='block md:hidden font-bold pb-4'>
                        Category :
                      </p>
                      <p>{item.category}</p>
                    </div>

                    <div
                      className={`${CELL} gap-2 flex items-center justify-end order-first md:order-last`}
                    >
                      <button
                        className={`${ACTION_BTNS} bg-amber-400 hover:bg-amber-200`}
                        onClick={() => {
                          setSelectedItem(item);
                          setAction("edit");
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                      <button
                        className={`${ACTION_BTNS} bg-rose-500 hover:bg-rose-200 text-white`}
                        onClick={() => {
                          setSelectedItem(item);
                          setAction("delete");
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                )
              )}

              <div>
                <div className='h-10' />
                <ResponsivePagination
                  current={page}
                  total={chunkedData.length}
                  onPageChange={(e) => {
                    setPage(e);
                  }}
                />
                <div className='h-10' />
              </div>
            </DataTable>
          </section>
        </>
      )}
      <OtherPricesModal
        loading={loading}
        setLoading={setLoading}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        action={action}
        setAction={setAction}
        refetch={() => {
          setPage(1);
          setCategory(undefined);
          getData();
        }}
      />
    </>
  );
}

export default OtherPrices;
