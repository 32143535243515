import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight, faPlay } from "@fortawesome/free-solid-svg-icons";

import ExtraCurricular from "components/otherServices/ExtraCurricular";
import Lunch from "components/otherServices/Lunch";
import SchoolBus from "components/otherServices/SchoolBus";
import SchoolYearSchedule from "components/otherServices/SchoolYearSchedule";

import { HOME_CURSUS, HOME_FAMILY_PICTURES } from "utils/constants/content";
import { CURSUS, Image } from "utils/types/general";
import {
  BG_COLORED,
  CAROUSEL_RESPONSIVE,
  CONTAINER,
  CONTAINER_PADDING,
  LG_CENTERED,
  TITLE
} from "utils/styles";

function Page() {
  const [showVideo, setShowVideo] = useState<boolean>(false);

  return (
    <>
      <section id='main-banner' className={BG_COLORED}>
        <div
          className={`${CONTAINER} flex flex-col lg:flex-row items-center justify-between ${LG_CENTERED}`}
        >
          <div className='logo-wrapper w-full flex items-center justify-center'>
            <LazyLoadImage src='/images/logo-big.png' width={150} height={93} />
          </div>
          <div className='section-right w-full lg:min-w-1/2'>
            <p
              className={`text-center pb-4  pt-4 lg:pt-0 ${TITLE} text-indigo-700`}
            >
              Welcome to our family
            </p>
            <div className='video-wrapper rounded-2xl overflow-hidden relative shadow-lg shadow-slate-400'>
              {showVideo ? (
                <iframe
                  width='100%'
                  height='350'
                  src='https://www.youtube.com/embed/ibB4sfwCszA'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  style={{ border: 0, width: "100%" }}
                ></iframe>
              ) : (
                <>
                  <div className='overlay bg-slate-700 opacity-60 absolute w-full h-full flex items-center justify-center'>
                    <button onClick={() => setShowVideo(!showVideo)}>
                      <FontAwesomeIcon
                        icon={faPlay}
                        color='white'
                        className='text-3xl'
                      />
                    </button>
                  </div>
                  <LazyLoadImage
                    src='https://img.youtube.com/vi/ibB4sfwCszA/mqdefault.jpg'
                    width='100%'
                    height='100%'
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <section
        id='presentation'
        className={`${CONTAINER} text-center ${LG_CENTERED}`}
      >
        <h1 className={`pb-4 ${TITLE} text-indigo-700`}>
          Elementary School of Antananarivo (ESA)
        </h1>
        <p className='text-lg'>
          is a private educational school located in Ambohitrarahaba,
          Antananarivo, Madagascar. In a secure and adapted environment,
          children are immersed in a{" "}
          <span className='font-semibold'>daily English environment</span>,
          supervised by experienced teachers.
        </p>
      </section>

      <section id='cursus-section' className={BG_COLORED}>
        <div className={`${CONTAINER} ${CONTAINER_PADDING}`}>
          <h1 className={`${TITLE} text-center pb-4`}>CURSUS AT ESA</h1>
          <div className='arrow-container flex flex-nowrap items-center max-w-[700px] m-auto'>
            <div
              className='bg-slate-600 h-[4px]'
              style={{ width: "calc(100% - 30px)" }}
            />
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </div>

          <div className='cursus-wrapper flex flex-wrap mt-8 rounded-lg overflow-hidden'>
            {HOME_CURSUS.map((cursus: CURSUS) => (
              <Link
                key={cursus.name}
                to={cursus.path}
                className={`${cursus.color} text-white w-full sm:w-1/2 md:w-1/4 py-8 px-8 text-center`}
              >
                <p className='font-bold uppercase text-lg'>{cursus.name}</p>
                <p>{cursus.interval}</p>
              </Link>
            ))}
          </div>

          <div className='bg-esaMintyDark text-white p-8 mt-8 text-center'>
            <p className='text-lg font-bold pb-4'>
              TOWARDS A BRIGHTER FUTURE FOR ALL CHILDREN
            </p>
            <p className='pb-4'>
              We have the responsibility to follow-up the students one by one.
              That"s why, in a classroom, they are only 12 students maximum.
            </p>
            <p>
              The school culture encourages “diversity in the classroom” teaches
              ESA students more important things: how to live and work in a
              society where everyone is unique. This is in order to have a
              better development.
            </p>
          </div>
        </div>
      </section>

      <section id='other-services' className={BG_COLORED}>
        <div className={`${CONTAINER} ${CONTAINER_PADDING}`}>
          <h2 className={`${TITLE} text-center pb-8 uppercase`}>
            Other Services
          </h2>
          <div className='services-wrapper grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4'>
            <ExtraCurricular />
            <Lunch />
            <SchoolBus />
            <SchoolYearSchedule />
          </div>
        </div>
      </section>

      <section id='family-pictures'>
        <div className={`${CONTAINER} ${CONTAINER_PADDING}`}>
          <h1 className={`${TITLE} text-center pb-4 uppercase`}>
            Family pictures
          </h1>
          <Carousel
            swipeable={false}
            draggable={false}
            autoPlay
            infinite
            responsive={CAROUSEL_RESPONSIVE}
            containerClass='h-full w-full z-0'
          >
            {HOME_FAMILY_PICTURES.map((image: Image) => (
              <LazyLoadImage
                key={image.src}
                src={image.src}
                alt={image.alt}
                className='w-full object-cover h-60'
              />
            ))}
          </Carousel>
        </div>
      </section>

      <section id='location' className={BG_COLORED}>
        <div className={`${CONTAINER} ${CONTAINER_PADDING}`}>
          <h1 className={`${TITLE} text-center pb-4 uppercase`}>
            Contact our team
          </h1>
          <iframe
            title='contact-map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.4416281615395!2d47.54984911443657!3d-18.867479411018135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x21f087f6bc6608a3%3A0xf607e8d9713fa925!2sElementary%20School%20of%20Antananarivo!5e0!3m2!1sen!2smg!4v1623714948915!5m2!1sen!2smg'
            width='600'
            height='450'
            style={{ border: 0, width: "100%" }}
            allowFullScreen
            loading='lazy'
          />
        </div>
      </section>
    </>
  );
}

export default Page;
