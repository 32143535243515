export const FORM_GROUP_STYLE = "border rounded-3xl mb-6 relative";
export const INPUT_STYLE =
  "focus:shadow-none focus-visible:shadow-none focus-visible:outline-none py-2 px-4 pr-10 w-full h-full rounded-3xl";
export const ERROR_STYLE = "mb-6 px-4 -mt-4 text-rose-600 text-sm flex";
export const SUBMIT_STYLE =
  "rounded-3xl w-[max-content] mx-auto py-2 px-6 min-w-36 text-center shadow-md cursor-pointer";

export const CONTAINER = "max-w-[1440px] mx-auto";
export const CARD_TITLE = "font-bold uppercase";
export const LG_CENTERED = "px-8 py-10 lg:px-36";
export const CONTAINER_PADDING = "px-8 py-10 lg:px-20";

export const TITLE = "text-2xl lg:text-3xl font-bold";
export const BG_COLORED = "bg-slate-50";

export const ACTION_BTNS =
  "min-w-9 w-9 h-9 flex items-center justify-center rounded-full";

export const CAROUSEL_CARDS = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 992 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 992, min: 768 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

export const CAROUSEL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 992 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 992, min: 768 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};
