/* eslint-disable @typescript-eslint/no-unused-vars */
import { PRICE, PRICE_DETAIL } from "utils/types/prices";
import { getAmountStr } from "./invoice";

export const formatPriceFormData = (price?: PRICE) => {
  if (!price) return;
  const { prices, id, version, category, ...rest } = price;
  const transformedData = { ...rest };
  transformedData["prices"] = {};

  [
    "insurance",
    "book_photocopy",
    "materials",
    "registration_fee",
    "re_registration",
    "total",
    "september_fee",
    "annual",
    "monthly",
    "trimester"
  ].forEach((priceKey: string) => {
    transformedData["prices"][priceKey] = {};
    const { label, comment, ...rest } = prices[priceKey];
    transformedData["prices"][priceKey] = { ...rest };
  });
  return transformedData;
};

export const formatPriceToSend = (formData: any, data: PRICE): PRICE => {
  const transformedPrices = { ...data.prices };
  [
    "insurance",
    "book_photocopy",
    "materials",
    "registration_fee",
    "re_registration",
    "total",
    "september_fee",
    "annual",
    "monthly",
    "trimester"
  ].forEach((priceKey: string) => {
    const detailedPrice = [
      "total",
      "september_fee",
      "annual",
      "trimester",
      "monthly"
    ].includes(priceKey);

    const daycareValue = detailedPrice
      ? {
          part_time: Number(formData.prices[priceKey].daycare.part_time),
          full_time: Number(formData.prices[priceKey].daycare.full_time)
        }
      : Number(formData.prices[priceKey].daycare);

    transformedPrices[priceKey] = {
      ...data.prices[priceKey],
      daycare: daycareValue,
      elementary: Number(formData.prices[priceKey].elementary),
      middle_school: Number(formData.prices[priceKey].middle_school),
      pre_school: Number(formData.prices[priceKey].pre_school)
    };
  });

  return {
    ...data,
    school_year: formData.school_year,
    application_deadline: formData.application_deadline,
    apply_to_website: formData.apply_to_website,
    prices: transformedPrices
  };
};

const generateChildPriceItem = (label: string, detailed?: boolean): any => {
  return {
    daycare: detailed ? { part_time: 0, full_time: 0 } : 0,
    pre_school: 0,
    elementary: 0,
    middle_school: 0,
    label,
    comment: ""
  };
};

export const generateEmptyPrice = (school_year: string): PRICE => {
  return {
    category: "Ecolage",
    school_year: school_year,
    apply_to_website: false,
    application_deadline: "",
    version: "v2",
    prices: {
      insurance: generateChildPriceItem("Insurance"),
      book_photocopy: generateChildPriceItem("Book / Photocopy"),
      total: generateChildPriceItem("Total", true),
      materials: generateChildPriceItem("Materials"),
      monthly: generateChildPriceItem("Monthly payment", true),
      annual: generateChildPriceItem("Annual payment", true),
      registration_fee: generateChildPriceItem("Registration Fee"),
      trimester: generateChildPriceItem("Trimester payment", true),
      september_fee: generateChildPriceItem("September fee", true),
      re_registration: generateChildPriceItem("Re-registration")
    }
  };
};

export const formatDdlPrices = (prices: PRICE_DETAIL[]): PRICE_DETAIL[] => {
  const transformedPrices = [];

  prices.forEach((item: PRICE_DETAIL) => {
    if (typeof item.amount === "number") {
      transformedPrices.push({
        ...item,
        description: `${item.description} - ${getAmountStr(item.amount)} Ar`
      });
    } else {
      if (
        item.amount.part_time !== undefined ||
        item.amount.part_time !== undefined
      ) {
        ["part_time", "full_time"].forEach((timeKey: string) => {
          transformedPrices.push({
            ...item,
            id: `${item.id}_${timeKey}`,
            description: `${item.description} ${timeKey === "part_time" ? "Part time" : "Full time"} - ${getAmountStr(item.amount[timeKey] || 0)} Ar`,
            amount: item.amount[timeKey] || 0
          });
        });
      }
    }
  });
  return transformedPrices;
};

export const formatAmountOfPrices = (prices: PRICE_DETAIL[]) => {
  return prices.map((item: PRICE_DETAIL) => {
    return {
      ...item,
      description: `${item.description} - ${getAmountStr(item.amount)} Ar`
    };
  });
};
