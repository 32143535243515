import React, { useEffect, useState } from "react";

import { useUser } from "context/user.context";
import { useNavigate } from "react-router-dom";
import { ACTIONS, PAGINATION } from "utils/types/general";
import { LevelsAPI } from "api";
import Loader from "components/common/Loader";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { LEVEL } from "utils/types/level";
import LevelsTable from "components/levels/LevelsTable";
import LevelModal from "components/levels/LevelModal";

const ELEMENTS_PER_PAGE = 10;

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [levels, setLevels] = useState<LEVEL[]>([]);
  const [selectedItem, setSelectedItem] = useState<LEVEL>();
  const [pagination, setPagination] = useState<PAGINATION>();
  const [action, setAction] = useState<ACTIONS | undefined>();

  const [filterName, setFilterName] = useState("");

  const fetchData = (page: number, name?: string) => {
    setLoading(true);
    LevelsAPI.search(page, ELEMENTS_PER_PAGE, null, name)
      .then((r: any) => {
        const { data, ...rest } = r;
        setLevels(data);
        setPagination(rest);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isAuth) {
      if (ctxUser && !["ADMIN", "SECRETARIAT"].includes(ctxUser.role)) {
        switch (ctxUser.role) {
          case "PARENT":
            navigate("/");
            navigate("/dashboard/parent");
            break;
          case "TUTOR":
            navigate("/dashboard/tutor");
            break;
          default:
            navigate("/");
            break;
        }
      } else {
        fetchData(1);
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className='flex items-center justify-between flex-wrap pb-6'>
          <h1 className='w-full md:w-[25%] uppercase font-bold text-center md:text-left pb-3'>
            Manage levels
          </h1>
          <div className='w-full md:w-[75%] flex flex-row flex-wrap gap-3 justify-start md:justify-end items-center'>
            <button
              className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-lg px-4 py-2 shadow-md'
              onClick={() => {
                setAction("add");
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className='ml-2 font-semibold'>Add a new level</span>
            </button>
            <div className='flex flex-row items-center'>
              <div
                className={`border rounded-3xl min-h-12 flex items-center bg-white px-4 m-0 mr-2`}
              >
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type='text'
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder='Search'
                  className='ml-3 focus:shadow-none focus-visible:shadow-none focus-visible:outline-none'
                />
              </div>
              <button
                className='bg-esaBlue hover:bg-esaBlueHover text-white rounded-lg px-4 py-2 shadow-md disabled:bg-gray-400 disabled:cursor-not-allowed max-h-12'
                onClick={() => {
                  fetchData(1, filterName);
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          className={`rounded-lg shadow-lg overflow-hidden bg-white py-4 px-2`}
        >
          {loading ? (
            <Loader />
          ) : (
            <LevelsTable
              levels={levels}
              setLevels={setLevels}
              setSelectedItem={setSelectedItem}
              setAction={setAction}
              pagination={pagination}
              getData={fetchData}
            />
          )}
        </div>
      </section>

      <LevelModal
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        action={action}
        setAction={setAction}
        refetch={() => fetchData(1, filterName)}
      />
    </div>
  );
}

export default Page;
