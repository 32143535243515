import axios from "axios";
import { isAuth } from "utils/context";
import { INVOICE_SEARCH_PARAMS } from "utils/types/invoice";

const search = async (
  page: number,
  elementsPerPage: number,
  options: INVOICE_SEARCH_PARAMS
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}invoices/search?page=${page}&elementsPerPage=${elementsPerPage}`;
    try {
      const response = await axios.post(url, options);
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const generateNextReference = async (reference: string) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}invoices/generateNext`;
    try {
      const response = await axios.post(url, { reference });
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (invoice) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}invoices`;
    const response = await axios.post(url, invoice);

    return response.data.data;
  }
  return true;
};

const update = async (invoice) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}invoices/${invoice.id}`;
    const response = await axios.put(url, invoice);

    return response.data.data;
  }
  return true;
};

const markAsRead = async (invoice) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}invoices/markasread`;
    const response = await axios.post(url, invoice);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}invoices/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { search, add, update, deletebyId, generateNextReference, markAsRead };
