import axios from "axios";
import { UsersAPI } from "api";

export const persistContext = async (
  changeToken: any,
  changeAuthStatus: any,
  changeUser: any
) => {
  if (isAuth()) {
    changeToken(localStorage.getItem("token"));

    const resUser = await UsersAPI.getUser();

    if (!resUser.error) {
      changeUser(resUser);
      changeAuthStatus(true);
    }
  }
};

export const isAuth = () => {
  let authenticated = false;
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "null"
  ) {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    authenticated = true;
  } else {
    authenticated = false;
  }
  return authenticated;
};
