import axios from "axios";
import moment from "moment";
import { isAuth } from "utils/context";
import { TERM } from "utils/types/term";

const get = async (schoolYear: string) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to get Terms"
  };

  const url = `${process.env.REACT_APP_API_URL}terms?schoolYear=${schoolYear}`;
  try {
    const response = await axios.get(url);
    return response.data[0];
  } catch {
    return error;
  }
};

const add = async (schoolYear, terms) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}terms/${schoolYear}`;

    const filterEmpty = terms.filter(
      (term) => term.end !== "" && term.start !== ""
    );
    const tmpTerms = filterEmpty.map((term) => {
      return {
        ...term,
        schoolYear
      };
    });

    const response = await axios.post(url, { terms: tmpTerms });

    return response.data.data;
  }
  return true;
};

const update = async (schoolYear, terms) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}terms/${schoolYear}`;

    const filterEmpty = terms.filter(
      (term) => term.end !== "" && term.start !== ""
    );
    const tmpTerms = filterEmpty.map((term) => {
      return {
        ...term,
        schoolYear
      };
    });

    const response = await axios.put(url, { terms: tmpTerms });

    return response.data.data;
  }
  return true;
};

const getCurrentTerm = (terms: TERM[], myDate: string) => {
  const currentInterval = terms.filter((interval: any) =>
    moment(myDate).isBetween(interval.start, interval.end, null, "[]")
  );
  const result =
    currentInterval.length > 0
      ? currentInterval[0]
      : terms.filter((interval: any) => interval.termNb === "1")[0];
  return result;
};

export { get, add, update, getCurrentTerm };
