import moment from "moment";
import { getCurrentSchoolYear } from "utils/dates";
import { COLUMN, OPTIONS } from "utils/types/general";
import {
  T_INVOICE_PAID_FILTER,
  INVOICE_SEARCH_PARAMS,
  INVOICE
} from "utils/types/invoice";

export const INVOICE_FILTER_PAID: T_INVOICE_PAID_FILTER[] = [
  { key: "all", text: "All", paid: "all" },
  { key: "receipt", text: "Receipts", paid: "receipt" },
  { key: "invoice", text: "Invoices", paid: "invoice" }
];

export const DEFAULT_INVOICE_PAYMENT_METHODS: OPTIONS[] = [
  { label: "Cheque", value: "cheque" },
  { label: "Bank transfer", value: "banktransfer" },
  { label: "Card", value: "card" },
  { label: "Mvola", value: "mvola" },
  { label: "Cash", value: "cash" }
];

export const DEFAULT_INVOICE_FILTERS: INVOICE_SEARCH_PARAMS = {
  codes: [],
  schoolYear: getCurrentSchoolYear(),
  paid: "invoice",
  reference: ""
};

export const INVOICES_TABLE_COLUMNS: COLUMN[] = [
  { title: "Reference", sortable: true, slug: "reference" },
  { title: "Student", sortable: false, slug: "student.name" },
  { title: "Student Code", sortable: false, slug: "student.codeEleve" },
  { title: "Viewed", sortable: false, slug: "viewed" }
];

export const DEFAULT_INVOICE: INVOICE = {
  reference: "",
  codeEleve: "",

  school_year: "",
  date_issue: `${moment().format("YYYY-MM-DD")}`,
  date_due: "",
  date_paid: "",

  amount_due: 0,
  amount_paid: 0,
  type: "invoice",
  paid: 0,
  description: "",
  penalties: [],
  payments: [],
  details: [],
  student: undefined,
  paymentMethod: undefined,
  viewed: false
};
