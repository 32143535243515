import { COLUMN } from "utils/types/general";
import { PRICE_CATEGORY, PRICE_LEVEL } from "utils/types/prices";

export const PRICES_LEVELS: PRICE_LEVEL[] = [
  { key: "daycare", title: "Daycare", description: "18-36 months" },
  {
    key: "pre_school",
    title: "Preschooler",
    description: "Preschool, Pre-K, Kindergarden"
  },
  {
    key: "elementary",
    title: "Elementary",
    description: "G1, G2, G3, G4, G5"
  },
  {
    key: "middle_school",
    title: "Middle",
    description: "G6, G7, G8"
  }
];

export const EMPTY_OTHER_PRICE: any = {
  amount: 0,
  description: "",
  category: ""
};

export const PRICE_CATEGORIES: PRICE_CATEGORY[] = [
  { name: "Activity", color: "yellow" },
  { name: "Cantine/Gouter", color: "green" },
  { name: "Inscription/Réinscription", color: "cyan" },
  { name: "School bus", color: "pink" }
];

export const PRICE_CATEGORIES_FILTERS: PRICE_CATEGORY[] = [
  { name: "all", color: "" },
  { name: "", color: "" },
  ...PRICE_CATEGORIES
];

export const OTHER_PRICES_COLUMNS: COLUMN[] = [
  { title: "Description", sortable: true, slug: "description" },
  { title: "Amount", sortable: true, slug: "amount" },
  { title: "Category", sortable: true, slug: "category" }
];
