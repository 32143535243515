import React, { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { SUBJECT } from "utils/types/subject";
import { SubjectsAPI } from "api";
import DdlUser from "components/dropdowns/DdlUser";

interface Props {
  data?: SUBJECT;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function SubjectForm(props: Props) {
  const { data, action, refetch, closeModal } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<SUBJECT>({
    mode: "onChange",
    defaultValues: {
      ...data
    }
  });

  const addUpdate = useCallback(
    (dataToSend: any) => {
      if (dataToSend.id || action === "edit") {
        const updateData = {
          ...dataToSend,
          value: dataToSend.label,
          teachers: dataToSend.teachers ? dataToSend.teachers : []
        };
        SubjectsAPI.update(updateData)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch();
            closeModal();
          });
      } else {
        const addData = {
          ...dataToSend,
          value: dataToSend.label,
          teachers: dataToSend.teachers ? dataToSend.teachers : []
        };
        SubjectsAPI.add(addData)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch();
            closeModal();
          });
      }
    },
    [action]
  );

  const onSubmit: SubmitHandler<SUBJECT> = async (data) => {
    setLoading(true);
    addUpdate({ ...data });
  };

  useEffect(() => {
    if (action === "edit") {
      trigger(); // triggers validation but does not submit the form
    }
  }, [action]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 gap-4'>
        <div id='label'>
          <p className='font-bold pb-2'>Subject</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.label
                ? errors.label
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              className={INPUT_STYLE}
              placeholder='Subject'
              type='text'
              {...register("label", {
                required: "Subject label is required"
              })}
            />
            {(isSubmitted || errors.label) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.label ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.label && (
            <span className={ERROR_STYLE}>{errors.label.message}</span>
          )}
        </div>

        <div id='teacher'>
          <p className='font-bold pb-2'>Teacher</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.teachers
                ? errors.teachers
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='teachers'
              render={({ field: { onChange, value } }) => {
                return (
                  <DdlUser
                    onChange={(e) => {
                      onChange(
                        e.map((item: any) => {
                          return {
                            codeEleve: item.codeEleve,
                            name: item.name,
                            id: item.id
                          };
                        })
                      );
                    }}
                    hideStudents
                    isMulti
                    value={
                      value
                        ? value.map((val: any) => {
                            return { label: val.name, value: val.codeEleve };
                          })
                        : undefined
                    }
                    placeholder={"Select a teacher"}
                  />
                );
              }}
            />
          </div>
          {errors.teachers && (
            <span className={ERROR_STYLE}>{errors.teachers.message}</span>
          )}
        </div>
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create subject" : "Update subject"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default SubjectForm;
