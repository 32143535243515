import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Loader from "components/common/Loader";

interface Props {
  onConfirm: CallableFunction;
  onClose: CallableFunction;
  text?: string;
  textCancel?: string;
  textConfirm?: string;
  iconColor?: string;
  confirmColor?: string;
  loading?: boolean;
}

function Popup(props: Props) {
  const {
    onConfirm,
    onClose,
    text = "Are you sure you want to delete this element ?",
    textCancel = "Cancel",
    textConfirm = "Confirm deletion",
    iconColor = "text-rose-500",
    confirmColor = "bg-rose-500",
    loading = undefined
  } = props;

  return (
    <div>
      <div className='flex flex-col  items-center mb-8'>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className={`text-5xl ${iconColor}`}
        />
        <p className='pt-2'>{text}</p>
      </div>

      <div className='flex items-center justify-end gap-3'>
        <button
          className='bg-blue-500 text-white py-2 px-4 rounded-lg min-h-12'
          onClick={() => onClose()}
        >
          {textCancel}
        </button>
        <button
          disabled={loading}
          className={`${confirmColor} text-white py-2 px-4 rounded-lg min-w-28 min-h-12`}
          onClick={() => onConfirm()}
        >
          {loading ? <Loader /> : <span>{textConfirm}</span>}
        </button>
      </div>
    </div>
  );
}

export default Popup;
