import axios from "axios";
import { isAuth } from "utils/context";
import { NEWS } from "utils/types/news";

const getNotifs = async (user: any) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to Get nb notifs"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}news/notif/${user.codeEleve}`;
    try {
      const response = await axios.post(url, { data: user });
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const getNews = async (
  page: number,
  elementsPerPage?: number,
  all?: boolean
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}news?page=${page}${
      elementsPerPage ? `&element=${elementsPerPage}` : ""
    }${all ? `&all=${String(all)}` : ""}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const search = async (options: any) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}news/search`;
    try {
      const response = await axios.post(url, options);
      return response.data.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (news) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}news`;
    const response = await axios.post(url, news);

    return response.data.data;
  }
  return true;
};

const update = async (news) => {
  if (isAuth()) {
    const dataToSend = { ...news, views: [] };
    const url = `${process.env.REACT_APP_API_URL}news/${news.id}`;
    const response = await axios.put(url, dataToSend);

    return response.data.data;
  }
  return true;
};

const markAsRead = async (news: NEWS, codeEleve) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}news/markAsRead/${news.id}`;
    const response = await axios.put(url, { data: codeEleve });

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}news/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { getNotifs, getNews, search, add, update, deletebyId, markAsRead };
