import React, { useEffect } from "react";

import { useUser } from "context/user.context";
import { useNavigate } from "react-router-dom";

import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";

import AttendanceAdmins from "components/delayAbsence/AttendanceAdmins";
import AttendanceParents from "components/delayAbsence/AttendanceParents";

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (ctxUser && isAuth) {
      if (
        ctxUser &&
        !["ADMIN", "PARENT", "SECRETARIAT"].includes(ctxUser.role)
      ) {
        navigate(`/dashboard/tutor`);
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        {["ADMIN", "SECRETARIAT"].includes(ctxUser.role) && (
          <AttendanceAdmins />
        )}
        {["PARENT"].includes(ctxUser.role) && <AttendanceParents />}
      </section>
    </div>
  );
}

export default Page;
