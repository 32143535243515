import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  faCheck,
  faClose,
  faDownload,
  faEye,
  faPen,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DataTable from "components/common/DataTable";

import { INVOICES_TABLE_COLUMNS } from "utils/constants/invoice";
import { ACTION_BTNS } from "utils/styles";
import { PAGINATION } from "utils/types/general";
import {
  INVOICE,
  INVOICE_ACTIONS,
  INVOICE_SEARCH_PARAMS
} from "utils/types/invoice";
import Loader from "components/common/Loader";
import { useUser } from "context/user.context";
import { InvoicesAPI } from "api";

interface Props {
  loading: string;
  invoices: INVOICE[];
  params: INVOICE_SEARCH_PARAMS;
  pagination: PAGINATION;
  getData: CallableFunction;
  setAction: (value: INVOICE_ACTIONS) => void;
  setSelectedItem: Dispatch<SetStateAction<INVOICE>>;
}

const GRID_TEMPLATE =
  "grid grid-cols-1 lg:grid-cols-[20%_35%_15%_10%_20%] border-b";
const CELL = "p-4";

function InvoicesTable(props: Props) {
  const {
    loading,
    invoices,
    params,
    pagination,
    getData,
    setSelectedItem,
    setAction
  } = props;
  const { ctxUser } = useUser();
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);

  const markAsRead = useCallback(
    async (data: INVOICE, codeEleve: string) => {
      if (
        data.codeEleve === codeEleve &&
        (data.viewed === undefined || data.viewed === false)
      ) {
        setLoadingInvoice(true);
        await InvoicesAPI.markAsRead(data)
          .then(() => true)
          .finally(() => setLoadingInvoice(false));
      }
    },
    [ctxUser]
  );

  return loading === "fetch" ? (
    <Loader />
  ) : (
    <DataTable
      data={invoices}
      setData={() => null}
      headers={INVOICES_TABLE_COLUMNS}
      gridStyle={`${GRID_TEMPLATE} hidden lg:grid`}
      cellStyle={CELL}
      getData={(pageNB: number) => getData(pageNB, params)}
      pagination={pagination}
    >
      {invoices.map((invoice: INVOICE) => {
        const textColor =
          invoice.type === "invoice"
            ? "lg:text-rose-500"
            : "lg:text-esaMintyDark";
        return (
          <div
            key={invoice.id}
            className={`${GRID_TEMPLATE} py-2 bg-white rounded-lg lg:rounded-none shadow-lg lg:shadow-none mb-6 lg:mb-0`}
          >
            <div className={`${CELL} ${textColor}`}>
              <p className='block lg:hidden font-bold capitalize'>{`${invoice.type} N° :`}</p>
              {invoice.reference}
            </div>
            <div className={`${CELL} ${textColor}`}>
              <p className='block lg:hidden font-bold'>Student :</p>
              {invoice.student.name}
            </div>
            <div className={`${CELL} ${textColor}`}>
              <p className='block lg:hidden font-bold'>Student code :</p>
              {invoice.student.codeEleve}
            </div>
            <div className={`${CELL} ${textColor}`}>
              <p className='block lg:hidden font-bold'>Viewed :</p>
              <FontAwesomeIcon icon={invoice.viewed ? faCheck : faClose} />
            </div>
            <div
              className={`${CELL} gap-2 flex items-center justify-end order-first lg:order-last`}
            >
              <button
                disabled={loading === "codeEleve" || loadingInvoice}
                className={`${ACTION_BTNS} bg-sky-400 hover:bg-sky-500 disabled:opacity-50 text-white`}
                onClick={() => {
                  if (ctxUser.role === "PARENT") {
                    markAsRead(invoice, ctxUser.codeEleve);
                  }
                  setSelectedItem(invoice);
                  setAction("view");
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <button
                disabled={
                  ["codeEleve", "pdf"].includes(loading) || loadingInvoice
                }
                className={`${ACTION_BTNS} bg-esaMinty hover:bg-esaMintyDark disabled:opacity-50 text-white`}
                onClick={() => {
                  if (ctxUser.role === "PARENT") {
                    markAsRead(invoice, ctxUser.codeEleve);
                  }
                  setSelectedItem(invoice);
                  setAction("download");
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              {["ADMIN", "SECRETARIAT"].includes(ctxUser.role) && (
                <>
                  <button
                    disabled={loading === "codeEleve"}
                    className={`${ACTION_BTNS} bg-amber-400 hover:bg-amber-200 disabled:opacity-50`}
                    onClick={() => {
                      setSelectedItem(invoice);
                      setAction("edit");
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button
                    disabled={loading === "codeEleve"}
                    className={`${ACTION_BTNS} bg-rose-500 hover:bg-rose-200 text-white disabled:opacity-50`}
                    onClick={() => {
                      setSelectedItem(invoice);
                      setAction("delete");
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </>
              )}
            </div>
          </div>
        );
      })}
    </DataTable>
  );
}

export default InvoicesTable;
