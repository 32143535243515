import React, { useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch
} from "react-hook-form";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { INVOICE, INVOICE_PAYMENT } from "utils/types/invoice";
import { ERROR_STYLE, INPUT_STYLE } from "utils/styles";
import { getAmountStr } from "utils/format/invoice";

interface Props {
  formKey: string;
  watch: UseFormWatch<INVOICE>;
  control: Control<INVOICE, any>;
  register: UseFormRegister<INVOICE>;
  unregister: UseFormUnregister<INVOICE>;
  setValue: UseFormSetValue<INVOICE>;
  isSubmitted: boolean;
  errors: FieldErrors<INVOICE>;
  isSubmitting: boolean;
  title: string;
  textTotal: string;
}

function InvoiceRepeater(props: Props) {
  const {
    formKey,
    watch,
    control,
    register,
    unregister,
    setValue,
    errors,
    isSubmitted,
    isSubmitting,
    title,
    textTotal
  } = props;

  const [expand, setExpand] = useState<boolean>(false);
  const data = watch(formKey as any);

  return (
    <>
      <div
        className={`bg-slate-100 px-4 py-4 mt-4 rounded-lg rounded-b-none border  ${data.length === 0 || !expand ? "border-b" : "border-b-0"}`}
      >
        <div className='flex justify-between items-center'>
          <p>
            <span className='font-bold mr-2'>{title}</span>
            <span>{`(${data.length} items)`}</span>
          </p>

          <div className='flex items-center justify-center gap-4'>
            <button
              type='button'
              className='w-max shadow-lg bg-esaMintyDark px-2 py-1 rounded-lg h-8'
              onClick={async () => {
                register(`${formKey}.${data.length}` as any);
                setValue(
                  `${formKey}` as any,
                  [
                    {
                      amount: 0,
                      date: ""
                    },
                    ...data
                  ].filter((b) => b)
                );
                setExpand(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className='text-white' />
            </button>
            {data.length > 0 && (
              <button
                type='button'
                className='w-max px-2 py-1 h-8'
                onClick={() => setExpand(!expand)}
              >
                <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} />
              </button>
            )}
          </div>
        </div>
      </div>

      <div
        id={`${formKey}-items-wrapper`}
        className={`${expand ? "h-auto" : "h-0 overflow-hidden"}`}
      >
        {(data || []).map((_: INVOICE_PAYMENT, iItem: number) => {
          return (
            <div
              key={`${formKey}${iItem}`}
              className={`border ${iItem === data.length - 1 ? "" : "border-b-0"}`}
            >
              <div className='bg-slate-50 py-2 px-4 flex justify-start'>
                <button
                  type='button'
                  className='w-max shadow-lg bg-white px-2 py-1 rounded-lg h-8 mr-3'
                  onClick={() => {
                    unregister(`${formKey}.${iItem}` as any);

                    // Reindex the remaining items
                    setValue(
                      `${formKey}` as any,
                      data.filter((_, i) => i !== iItem)
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className='text-rose-500'
                  />
                </button>
              </div>

              <div className='grid grid-cols-2 gap-4 px-4 pt-4'>
                <div>
                  <p className='font-semibold text-slate-500 pb-2 text-right'>
                    Date
                  </p>
                  <div
                    className={`border relative rounded-lg mb-4 ${
                      isSubmitted || errors[`${formKey}.${iItem}.date`]
                        ? errors[`${formKey}.${iItem}.date`]
                          ? "border-rose-600"
                          : "border-lime-500"
                        : ""
                    }`}
                  >
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} !pr-4 text-right`}
                      placeholder='Date'
                      type='date'
                      {...register(`${formKey}.${iItem}.date` as any, {
                        required: false
                      })}
                    />
                  </div>
                  {errors[`${formKey}.${iItem}.date`] && (
                    <span className={ERROR_STYLE}>
                      {errors[`${iItem}.date`].message}
                    </span>
                  )}
                </div>
                <div>
                  <p className='font-semibold text-slate-500 pb-2 text-right'>
                    Amount (Ar)
                  </p>
                  <div
                    className={`border relative rounded-lg mb-4 ${
                      isSubmitted || errors[`${formKey}.${iItem}.amount`]
                        ? errors[`${formKey}.${iItem}.amount`]
                          ? "border-rose-600"
                          : "border-lime-500"
                        : ""
                    }`}
                  >
                    <Controller
                      control={control}
                      name={`${formKey}.${iItem}.amount` as any}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please provide a valid amount"
                        }
                      }}
                      render={({ field: { onChange, value } }) => (
                        <input
                          disabled={isSubmitting}
                          className={`${INPUT_STYLE} !pr-4 text-right`}
                          placeholder='Amount'
                          type='text'
                          value={value}
                          onChange={(e) => {
                            const numericValue = Number(e.target.value);
                            if (isFinite(numericValue)) {
                              onChange(numericValue);
                            } else {
                              onChange(0);
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  {errors[`${formKey}.${iItem}.amount`] && (
                    <span className={ERROR_STYLE}>
                      {errors[`${iItem}.amount`].message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`bg-slate-100 px-4 py-4 rounded-lg rounded-t-none flex items-center justify-between flex-wrap border border-t-0 ${expand ? "" : ""}`}
      >
        <p className='font-semibold'>{textTotal}</p>
        <p className='font-semibold'>
          {`${getAmountStr(
            data.reduce((sum, item) => {
              return sum + Number(item.amount);
            }, 0)
          )} Ar`}
        </p>
      </div>
    </>
  );
}

export default InvoiceRepeater;
