import React from "react";

import Card from "components/common/Card";
import { CARD_TITLE } from "utils/styles";

const ExtraCurricular = () => {
  return (
    <Card
      images={[
        { src: "/images/activity1.PNG", alt: "extra curricular 1" },
        { src: "/images/activity2.PNG", alt: "extra curricular 2" }
      ]}
    >
      <p className={`${CARD_TITLE} pb-4`}>EXTRA-CURRICULAR ACTIVITIES</p>
      <hr />
      <p className='py-4'>
        (Every wednesday afternoon, only for preschoolers and elementary
        students)
      </p>

      <p className='pb-4'>
        Extra - Curricular activities help the students to have a truly
        well-rounded education.
      </p>
      <p>
        ESA provides activities such as Judo, Swimming, Danse, Sewing, Drama,
        Responsible citizenship some of them are free.
      </p>
    </Card>
  );
};

export default ExtraCurricular;
