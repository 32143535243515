import React from "react";

import { UserProvider } from "context/user.context";
import Routes from "Routes";

function App() {
  return (
    <UserProvider>
      <Routes />
    </UserProvider>
  );
}

export default App;
