import React, { Dispatch, SetStateAction } from "react";

import {
  faExclamationTriangle,
  faPen,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ACTIONS, PAGINATION } from "utils/types/general";
import { ACTION_BTNS } from "utils/styles";
import DataTable from "components/common/DataTable";
import { ATTENDANCE } from "utils/types/attendance";
import { ATTENDANCE_TABLE_COLUMNS } from "utils/constants/attendance";

interface Props {
  attendances: ATTENDANCE[];
  setAttendances: Dispatch<SetStateAction<ATTENDANCE[]>>;
  setSelectedItem: Dispatch<SetStateAction<ATTENDANCE>>;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  pagination?: PAGINATION;
  getData: CallableFunction;
}

const GRID_TEMPLATE =
  "grid grid-cols-1 md:grid-cols-[15%_15%_20%_10%_15%_10%_15%] border-b";
const CELL = "p-4";

function AttendanceAdminTable(props: Props) {
  const {
    attendances,
    setAttendances,
    setSelectedItem,
    setAction,
    pagination,
    getData
  } = props;
  return (
    <DataTable
      data={attendances}
      setData={setAttendances}
      headers={ATTENDANCE_TABLE_COLUMNS}
      gridStyle={`${GRID_TEMPLATE} hidden md:grid`}
      cellStyle={CELL}
      getData={getData}
      pagination={pagination}
    >
      {attendances.map((item: ATTENDANCE) => (
        <div key={item.id} className={`${GRID_TEMPLATE} py-2`}>
          <div className={`${CELL}`}>
            <p className='block md:hidden font-bold pb-4'>Date</p>
            {item.date ? (
              <p>{item.date}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div className={`${CELL}`}>
            <p className='block md:hidden font-bold pb-4'>Code élève</p>
            {item.codeEleve ? (
              <p>{item.codeEleve}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div className={`${CELL}`}>
            <p className='block md:hidden font-bold pb-4'>Reason</p>
            {item.reason ? (
              <p>{item.reason}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div className={`${CELL}`}>
            <p className='block md:hidden font-bold pb-4'>Term</p>
            {item.term ? (
              <p>{item.term}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div className={`${CELL}`}>
            <p className='block md:hidden font-bold pb-4'>School Year</p>
            {item.schoolYear ? (
              <p>{item.schoolYear}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div className={`${CELL}`}>
            <p className='block md:hidden font-bold pb-4'>Type</p>
            {item.type ? (
              <p className='capitalize'>{item.type}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div
            className={`${CELL} gap-2 flex items-center justify-end order-first md:order-last`}
          >
            <button
              className={`${ACTION_BTNS} bg-amber-400 hover:bg-amber-200`}
              onClick={() => {
                setSelectedItem(item);
                setAction("edit");
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              className={`${ACTION_BTNS} bg-rose-500 hover:bg-rose-200 text-white`}
              onClick={() => {
                setSelectedItem(item);
                setAction("delete");
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
      ))}
    </DataTable>
  );
}

export default AttendanceAdminTable;
