import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import { getTerms } from "utils/api_calls/term";
import { OPTIONS } from "utils/types/general";
import { TERM } from "utils/types/term";

interface Props {
  onChange: any;
  data?: TERM[];
  isMulti?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlTerm(props: Props) {
  const {
    onChange,
    data = undefined,
    isMulti = false,
    value = undefined,
    className = "",
    disabled = false
  } = props;
  const [terms, setTerms] = useState<TERM[]>([]);

  const fetchTerms = async () => {
    const resTerms = await getTerms();
    setTerms(resTerms);
  };

  const options = useMemo(() => {
    return (terms || []).map((term: TERM) => {
      return {
        label: `Term ${term.termNb}`,
        value: term.termNb
      };
    });
  }, [terms]);

  const handleChange = useCallback(
    (e: any) => {
      if (isMulti) {
        const selectedItems = (e || []).map((item: any) => item.value);

        const resultingItems = terms.filter((term: TERM) =>
          selectedItems.includes(term.termNb)
        );
        onChange(resultingItems);
      } else {
        const resultingItem = terms.find(
          (term: TERM) => term.termNb === e.value
        );
        onChange(resultingItem);
      }
    },
    [terms]
  );

  useEffect(() => {
    if (!data) {
      fetchTerms();
    } else {
      setTerms(data);
    }
  }, [data]);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder='Term'
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlTerm;
