import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Card from "components/common/Card";
import PricesInfos from "components/PricesInfos";
import RegistrationInfos from "components/RegistrationInfos";

import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  LG_CENTERED
} from "utils/styles";

const TITLE = "uppercase font-bold mb-4 text-esaPink pt-2";
function Page() {
  return (
    <div id='daycare-page' className={BG_COLORED}>
      <section
        id='main-banner'
        className={`${CONTAINER} flex flex-col lg:flex-row items-center justify-between ${LG_CENTERED} bg-blue-50 pb-16`}
      >
        <div className='logo-wrapper w-full flex items-center justify-center'>
          <LazyLoadImage src='/images/daycare1.png' width={230} height={93} />
        </div>
        <div className='section-right w-full lg:min-w-[70%] text-center'>
          <h1 className='text-3xl font-bold uppercase'>Day care</h1>
          <p className='font-bold text-xl pb-8'>18-36 months</p>
          <p className='text-lg font-semibold text-esaBlue'>
            ESA day care gives your child the perfect start !
          </p>
          <p className='text-lg font-semibold pb-8 text-esaBlue'>
            We help your child settle in and prepare them for preschool.
          </p>
          <p className='text-xl font-bold text-esaRed'>
            At ESA, we believe that each child deserves an enriching environment
            to learn and grow.
          </p>
        </div>
      </section>

      <section
        id='daycare-features'
        className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8 ${CONTAINER} ${CONTAINER_PADDING} pt-20`}
      >
        <Card>
          <h2 className={TITLE}>Safe welcoming day care</h2>
          <p>
            ESA provides safe welcoming day care. We have created an environment
            where children can have fun and discover their talents ! At ESA,
            children learn by playing and exploring; taking part in adventurous
            activities; trying different musical instruments.
          </p>
        </Card>

        <Card>
          <h2 className={TITLE}>Creativity and self confidence</h2>

          <p className='pb-4'>
            Children love to play and explore the world around them. Our day
            care encourages young children to make new friends and try new
            things.
          </p>

          <p>
            Fun activities stimulate creativity and self-confidence, to help
            your child make the most of growing up ! Our child day care has been
            specifically designed to give young children the tools they need to
            develop new skills and talents.
          </p>
        </Card>

        <Card>
          <h2 className={TITLE}>Prepare your child for preschool</h2>
          <p>
            ESA gives your child a head start when they start preschool. Our
            professional childcare team, prepares your child for preschool.
          </p>
        </Card>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={` rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <RegistrationInfos color='text-esaPink' />
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <PricesInfos type='daycare' color='text-esaPink' />
        </div>
      </section>
    </div>
  );
}

export default Page;
