import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Document, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import moment from "moment";

import InvoicesFilterForm from "components/invoices/InvoicesFilterForm";
import InvoicesTable from "components/invoices/InvoicesTable";
import InvoiceModal from "components/invoices/InvoiceModal";
import InvoiceHTMLTemplate from "components/templates/InvoiceHTMLTemplate";

import { InvoicesAPI, UsersAPI } from "api";
import { useUser } from "context/user.context";

import { DEFAULT_INVOICE_FILTERS } from "utils/constants/invoice";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import { PAGINATION } from "utils/types/general";
import {
  INVOICE,
  INVOICE_ACTIONS,
  INVOICE_SEARCH_PARAMS
} from "utils/types/invoice";
import { USER } from "utils/types/user";
import {
  getAmountDue,
  getAmountPaid,
  getTotalAmount
} from "utils/format/invoice";
import { getUsers } from "utils/api_calls/user";

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<INVOICE_SEARCH_PARAMS>(
    DEFAULT_INVOICE_FILTERS
  );

  const [users, setUsers] = useState<USER[]>([]);
  const [invoices, setInvoices] = useState<INVOICE[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Partial<USER>>();
  const [loading, setLoading] = useState<string>("");

  const [pagination, setPagination] = useState<PAGINATION>();

  const [action, setAction] = useState<INVOICE_ACTIONS>();
  const [selectedItem, setSelectedItem] = useState<INVOICE>();

  const fetchInvoices = useCallback(
    async (page: number, params: INVOICE_SEARCH_PARAMS) => {
      setLoading("fetch");
      const resInvoices = await InvoicesAPI.search(page, 10, params).then(
        (r: any) => r
      );

      const results = await Promise.all([resInvoices]);
      const { data, ...rest } = results[0];
      setInvoices(data);
      setPagination(rest);

      setLoading("");
    },
    []
  );

  const fetchUsers = useCallback(async () => {
    const resUsers = await getUsers(false, true, false).then((r) => r);
    const results = await Promise.all([resUsers]);
    setUsers(results[0]);
    setLoading("");
  }, []);

  const generatePDF = useCallback(
    async (invoiceItem: INVOICE, studentItem: Partial<USER>) => {
      const isPaid = invoiceItem.paid === 1;
      const paidBy = invoiceItem.paymentMethod?.label || null;
      const paidOn = invoiceItem.date_paid || null;

      const paymentLabel = `${isPaid && (paidBy || paidOn) ? "PAID" : ""} ${isPaid && paidBy ? `by ${paidBy}` : ""} ${paidOn || ""}`;

      const dateDue =
        invoiceItem.date_due && invoiceItem.date_due !== ""
          ? moment(invoiceItem.date_due).format("DD/MM/YYYY")
          : "";

      const pdfTotalAmount = getTotalAmount(invoiceItem);
      const pdfAmountDue = getAmountDue(invoiceItem);
      const pdfAmountPaid = getAmountPaid(invoiceItem);

      const doc = (
        <Document>
          <InvoiceHTMLTemplate
            data={invoiceItem}
            student={studentItem}
            paymentLabel={paymentLabel}
            dateDue={dateDue}
            total={pdfTotalAmount}
            totalWithPenalties={pdfAmountDue + pdfAmountPaid}
            amountDue={pdfAmountDue}
            amountPaid={pdfAmountPaid}
            isReceipt={pdfAmountDue === 0}
          />
        </Document>
      );

      setLoading("pdf");
      await pdf(doc)
        .toBlob()
        .then((r: Blob) => {
          if (r.size === 660) {
            generatePDF(invoiceItem, studentItem);
          } else {
            saveAs(r, `${invoiceItem.reference}`);
            setLoading("");
          }
        });
    },
    []
  );

  const loadInvoiceModal = useMemo(() => {
    if (selectedItem && action !== "download") {
      return true;
    }
    if (action === "add") {
      return true;
    }
    return false;
  }, [selectedItem, action]);

  useEffect(() => {
    if (isAuth) {
      if (
        ctxUser &&
        !["ADMIN", "SECRETARIAT", "PARENT"].includes(ctxUser.role)
      ) {
        navigate("/dashboard/tutor");
      } else {
        fetchInvoices(1, {
          ...DEFAULT_INVOICE_FILTERS,
          paid: ctxUser.role === "PARENT" ? "all" : "invoice",
          codes:
            ctxUser.role === "PARENT"
              ? [ctxUser.codeEleve]
              : DEFAULT_INVOICE_FILTERS.codes
        });
        if (ctxUser.role !== "PARENT") {
          // ie here ADMIN or SECRETARIAT
          fetchUsers();
        }
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  useEffect(() => {
    if (["download", "view"].includes(action) && selectedItem) {
      setLoading("codeEleve");
      UsersAPI.getByCodeEleve(selectedItem.codeEleve)
        .then((r) => {
          setSelectedStudent(r as any);
          if (action === "download") {
            generatePDF(selectedItem, r as any);
          }
        })
        .finally(() => setLoading(""));
    }
  }, [action, selectedItem]);

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <InvoicesFilterForm
          callback={(value: INVOICE_SEARCH_PARAMS) => {
            setSearchParams(value);
            fetchInvoices(1, {
              ...value,
              codes:
                ctxUser.role === "PARENT" ? [ctxUser.codeEleve] : value.codes
            });
          }}
          loading={["codeEleve", "fetch"].includes(loading)}
          setAction={setAction}
          users={users}
        />

        <div
          className={`rounded-none lg:rounded-lg lg:shadow-lg overflow-hidden bg-transparent lg:bg-white py-4 px-2`}
        >
          <InvoicesTable
            loading={loading}
            invoices={invoices}
            params={searchParams}
            pagination={pagination}
            getData={(pageNb: number, params: INVOICE_SEARCH_PARAMS) => {
              setSearchParams(params);
              fetchInvoices(pageNb, {
                ...params,
                codes:
                  ctxUser.role === "PARENT" ? [ctxUser.codeEleve] : params.codes
              });
            }}
            setAction={(value: INVOICE_ACTIONS) => {
              setAction(value);
            }}
            setSelectedItem={setSelectedItem}
          />

          {loadInvoiceModal && users && (
            <InvoiceModal
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              selectedStudent={selectedStudent}
              action={action}
              setAction={setAction}
              loading={loading}
              setLoading={setLoading}
              refetch={() =>
                fetchInvoices(pagination.currentPage, {
                  ...searchParams,
                  codes:
                    ctxUser.role === "PARENT"
                      ? [ctxUser.codeEleve]
                      : searchParams.codes
                })
              }
              users={users}
            />
          )}
        </div>
      </section>
    </div>
  );
}

export default Page;
