import React, { Fragment } from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";

export interface Props {
  terms: any;
  schoolYear: string;
}

const TermInfo = ({ terms, schoolYear }: Props) => {
  // Create styles
  const pdfStyles = StyleSheet.create({
    page: {
      padding: "30px"
    },
    view: {
      padding: "30px"
    },
    title: {
      fontSize: "13px",
      fontWeight: "extrabold",
      color: "#576064",
      textAlign: "center"
    },
    subtitle: {
      fontSize: "11px",
      fontWeight: "normal",
      backgroundColor: "#e8e8e8",
      padding: "7px",
      margin: "7px 0"
    },
    text: {
      fontSize: "11px",
      fontWeight: "normal",
      color: "#576064"
    }
  });

  return (
    <Page size='A4' style={pdfStyles.page}>
      <View style={pdfStyles.view}>
        <Text style={pdfStyles.title}>{schoolYear} Terms</Text>
        {terms.map((x: any) => (
          <Fragment key={`details-${x.termNb}`}>
            <Text style={pdfStyles.subtitle}>
              {x.start !== "" && x.end !== ""
                ? `Term ${x.termNb} from ${x.start} to ${x.end}`
                : ""}
            </Text>
            {x.weeks.map((y: any) => (
              <Fragment key={`details-week-${y.weekNb}`}>
                {y.start !== "" && y.end !== "" ? (
                  <Text style={pdfStyles.text}>
                    {`Week ${y.weekNb} : ${y.start} - ${y.end}`}{" "}
                  </Text>
                ) : null}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </View>
    </Page>
  );
};

export default TermInfo;
