import React, { useCallback, useMemo, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loader from "components/common/Loader";
import Modal from "components/common/Modal";
import Popup from "components/common/Popup";

import { INPUT_STYLE, SUBMIT_STYLE } from "utils/styles";
import { GRADE_PARAMS, GRADE_HEADER, QUIZZ, GRADE } from "utils/types/grade";
import { customRound, getQuizFields } from "utils/format/grade";

interface Props {
  grade: GRADE;
  data: QUIZZ[];
  formData: any;
  role: string;
  headers: GRADE_HEADER[];
  params: GRADE_PARAMS;
  callback: (value: GRADE) => void;
}

function GradeQuizz(props: Props) {
  const { grade, data, formData, role, headers, params, callback } = props;
  const quizzSectionRef = useRef<HTMLDivElement>(null);

  const [expand, setExpand] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { isSubmitting, isDirty }
  } = useForm<any>({ defaultValues: formData });

  const handleClickOutsideQuizzSection = useCallback(() => {
    if (isDirty && expand && ["ADMIN", "TUTOR"].includes(role)) {
      setShowModal(true);
    }
  }, [isDirty, showModal, expand, role]);

  useOnClickOutside(quizzSectionRef, handleClickOutsideQuizzSection);

  const onSubmit: SubmitHandler<any> = useCallback(
    async (submittedData) => {
      const gradeQuizzToSend = data.map((item: QUIZZ, iItem: number) => {
        return {
          ...submittedData[iItem],
          id: item.id
        };
      });

      const gradeExam = grade.gradeExam;

      gradeQuizzToSend.forEach((item: QUIZZ, iItem: number) => {
        // setting corresponding exam quizAverageValue
        const matchingExamSubject = grade.gradeExam[iItem];
        gradeExam[iItem] = {
          ...matchingExamSubject,
          quizAverageExam: item.quizAverage
        };
      });
      callback({
        ...grade,
        gradeQuiz: gradeQuizzToSend,
        gradeExam
      });
    },
    [data, grade]
  );

  const hasQuizzAverage = useMemo(() => {
    return Object.values(headers)
      .map((headerItem: GRADE_HEADER) => headerItem.key)
      .includes("quizAverage");
  }, [headers]);

  return (
    <>
      <div ref={quizzSectionRef} className='rounded-lg shadow-lg bg-white p-4'>
        <button
          onClick={() => {
            if (expand && isDirty) {
              setShowModal(true);
            }
            setExpand(!expand);
          }}
          className='w-full flex items-center justify-between'
        >
          <span className='font-bold text-lg text-left'>{`Quiz Term ${params.term.termNb} - ${params.student.name} - ${params.student.level} - ${params.schoolYear}`}</span>
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        <div className={`${expand ? "block" : "hidden"} pt-12 pb-4 px-0`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`grid grid-cols-1 lg:grid-cols-${headers.length}`}>
              {headers.map((header: GRADE_HEADER, iHeader: number) => (
                <div
                  key={header.key}
                  className={`hidden lg:block font-bold text-sm bg-slate-50 p-2 border border-b-0 ${iHeader < headers.length - 1 ? "border-r-0" : ""}`}
                >
                  {header.header}
                </div>
              ))}
            </div>

            {data.map((quizz: QUIZZ, iQuizz: number) => (
              <div
                key={quizz.id}
                className={`grid grid-cols-1 lg:grid-cols-${headers.length} mb-10 lg:mb-0 shadow-md lg:shadow-none`}
              >
                {headers.map((header: GRADE_HEADER, iHeader: number) => (
                  <div
                    key={`${iQuizz}${header.key}`}
                    className={`border ${iHeader < headers.length - 1 ? "lg:border-r-0" : ""} grid grid-cols-1 overflow-hidden ${iQuizz === data.length - 1 ? "border-b" : "border-b-0 "}`}
                  >
                    <p className='text-sm font-bold flex items-start p-2 lg:hidden'>
                      {header.header} :
                    </p>
                    <Controller
                      control={control}
                      name={`${iQuizz}.${header.key}`}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <textarea
                          disabled={
                            isSubmitting || !["TUTOR", "ADMIN"].includes(role)
                          }
                          className={`${INPUT_STYLE} disabled:cursor-not-allowed text-sm disabled:resize-none`}
                          placeholder={header.header}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            if (
                              hasQuizzAverage &&
                              String(header.key).includes("quiz") &&
                              header.key !== "quizAverage"
                            ) {
                              const quizFields = getQuizFields(headers);

                              const sumGradeQuizz = quizFields.reduce(
                                (acc, fieldKey) => {
                                  const currentFieldValue = watch(
                                    `${iQuizz}.${fieldKey}`
                                  );
                                  const numericValue =
                                    Number(currentFieldValue);

                                  if (isFinite(numericValue)) {
                                    return acc + numericValue;
                                  }
                                  return acc;
                                },
                                0
                              );

                              let sumNonNullGrades = quizFields.reduce(
                                (acc, fieldKey) => {
                                  const currentFieldValue = watch(
                                    `${iQuizz}.${fieldKey}`
                                  );
                                  const numericValue =
                                    Number(currentFieldValue);

                                  if (
                                    isFinite(numericValue) &&
                                    numericValue !== 0
                                  ) {
                                    return acc + 1;
                                  }
                                  return acc;
                                },
                                0
                              );
                              sumNonNullGrades =
                                sumNonNullGrades === 0 ? 1 : sumNonNullGrades;
                              const roundedValue = customRound(
                                sumGradeQuizz / sumNonNullGrades
                              );

                              setValue(
                                `${iQuizz}.quizAverage`,
                                String(roundedValue === 0 ? "" : roundedValue)
                              );
                            }
                          }}
                        ></textarea>
                      )}
                    />
                  </div>
                ))}
              </div>
            ))}
            {["TUTOR", "ADMIN"].includes(role) && (
              <div
                id='btnSubmit'
                className='w-full flex items-center justify-center pt-6'
              >
                {isSubmitting ? (
                  <div className={`${SUBMIT_STYLE} bg-gray-100`}>
                    <Loader width='w-6' height='h-6' text='text-gray-300' />
                  </div>
                ) : (
                  <input
                    type='submit'
                    disabled={isSubmitting}
                    value='Save quiz'
                    className={`${SUBMIT_STYLE} hover:bg-esaMinty bg-esaMintyDark text-white font-bold`}
                  />
                )}
              </div>
            )}
          </form>
        </div>
      </div>

      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title='Warning'
          hideClose
          className='md:max-w-[500px] lg:max-w-[500px]'
        >
          <Popup
            onConfirm={() => {
              const currentFormData = watch();
              onSubmit(currentFormData);
              setShowModal(false);
            }}
            onClose={() => {
              reset();
              setShowModal(false);
            }}
            text='Do you want to save changes to Quiz ?'
            textConfirm='Save quiz'
            textCancel='Discard changes'
            iconColor='text-amber-500'
            confirmColor='hover:bg-esaMinty bg-esaMintyDark'
          />
        </Modal>
      )}
    </>
  );
}

export default GradeQuizz;
