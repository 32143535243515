import { getCurrentSchoolYear } from "utils/dates";

export const DEFAULT_EVENT = {
  id: "",
  level: "Level",
  date: "",
  timeStart: "",
  timeEnd: "",
  weekNumber: "",
  termNumber: "",
  subjectName: "",
  recurrent: 0,
  files: null,
  cahierDeTexte: {
    description: "",
    broadcastLink: ""
  },
  school_year: getCurrentSchoolYear()
};
