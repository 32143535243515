import React, { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";

import { AttendanceAPI } from "api";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { capitalizeFirstLetter } from "utils/format";

import { ATTENDANCE } from "utils/types/attendance";
import DdlUser from "components/dropdowns/DdlUser";
import DdlAttendanceType from "components/dropdowns/DdlAttendanceType";
import DdlTerm from "components/dropdowns/DdlTerm";
import { TERM } from "utils/types/term";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import { getSchoolYearForDate, whichTerm } from "utils/dates";
import { getTerms } from "utils/api_calls/term";

interface Props {
  data?: ATTENDANCE;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function AttendanceForm(props: Props) {
  const { data, action, refetch, closeModal } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    trigger,
    setValue,
    resetField,
    clearErrors,
    setError,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<ATTENDANCE>({
    mode: "onChange",
    defaultValues: {
      ...data
    }
  });

  const addUpdate = useCallback(
    (dataToSend: any) => {
      if (dataToSend.id || action === "edit") {
        AttendanceAPI.update(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      } else {
        AttendanceAPI.add(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      }
    },
    [action]
  );

  const onSubmit: SubmitHandler<ATTENDANCE> = async (data) => {
    setLoading(true);
    addUpdate({ ...data });
  };

  useEffect(() => {
    if (action === "edit") {
      trigger(); // triggers validation but does not submit the form
    }
  }, [action]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 gap-4'>
        <div>
          <div id='date'>
            <p className='font-bold pb-2'>Date</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.date
                  ? errors.date
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting}
                className={INPUT_STYLE}
                placeholder='Date of birth'
                type='date'
                {...register("date", {
                  required: "Please provide a date"
                })}
                onChange={async (e) => {
                  const thisDateSchoolYear = getSchoolYearForDate(
                    e.target.value
                  );
                  const terms = await getTerms(thisDateSchoolYear);
                  const matchingTerm = whichTerm(e.target.value, terms);

                  if (matchingTerm) {
                    setValue("term", matchingTerm.termNb);
                    setValue("schoolYear", matchingTerm.schoolYear);
                    clearErrors("term");
                    clearErrors("schoolYear");
                  } else {
                    resetField("term");
                    resetField("schoolYear");
                    setError("term", {
                      message: "This date does not belong to any term"
                    });
                    setError("schoolYear", {
                      message: "This date does not belong to any school year"
                    });
                  }
                }}
              />
              {(isSubmitted || errors.date) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.date ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.date && (
              <span className={ERROR_STYLE}>{errors.date.message}</span>
            )}
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4 p-4 bg-slate-100 rounded-xl'>
          <p className='col-span-2 text-center'>
            The Term and school year will be chosen automatically depending on
            the date you choose above
          </p>
          <div id='term'>
            <p className='font-bold pb-2'>Term</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.term
                  ? errors.term
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='term'
                rules={{ required: "Please provide a term" }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DdlTerm
                      disabled
                      className='rounded-3xl shadow-md'
                      onChange={(e: TERM) => onChange(e.termNb)}
                      value={
                        value
                          ? {
                              label: `Term ${value}`,
                              value: value
                            }
                          : undefined
                      }
                    />
                  );
                }}
              />
            </div>
            {errors.term && (
              <span className={ERROR_STYLE}>{errors.term.message}</span>
            )}
          </div>
          <div id='schoolYear'>
            <p className='font-bold pb-2'>School Year</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.schoolYear
                  ? errors.schoolYear
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='schoolYear'
                rules={{ required: "Please provide a school year" }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DdlSchoolYear
                      disabled
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      value={{ label: value, value: value }}
                    />
                  );
                }}
              />
            </div>
            {errors.schoolYear && (
              <span className={ERROR_STYLE}>{errors.schoolYear.message}</span>
            )}
          </div>
        </div>
        <div>
          <div id='codeEleve'>
            <p className='font-bold pb-2'>Student</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.codeEleve
                  ? errors.codeEleve
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='codeEleve'
                rules={{ required: "Please provide a student" }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DdlUser
                      onChange={(e) => {
                        onChange(e.codeEleve);
                      }}
                      hideTeachers
                      value={
                        value
                          ? {
                              label: value,
                              value: value
                            }
                          : undefined
                      }
                    />
                  );
                }}
              />
            </div>
            {errors.codeEleve && (
              <span className={ERROR_STYLE}>{errors.codeEleve.message}</span>
            )}
          </div>
          <div id='reason'>
            <p className='font-bold pb-2'>Reason</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.reason
                  ? errors.reason
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting}
                className={INPUT_STYLE}
                placeholder='Reason'
                type='text'
                {...register("reason", {
                  required: "Reason is required"
                })}
              />
              {(isSubmitted || errors.reason) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.reason ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.reason && (
              <span className={ERROR_STYLE}>{errors.reason.message}</span>
            )}
          </div>
          <div id='type'>
            <p className='font-bold pb-2'>Type</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.type
                  ? errors.type
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='type'
                rules={{ required: "Please provide a type" }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DdlAttendanceType
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      value={
                        value
                          ? {
                              label: capitalizeFirstLetter(value),
                              value: value
                            }
                          : undefined
                      }
                    />
                  );
                }}
              />
            </div>
            {errors.type && (
              <span className={ERROR_STYLE}>{errors.type.message}</span>
            )}
          </div>
        </div>
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create attendance" : "Update attendance"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default AttendanceForm;
