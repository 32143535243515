import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import Modal from "components/common/Modal";
import Popup from "components/common/Popup";

import { InvoicesAPI } from "api";
import { toast } from "react-toastify";

import InvoicePreview from "components/invoices/InvoicePreview";
import InvoiceForm from "components/invoices/InvoiceForm";

import { INVOICE, INVOICE_ACTIONS } from "utils/types/invoice";
import { USER } from "utils/types/user";
import { DEFAULT_INVOICE } from "utils/constants/invoice";

interface Props {
  selectedItem: INVOICE;
  setSelectedItem: Dispatch<SetStateAction<INVOICE>>;
  selectedStudent?: Partial<USER>;
  action: INVOICE_ACTIONS;
  setAction: Dispatch<SetStateAction<INVOICE_ACTIONS>>;
  loading: string;
  setLoading: Dispatch<SetStateAction<string>>;
  refetch: any;
  users: USER[];
}

function InvoiceModal(props: Props) {
  const {
    selectedItem,
    setSelectedItem,
    selectedStudent,
    action,
    setAction,
    loading,
    setLoading,
    refetch,
    users
  } = props;

  const title = useMemo(() => {
    switch (action) {
      case "add":
        return "Create invoice";
      case "edit":
        return "Update invoice";
      case "delete":
        return "Delete invoice";
      case "view":
        return selectedItem.reference;
      default:
        return "";
    }
  }, [action, selectedItem]);

  const closeModal = useCallback(() => {
    setSelectedItem(undefined);
    setAction(undefined);
  }, []);

  return (
    <Modal
      isOpen={selectedItem !== undefined || action === "add"}
      onClose={closeModal}
      title={title}
      className={`md:max-w-[700px] ${action === "delete" ? "lg:max-w-[700px]" : "lg:max-w-[90%]"}`}
    >
      {["add", "edit"].includes(action) && (
        <InvoiceForm
          data={action === "add" ? DEFAULT_INVOICE : selectedItem}
          action={action}
          refetch={refetch}
          closeModal={closeModal}
          users={users}
        />
      )}
      {action === "view" && selectedStudent && (
        <InvoicePreview
          data={selectedItem}
          student={selectedStudent}
          loading={loading}
        />
      )}

      {action === "delete" && (
        <Popup
          onConfirm={() => {
            setLoading("deleting");
            InvoicesAPI.deletebyId(selectedItem.id).then(() => {
              toast.success(`${selectedItem.type} deleted successfully`, {
                position: "bottom-right"
              });
              refetch();
              closeModal();
            });
          }}
          onClose={() => closeModal()}
          loading={["fetch", "deleting"].includes(loading)}
        />
      )}
    </Modal>
  );
}

export default InvoiceModal;
