import { StyleSheet } from "@react-pdf/renderer";

export const pdfStyles = StyleSheet.create({
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%"
  },
  imgWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "75px",
    height: "60px"
  },
  imgStyles: {
    width: "50px",
    height: "60px",
    marginRight: "15px"
  },
  textStyle: {
    fontSize: 9,
    lineHeight: 1.2,
    fontFamily: "Montserrat"
  },
  textStyleSmall: {
    fontSize: 8,
    lineHeight: 1.2,
    fontFamily: "Montserrat"
  },
  textBold: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat"
  },
  textUnderlined: {
    fontSize: 9,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    textDecoration: "underline",
    marginRight: "4px"
  },
  invoiceInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "60%"
  },
  infoPaid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "40%"
  },
  esaInfo: {
    width: "100%"
  },
  badgeDanger: {
    backgroundColor: "#c00000",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    padding: "7px",
    borderRadius: "5px",
    marginBottom: "8px",
    width: "auto"
  },
  badgeSuccess: {
    backgroundColor: "#199163",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    padding: "7px",
    borderRadius: "5px",
    marginBottom: "8px",
    width: "auto"
  }
});

export const tableStyles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%"
  },
  thead: {
    backgroundColor: "#1f3864",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    border: "1px solid #1f3864"
  },
  tfoot: {
    backgroundColor: "#c00000",
    fontSize: 8,
    lineHeight: 1.2,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    border: "1px solid #c00000"
  },
  colDescription: {
    width: "50%",
    padding: "8px",
    display: "flex",
    flexDirection: "column"
  },
  colQuantity: {
    width: "10%",
    textAlign: "right",
    padding: "8px"
  },
  colUnitPrice: {
    width: "20%",
    textAlign: "right",
    padding: "8px"
  },
  colAmount: {
    width: "20%",
    textAlign: "right",
    padding: "8px"
  },
  colPenalty: {
    width: "75%",
    textAlign: "left",
    padding: "8px 0"
  },
  colPenaltyPrice: {
    width: "25%",
    textAlign: "right",
    padding: "8px 0"
  },
  colTotal: {
    width: "85%",
    padding: "8px"
  },
  discountStyle: {
    color: "#fff",
    backgroundColor: "#28a745",
    fontSize: 7,
    lineHeight: 1,
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginTop: "3px",
    padding: "3px",
    borderRadius: "3px",
    alignSelf: "flex-start",
  },
  totalWithPenalties: {
    justifyContent: "space-between",
    fontWeight: 600,
    border: "1px solid #c00000",
    padding: "10px 15px"
  },
  totalDue: {
    justifyContent: "space-between",
    fontWeight: 600,
    border: "1px solid #199163",
    padding: "10px 15px"
  },
  remaining: {
    justifyContent: "space-between",
    fontWeight: 600,
    border: "1px solid #c00000",
    backgroundColor: "#c00000",
    color: "white",
    padding: "10px 15px",
    margin: "20px 0 0"
  }
});
