import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import { OPTIONS } from "utils/types/general";

interface Props {
  onChange: any;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  disabled?: boolean;
}

function DdlAttendanceType(props: Props) {
  const {
    onChange,
    value = undefined,
    className = "",
    disabled = false
  } = props;

  const options = useMemo(() => {
    return [
      { label: "Absence", value: "absence" },
      { label: "Delay", value: "delay" }
    ];
  }, []);

  const handleChange = useCallback((e: any) => {
    onChange(e);
  }, []);

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={false}
      options={options}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder='Type'
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlAttendanceType;
