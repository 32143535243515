import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast, ToastOptions } from "react-toastify";

import Loader from "components/common/Loader";

import { UsersAPI } from "api";
import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  ERROR_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  object: string;
  message: string;
};

const FORM_GROUP_STYLE = "border rounded-lg relative";
function Page() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const res = await UsersAPI.sendEmail(data);
    const options: ToastOptions = {
      position: "bottom-right"
    };

    if (res.data.status !== 200) {
      toast.error(res.data.message, options);
    } else {
      toast.success(res.data.message, options);
    }
  };
  return (
    <div id='contact-page' className={BG_COLORED}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <h1 className='text-2xl text-center uppercase font-bold pb-6'>
          Contact us
        </h1>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col'
            autoComplete='off'
          >
            <div className='grid grid-cols-2 gap-4 mb-4'>
              {[
                { key: "firstName", title: "First name" },
                { key: "lastName", title: "Last name" }
              ].map((field: any) => (
                <div key={field.key}>
                  <p>{field.title}</p>
                  <div
                    className={`${FORM_GROUP_STYLE} rounded-lg ${
                      isSubmitted
                        ? errors[field.key]
                          ? "border-rose-600"
                          : "border-lime-500"
                        : ""
                    }`}
                  >
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} rounded-lg`}
                      placeholder={field.title}
                      type='text'
                      {...register(field.key, { required: true })}
                    />
                    {isSubmitted && (
                      <div
                        className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                          errors[field.key] ? "bg-rose-600" : "bg-lime-500"
                        }`}
                      ></div>
                    )}
                  </div>
                  {errors[field.key] && (
                    <span
                      className={ERROR_STYLE}
                    >{`${field.title} is required`}</span>
                  )}
                </div>
              ))}
            </div>

            <div id='email' className='mb-4'>
              <p>Email</p>
              <div
                className={`${FORM_GROUP_STYLE} rounded-lg ${
                  isSubmitted
                    ? errors.email
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <Controller
                  control={control}
                  name='email'
                  rules={{
                    required: "Please provide an email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please provide a valid email address"
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} rounded-lg`}
                      placeholder='Email'
                      type='email'
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {isSubmitted && (
                  <div
                    className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                      errors.email ? "bg-rose-600" : "bg-lime-500"
                    }`}
                  ></div>
                )}
              </div>
              {errors.email && (
                <span className={ERROR_STYLE}>{errors.email.message}</span>
              )}
            </div>

            <div id='object' className='mb-4'>
              <p>Object</p>
              <div
                className={`${FORM_GROUP_STYLE} rounded-lg ${
                  isSubmitted
                    ? errors.object
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <input
                  disabled={isSubmitting}
                  className={`${INPUT_STYLE} rounded-lg`}
                  placeholder='Object'
                  type='text'
                  {...register("object", { required: true })}
                />
                {isSubmitted && (
                  <div
                    className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                      errors.object ? "bg-rose-600" : "bg-lime-500"
                    }`}
                  ></div>
                )}
              </div>
              {errors.object && (
                <span className={ERROR_STYLE}>Object is required</span>
              )}
            </div>

            <div id='message' className='mb-4'>
              <p>Message</p>
              <div
                className={`${FORM_GROUP_STYLE} rounded-lg ${
                  isSubmitted
                    ? errors.message
                      ? "border-rose-600"
                      : "border-lime-500"
                    : ""
                }`}
              >
                <textarea
                  disabled={isSubmitting}
                  className={`${INPUT_STYLE} rounded-lg`}
                  placeholder='Message'
                  {...register("message", { required: true })}
                ></textarea>
                {isSubmitted && (
                  <div
                    className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                      errors.message ? "bg-rose-600" : "bg-lime-500"
                    }`}
                  ></div>
                )}
              </div>
              {errors.message && (
                <span className={ERROR_STYLE}>Message is required</span>
              )}

              <div className='w-full flex items-center justify-center mt-8'>
                {isSubmitting ? (
                  <div className={`${SUBMIT_STYLE} bg-gray-100`}>
                    <Loader width='w-6' height='h-6' text='text-gray-300' />
                  </div>
                ) : (
                  <input
                    type='submit'
                    disabled={isSubmitting}
                    value='Send message'
                    className={`${SUBMIT_STYLE} bg-esaBlue text-white`}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Page;
