import React from "react";

import Card from "components/common/Card";
import PricesInfos from "components/PricesInfos";
import RegistrationInfos from "components/RegistrationInfos";

import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  LG_CENTERED
} from "utils/styles";

const TITLE = "uppercase font-bold mb-4 text-esaTextLightBlue pt-2";

function Page() {
  return (
    <div id='primaryschool-page' className={BG_COLORED}>
      <section
        id='main-banner'
        className='min-h-96 relative flex flex-col justify-center'
        style={{
          background: "url('/images/primary1.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
      >
        <div className='absolute top-0 left-0 bg-black w-full h-full opacity-10 z-0' />
        <div
          className={`relative ${CONTAINER} ${LG_CENTERED} text-esaBlue text-center h-full flex flex-col justify-center`}
        >
          <h1 className='text-3xl font-bold uppercase'>PRIMARY SCHOOL</h1>
          <p className='font-semibold text-xl pb-2'>Aka ELEMENTARY SCHOOL</p>
          <p className='font-semibold text-xl pb-8'>G1, G2, G3, G4, G5</p>
          <p className='text-lg'>
            Primary school is where curiosity transforms into deeper
            understanding and exploration. Through hands-on activities,
            collaborative projects, and creative problem-solving, children build
            critical thinking skills and a love for learning. Each day, students
            are challenged to think independently, work together, and develop
            the foundational skills necessary for their academic journey ahead.
          </p>
        </div>
      </section>

      <section
        id='daycare-features'
        className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8 ${CONTAINER} ${CONTAINER_PADDING} pt-20`}
      >
        <Card>
          <h2 className={TITLE}>Our teaching methods</h2>
          <p className='pb-4'>
            We have the responsibility to follow-up the students one by one.
            Inspired by Montessori method, we are continually applying practical
            teaching.
          </p>
          <p>
            Materials are obviously available to children for studying with this
            method.
          </p>
        </Card>

        <Card>
          <h2 className={TITLE}>Personal study</h2>
          <p>
            Family time, as school, is very important. That is why we give 40mn
            of "Personal study" at the end of the day to review or improve what
            they did. This is in order to have a better development.
          </p>
        </Card>

        <Card>
          <h2 className={TITLE}>Extra-curricular activity</h2>
          <p className='pb-4'>
            Extra-curricular activities help the students to have a truly
            well-rounded education.
          </p>
          <p>
            ESA provides free activities such as Music, Dance, Singing, Malagasy
            Game, Cooking, Drama, art craft, sewing...
          </p>
        </Card>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <RegistrationInfos color='text-esaYellow' />
        </div>
      </section>

      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className={`rounded-lg shadow-lg overflow-hidden bg-white p-6`}>
          <PricesInfos type='elementary' color='text-esaYellow' />
        </div>
      </section>
    </div>
  );
}

export default Page;
