import React from "react";
import Layout from "components/layout/Layout";
import { Route, Routes } from "react-router-dom";

import ErrorPage from "pages/error";
import HomePage from "pages/home";

import DaycarePage from "pages/daycare";
import PreschoolPage from "pages/preschool";
import PrimarySchoolPage from "pages/primaryschool";
import MiddleSchoolPage from "pages/middleschool";

import NewsPage from "pages/news";
import ContactPage from "pages/contact";
import RegistrationPage from "pages/registration";

import DashboardPage from "pages/dashboard";

import AgendaPage from "pages/agenda";
import GradesPage from "pages/grades";
import DelayAbsencePage from "pages/delay-absence";

import InvoicesPage from "pages/invoices";
import StudentsPage from "pages/students";

import PricesPage from "pages/prices";
import TeachersPage from "pages/teachers";
import TermsPage from "pages/terms";
import FilesPage from "pages/files";
import SubjectsPage from "pages/subjects";
import LevelsPage from "pages/levels";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<HomePage />} />

        <Route path='daycare' element={<DaycarePage />} />
        <Route path='preschool' element={<PreschoolPage />} />
        <Route path='primaryschool' element={<PrimarySchoolPage />} />
        <Route path='middleschool' element={<MiddleSchoolPage />} />
        <Route path='news' element={<NewsPage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='registration' element={<RegistrationPage />} />

        <Route path='dashboard/:role' element={<DashboardPage />} />

        <Route path='agenda' element={<AgendaPage />} />
        <Route path='grades' element={<GradesPage />} />
        <Route path='delay-absence' element={<DelayAbsencePage />} />

        <Route path='invoices' element={<InvoicesPage />} />
        <Route path='students' element={<StudentsPage />} />

        <Route path='prices' element={<PricesPage />} />
        <Route path='teachers' element={<TeachersPage />} />
        <Route path='terms' element={<TermsPage />} />
        <Route path='files' element={<FilesPage />} />
        <Route path='subjects' element={<SubjectsPage />} />
        <Route path='levels' element={<LevelsPage />} />

        <Route path='*' element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
