import axios from "axios";
import { isAuth } from "utils/context";
import { ERROR } from "utils/types/general";
import { USER } from "utils/types/user";

const login = async (credentials: any) => {
  const url = `${process.env.REACT_APP_API_URL}login`;
  try {
    const response = await axios.post(url, credentials);
    axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    return response.data;
  } catch {
    return {
      error: true,
      status: 403,
      message: "Authentication failure"
    };
  }
};

const getUser = async () => {
  const url = `${process.env.REACT_APP_API_URL}user`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch {
    return {
      error: true,
      status: 404,
      message: "Could not fetch your info. Please retry login"
    };
  }
};

const getByCodeEleve = async (
  codeEleve: string
): Promise<Partial<USER> | null | ERROR> => {
  const error: ERROR = {
    error: true,
    status: 403,
    message: "Failed to search by code"
  };

  if (isAuth()) {
    try {
      const url = `${process.env.REACT_APP_API_URL}user/${codeEleve}`;

      const resStudent = await axios.get(url);
      return resStudent.data.data.length > 0 ? resStudent.data.data[0] : null;
    } catch {
      return error;
    }
  }
  return error;
};

const sendEmail = async (email: any) => {
  const url = `${process.env.REACT_APP_API_URL}contact`;
  const response = await axios.post(url, email);
  return response;
};

const update = async (credentials: any) => {
  const url = `${process.env.REACT_APP_API_URL}users/${credentials.id}`;
  const response = await axios.put(url, credentials);
  return response;
};

const getTeachers = async (
  page?: number,
  elementsPerPage?: number,
  all?: boolean,
  name?: string
) => {
  const url = `${process.env.REACT_APP_API_URL}users/searchFull/role?${
    page ? `page=${page}` : ""
  }${
    elementsPerPage ? `&element=${elementsPerPage}` : ""
  }${all ? `&all=${String(all)}` : ""}`;

  try {
    const response = await axios.post(url, { value: "TUTOR", name });
    return response.data;
  } catch {
    return {
      error: true,
      status: 404,
      message: "Could not fetch teacher."
    };
  }
};

const add = async (user: any) => {
  const url = `${process.env.REACT_APP_API_URL}users`;
  try {
    const response = await axios.post(url, user);
    return response.data;
  } catch {
    return {
      error: true,
      status: 404,
      message: "Could not fetch user."
    };
  }
};

const deleteUser = async (id: any) => {
  const url = `${process.env.REACT_APP_API_URL}users/${id}`;
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch {
    return {
      error: true,
      status: 404,
      message: "Could not fetch teacher."
    };
  }
};

export {
  login,
  getUser,
  sendEmail,
  add,
  update,
  deleteUser,
  getTeachers,
  getByCodeEleve
};
