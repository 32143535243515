/* eslint-disable no-plusplus */
import React, { useMemo, Fragment } from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";

import {
  pdfStyles,
  tableStyles
} from "components/templates/ReportCardTemplate.style";
import { GRADE_PARAMS, STATS_ATTENDANCE } from "utils/types/grade";

export interface Props {
  data: any;
  info: GRADE_PARAMS;
  subjects: string[];
  delays: STATS_ATTENDANCE;
  isSmall: boolean;
  commentaire: string;
}

const ReportCardTemplate = ({
  data,
  info,
  subjects,
  delays,
  isSmall,
  commentaire
}: Props) => {
  const marksExplanation = useMemo(() => {
    if (isSmall) {
      return [
        { mark: "S+", effort: "Excellent" },
        { mark: "S", effort: "Very Good" },
        { mark: "S-", effort: "Satisfactory" },
        { mark: "P", effort: "Progressing" },
        { mark: "N", effort: "Need improvement" }
      ];
    }
    return [
      { mark: "A", effort: "Excellent effort" },
      { mark: "B", effort: "Good effort" },
      { mark: "C", effort: "Satisfactory" },
      { mark: "D", effort: "Need some improvement" }
    ];
  }, [isSmall]);

  Font.register({
    family: "Montserrat",
    fonts: [
      { src: "/fonts/Montserrat-Light.ttf" },
      { src: "/fonts/Montserrat-SemiBold.ttf", fontWeight: 700 },
      { src: "/fonts/Montserrat-Italic.ttf", fontStyle: "italic" }
    ]
  });

  return (
    <Page size='A4' style={{ padding: "40px" }}>
      <View style={pdfStyles.headerWrapper}>
        <View style={pdfStyles.imgWrapper}>
          <Image style={pdfStyles.imgStyles} src='/images/logo-big.png' cache />
        </View>
        <View style={pdfStyles.esaInfo}>
          <Text style={pdfStyles.textBold}>
            ELEMENTARY SCHOOL OF ANTANANARIVO
          </Text>
          <Text style={pdfStyles.textStyle}>Amboara Ambohitrarahaba</Text>
          <Text style={pdfStyles.textStyle}>contact@esa-mada.com</Text>
          <Text style={pdfStyles.textStyle}>+261 34 40 216 81</Text>
          <Text style={pdfStyles.textStyle}>
            NIF: 4003517556 - STAT: 11 2019 0 05 848
          </Text>
        </View>
      </View>

      <Text style={pdfStyles.textTitle}>Report Card</Text>

      <View style={pdfStyles.infoWrapper}>
        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>Name:</Text>
          <Text style={pdfStyles.textStyle}>{info.student.name}</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>Level:</Text>
          <Text style={pdfStyles.textStyle}>{info.student.level}</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>Term:</Text>
          <Text style={pdfStyles.textStyle}>{info.term.termNb}</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textUnderlined}>School Year:</Text>
          <Text style={pdfStyles.textStyle}>{info.schoolYear}</Text>
        </View>
      </View>

      {delays && (
        <View style={pdfStyles.delaysWrapper}>
          <View style={pdfStyles.row}>
            <Text style={pdfStyles.textUnderlined}>Delays:</Text>
            <Text style={pdfStyles.textStyle}>{delays.delays}</Text>
          </View>

          <View style={pdfStyles.row}>
            <Text style={pdfStyles.textUnderlined}>Absence:</Text>
            <Text style={pdfStyles.textStyle}>{delays.absences}</Text>
          </View>
        </View>
      )}

      <View style={tableStyles.row}>
        {subjects &&
          subjects.map((sub: string) => (
            <View style={[tableStyles.card]} key={sub}>
              <Text style={[tableStyles.thead]}>{sub}</Text>
              <Text style={[tableStyles.theadBold]}>
                The student is able to:
              </Text>

              {data
                .filter((a: any) => a.subject === sub)[0]
                .data.map((details: any, idx: number) => (
                  <Fragment key={`${sub}-${idx}`}>
                    {details.skill !== "" && (
                      <>
                        <View style={pdfStyles.row}>
                          <Text style={[tableStyles.col, pdfStyles.textStyle]}>
                            {details.skill}
                          </Text>
                          <Text style={[tableStyles.col, pdfStyles.textStyle]}>
                            {details.mark}
                          </Text>
                        </View>
                      </>
                    )}
                  </Fragment>
                ))}
            </View>
          ))}
      </View>

      <View style={pdfStyles.delaysWrapper}>
        <View style={[pdfStyles.row, pdfStyles.comment]}>
          <Text style={pdfStyles.textUnderlined}>Comments:</Text>
        </View>

        <View style={pdfStyles.row}>
          <Text style={pdfStyles.textStyle}>{commentaire}</Text>
        </View>

        <View style={tableStyles.tableWrapper}>
          {marksExplanation.map((exp: any, index: number) => (
            <View key={`${exp}${index}`} style={pdfStyles.row}>
              <Text
                style={[
                  tableStyles.col,
                  pdfStyles.textStyle,
                  tableStyles.cell,
                  tableStyles.cellLeft
                ]}
              >
                {exp.effort}
              </Text>
              <Text
                style={[
                  tableStyles.col,
                  pdfStyles.textStyle,
                  tableStyles.cell,
                  tableStyles.cellRight
                ]}
              >
                {exp.mark}
              </Text>
            </View>
          ))}
        </View>
      </View>

      <View style={tableStyles.row}>
        <Text style={tableStyles.colLg3}>Principal</Text>
        <Text style={tableStyles.colLg3}>Pedagogic manager</Text>
        <Text style={tableStyles.colLg3}>Head Teacher</Text>
        <Text style={tableStyles.colLg3}>Parents</Text>
      </View>
    </Page>
  );
};

export default ReportCardTemplate;
