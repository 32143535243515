import React, { Fragment, useEffect, useRef, useState } from "react";
import { useUser } from "context/user.context";
import { useNavigate } from "react-router-dom";
import { TERM } from "utils/types/term";
import { BG_COLORED, CONTAINER, CONTAINER_PADDING } from "utils/styles";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import { getTerms } from "utils/api_calls/term";
import { emptyTerms } from "utils/constants/term";
import Loader from "components/common/Loader";
import TermCollapsible from "components/terms/TermCollapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { TermAPI } from "api";
import _ from "lodash";
import TermInfosModal from "components/terms/TermInfosModal";

// Custom type for the TermCollapsible ref
interface TermCollapsibleRef {
  submit: () => void;
}

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<TERM[]>(emptyTerms);
  const [schoolYear, setSchoolYear] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  // Array to store refs for each TermCollapsible, including null for uninitialized refs
  const termRefs = useRef<(TermCollapsibleRef | null)[]>([]);

  const fetchData = async (schoolYearParam: string) => {
    if (!schoolYearParam) return;
    setLoading(true);
    const trs = await getTerms(schoolYearParam);
    setTerms(trs.length === 0 ? emptyTerms : trs);
    setSchoolYear(schoolYearParam);
    setLoading(false);
  };

  useEffect(() => {
    if (isAuth) {
      if (ctxUser && !["ADMIN", "SECRETARIAT"].includes(ctxUser.role)) {
        switch (ctxUser.role) {
          case "PARENT":
            navigate("/dashboard/parent");
            break;
          case "TUTOR":
            navigate("/dashboard/tutor");
            break;
          default:
            navigate("/");
            break;
        }
      } else {
        fetchData(undefined);
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  const handleSave = async () => {
    // Trigger submission for each TermCollapsible
    const results = await Promise.all(
      termRefs.current.map(async (ref) => {
        if (ref && typeof ref.submit === "function") {
          const res = await ref.submit();
          return res;
        }
        return undefined;
      })
    );

    // Filter out undefined results (in case of validation errors)
    const validResults = results.filter((result) => result !== undefined);

    if (validResults.length === 0) return false;
    const noChanges = _.isEqual(validResults, terms);
    if (noChanges) {
      toast.info("No changes were made", { position: "bottom-right" });
      return false;
    }

    const term1Valid = validResults.length > 0 && validResults[0] !== undefined;
    const term2Valid = validResults.length > 0 && validResults[1] !== undefined;
    const term3Valid = validResults.length > 0 && validResults[2] !== undefined;

    if (!term1Valid || !term2Valid || !term3Valid) return false;

    if (!schoolYear) {
      toast.error("Please choose a school year", { position: "bottom-right" });
      return false;
    }

    setLoading(true);
    const process =
      (validResults[0] as any).schoolYear === "" ? "add" : "update";

    if (process === "add") {
      const newTerms = validResults.map((v) => {
        return { ...(v as any), schoolYear };
      });
      await TermAPI.add(schoolYear, newTerms).then(async () => {
        toast.success("Terms added successfully", { position: "bottom-right" });
        setLoading(false);
        await fetchData(schoolYear);
        setShowModal(true);
      });
    } else {
      await TermAPI.update(schoolYear, validResults).then(async () => {
        toast.success("Terms updated successfully", {
          position: "bottom-right"
        });
        setLoading(false);
        await fetchData(schoolYear);
        setShowModal(true);
      });
    }
  };

  return (
    <div id='dashboard-admin-page' className={`${BG_COLORED} py-8`}>
      <section className={`${CONTAINER} ${CONTAINER_PADDING}`}>
        <div className='flex items-center justify-between flex-wrap pb-6'>
          <DdlSchoolYear
            onChange={(e) => {
              fetchData(e.value);
            }}
            value={
              schoolYear ? { label: schoolYear, value: schoolYear } : undefined
            }
            className='w-full md:w-[25%] border rounded-3xl my-4'
          />
          <h1 className='text-center uppercase my-4'>
            <span>Manage terms for the school year:</span>
            <span className='font-bold ml-2'>{schoolYear}</span>
          </h1>
          <button
            disabled={loading}
            className='bg-esaMinty hover:bg-esaMintyDark text-white rounded-lg px-4 py-2 shadow-md w-full md:w-[25%]'
            onClick={handleSave} // Trigger form submission on save
          >
            <FontAwesomeIcon icon={faSave} />
            <span className='ml-2 font-semibold'>Save changes</span>
          </button>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div>
            {terms.map((term: TERM, index: number) => (
              <Fragment key={`term-${index}-wrapper`}>
                <TermCollapsible
                  ref={(el) => (termRefs.current[index] = el)} // Store references
                  term={term}
                />
              </Fragment>
            ))}
          </div>
        )}
      </section>

      <TermInfosModal
        terms={terms}
        schoolYear={schoolYear}
        showModal={showModal}
        setShowModal={setShowModal}
        loading={loading}
      />
    </div>
  );
}

export default Page;
