import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";
import DdlTerm from "components/dropdowns/DdlTerm";
import DdlUser from "components/dropdowns/DdlUser";

import { useUser } from "context/user.context";

import { DUMMY_TERM } from "utils/constants/grade";
import { getCurrentSchoolYear } from "utils/dates";
import { ERROR_STYLE, FORM_GROUP_STYLE, SUBMIT_STYLE } from "utils/styles";
import { OPTIONS } from "utils/types/general";
import { GRADE_PARAMS } from "utils/types/grade";
import { TERM } from "utils/types/term";
import { USER } from "utils/types/user";

interface Props {
  callback: (value: GRADE_PARAMS) => void;
  loading: boolean;
}
function GradesHeaderForm({ callback, loading }: Props) {
  const { ctxUser } = useUser();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<GRADE_PARAMS>({
    defaultValues: {
      schoolYear: getCurrentSchoolYear(),
      student: ctxUser.role === "PARENT" ? ctxUser : undefined,
      term: undefined
    }
  });

  const onSubmit: SubmitHandler<GRADE_PARAMS> = async (data) => {
    callback(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div id='student' className='min-h-auto md:min-h-[100px]'>
        <p className='font-bold pb-2'>Student</p>
        {ctxUser.role === "PARENT" ? (
          <p>{`${ctxUser.codeEleve} - ${ctxUser.name}`}</p>
        ) : (
          <>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.student
                  ? errors.student
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <Controller
                control={control}
                name='student'
                rules={{ required: "Please choose a student" }}
                render={({ field: { onChange, value } }) => (
                  <DdlUser
                    placeholder='Find student'
                    className='rounded-3xl shadow-md'
                    hideTeachers
                    onChange={(e: USER) => onChange(e)}
                    value={
                      value
                        ? {
                            label: `${value.codeEleve} ${value.name}`,
                            value: value.codeEleve
                          }
                        : undefined
                    }
                    disabled={
                      !["TUTOR", "ADMIN", "SECRETARIAT"].includes(ctxUser.role)
                    }
                  />
                )}
              />
            </div>
            {errors.student && (
              <span className={ERROR_STYLE}>{errors.student.message}</span>
            )}
          </>
        )}
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 pt-4'>
        <div id='schoolYear'>
          <p className='font-bold pb-2'>School Year</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.schoolYear
                ? errors.schoolYear
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='schoolYear'
              rules={{ required: "Please provide a school year" }}
              render={({ field: { onChange, value } }) => (
                <DdlSchoolYear
                  className='rounded-3xl shadow-md'
                  onChange={(e: OPTIONS) => onChange(e.value)}
                  value={{ label: value, value: value }}
                  disabled={loading}
                />
              )}
            />
          </div>
          {errors.schoolYear && (
            <span className={ERROR_STYLE}>
              {(errors as any).schoolYear.message}
            </span>
          )}
        </div>

        <div id='term'>
          <p className='font-bold pb-2'>Term</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.term
                ? errors.term
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='term'
              rules={{ required: "Please choose a term" }}
              render={({ field: { onChange, value } }) => (
                <DdlTerm
                  className='rounded-3xl shadow-md'
                  data={DUMMY_TERM} // because we only need the termNb
                  disabled={loading}
                  onChange={(e: TERM) => onChange(e)}
                  value={
                    value
                      ? { label: `Term ${value.termNb}`, value: value.termNb }
                      : undefined
                  }
                />
              )}
            />
          </div>
          {errors.term && (
            <span className={ERROR_STYLE}>{(errors as any).term.message}</span>
          )}
        </div>
      </div>

      <div className='w-full grid grid-cols-1 md:grid-cols-[7fr_3fr]'>
        <div>
          {watch("term") && watch("schoolYear") && (
            <p className='pb-4 md:pb-0'>{`Quiz & Exam Term ${watch("term").termNb} of ${watch("schoolYear")}`}</p>
          )}
        </div>

        <div>
          {isSubmitting || loading ? (
            <div
              className={`${SUBMIT_STYLE} mx-0 ml-auto mr-0 bg-gray-100 min-h-12 flex items-center justify-center`}
            >
              <Loader width='w-6' height='h-6' text='text-gray-300' />
            </div>
          ) : (
            <input
              type='submit'
              disabled={isSubmitting}
              value='Search'
              className={`${SUBMIT_STYLE} mx-0 ml-auto mr-0 bg-indigo-500 hover:bg-indigo-300 min-h-12 flex items-center justify-center text-white cursor-pointer`}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default GradesHeaderForm;
