import { AttendanceAPI } from "api";
import { GRADE_PARAMS } from "utils/types/grade";

export const getStatsAttendance = async (params: GRADE_PARAMS) => {
  const resAttendanceStats = AttendanceAPI.getStats({
    codeEleve: params.student.codeEleve,
    term: params.term.termNb,
    schoolYear: params.schoolYear
  }).then((r: any) => r);
  const res = await Promise.all([resAttendanceStats]);
  return res[0];
};
