import React from "react";
import { MARK } from "utils/types/grade";

interface Props {
  marks: MARK[];
}

function Mark(props: Props) {
  const { marks } = props;

  return (
    <>
      {marks.map((mark: MARK, iMark: number) => (
        <div
          key={mark.mark}
          className={`grid grid-cols-[50px_100%] py-1 px-4 ${iMark === marks.length - 1 ? "" : "border-b"}`}
        >
          <p>{mark.mark}</p>
          <p>{mark.effort}</p>
        </div>
      ))}
    </>
  );
}

export default Mark;
