import React, { useEffect, useState } from "react";

import { ATTENDANCE } from "utils/types/attendance";
import { PAGINATION } from "utils/types/general";

import { useUser } from "context/user.context";
import { AttendanceAPI } from "api";
import AttendanceParentTable from "components/delayAbsence/AttendanceParentTable";
import Loader from "components/common/Loader";
import DdlSchoolYear from "components/dropdowns/DdlSchoolYear";

const ELEMENTS_PER_PAGE = 10;

const AttendanceParents = () => {
  const { ctxUser } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [attendances, setAttendances] = useState<ATTENDANCE[]>([]);
  const [pagination, setPagination] = useState<PAGINATION>();
  const [selectedSchoolYear, setSelectedSchoolYear] = useState("");

  const getAttendances = async (page: number, schoolYear?: string) => {
    setLoading(true);
    AttendanceAPI.search(
      page,
      ctxUser.codeEleve,
      ELEMENTS_PER_PAGE,
      false,
      schoolYear ? schoolYear : selectedSchoolYear
    )
      .then((r: any) => {
        const { data, ...rest } = r;
        setAttendances(data);
        setPagination(rest);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAttendances(1, selectedSchoolYear);
  }, []);

  return (
    <>
      <div className='flex items-center justify-between flex-wrap pb-6'>
        <div className='w-full md:w-[50%] uppercase font-bold text-center md:text-left pb-3'>
          <p>Delays and absences</p>
          <p className='font-bold'>{`${ctxUser.name} - ${ctxUser.codeEleve}`}</p>
        </div>
        <div className='w-full md:w-[50%] flex flex-row flex-wrap gap-3 justify-start md:justify-end items-center'>
          <DdlSchoolYear
            onChange={(e) => {
              setSelectedSchoolYear(e.value);
              getAttendances(1, e.value);
            }}
            value={
              selectedSchoolYear
                ? { label: selectedSchoolYear, value: selectedSchoolYear }
                : undefined
            }
            className='min-w-[300px] border rounded-3xl'
          />
        </div>
      </div>

      <div
        className={`rounded-lg shadow-lg overflow-hidden bg-white py-4 px-2`}
      >
        {loading ? (
          <Loader />
        ) : (
          <AttendanceParentTable
            attendances={attendances}
            setAttendances={setAttendances}
            pagination={pagination}
            getData={getAttendances}
          />
        )}
      </div>
    </>
  );
};

export default AttendanceParents;
