import { InvoicesAPI } from "api";
import moment from "moment";
import { INVOICE, INVOICE_PAYMENT, INVOICE_PRICE } from "utils/types/invoice";

export const getAmountStr = (amount: string | number): string => {
  return Number(amount)
    .toLocaleString()
    .toString()
    .replace(/,/g, " ")
    .replace(/\//g, " ");
};

export const amountLine = (detail: INVOICE_PRICE) => {
  let amount = Number(detail.amount);
  if (detail.quantity) {
    amount = Number(detail.quantity) * amount;
  }
  if (detail.discount) {
    amount = amount - Number(detail.discount);
  }
  return getAmountStr(amount);
};

export const getTotalAmount = (invoice: INVOICE) => {
  let price = 0;
  if (invoice.details && invoice.details.length > 0) {
    invoice.details.map((item: any) => {
      let p = Number(item.amount);

      if (item.quantity) {
        p = Number(item.amount) * Number(item.quantity);
      }

      if (item.discount) {
        p -= Number(item.discount);
      }

      price += p;
      return price;
    });

    return price;
  }

  return price;
};

export const getAmountPaid = (invoice: any) => {
  let res = invoice.amount_paid || 0;

  if (invoice.payments && invoice.payments.length > 0) {
    const sumAmountsPaid = invoice.payments.reduce(
      (accumulator, object) => Number(accumulator) + Number(object.amount),
      0
    );

    res += sumAmountsPaid;
  }
  return res;
};

export const getAmountDue = (invoice) => {
  const totalAmount = getTotalAmount(invoice);
  const amountPaid = getAmountPaid(invoice);

  let res = totalAmount - amountPaid;
  if (invoice.penalties && invoice.penalties.length > 0) {
    const sumPenalties = invoice.penalties.reduce(
      (accumulator, object) => Number(accumulator) + Number(object.amount),
      0
    );

    res += sumPenalties;
  }

  return res;
};

export const generateInvoiceReference = async (
  codeEleve: string
): Promise<string> => {
  const baseReference = `${moment(new Date()).format("YYYYMMDD")}-${codeEleve}`;
  const resGen = await InvoicesAPI.generateNextReference(baseReference).then(
    (r) => r
  );

  const result = await Promise.all([resGen]);
  return `${baseReference}-${result[0]}`;
};

export const sortInvoicePaymentByDate = (
  payments: INVOICE_PAYMENT[]
): INVOICE_PAYMENT[] => {
  return payments.sort((a, b) => {
    const dateA: any = a.date ? new Date(a.date) : new Date(0); // If date is "", treat as earliest
    const dateB: any = b.date ? new Date(b.date) : new Date(0);

    return dateA - dateB;
  });
};
