import React, { useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "context/user.context";
import { UsersAPI } from "api";
import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";

import Loader from "components/common/Loader";
import Modal from "components/common/Modal";
import { NAV_ITEM_STYLE } from "components/layout/Header";
import { useNavigate } from "react-router-dom";

type Inputs = {
  username: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<Inputs>();
  const { changeToken, changeAuthStatus, changeUser } = useUser();
  const [open, setOpen] = useState<boolean>(false);
  const [showPwd, setShowPwd] = useState<boolean>(false);

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const resAuth = await UsersAPI.login(data);

    if (resAuth.error) {
      toast.error(resAuth.message, { position: "bottom-right" });
    } else {
      localStorage.setItem("token", resAuth.token);
      changeToken(resAuth.token);
      changeAuthStatus(true);

      const resUser = await UsersAPI.getUser();
      if (resUser.error) {
        toast.error(resUser.message, { position: "bottom-right" });
      } else {
        changeUser(resUser);
        if (["ADMIN", "SECRETARIAT"].includes(resUser.role)) {
          navigate("/dashboard/admin");
        }
        if (resUser.role === "PARENT") {
          navigate("/dashboard/parent");
        }
        if (resUser.role === "TUTOR") {
          navigate("/dashboard/tutor");
        }
        setOpen(false);
      }
    }
  };

  return (
    <div>
      <button
        type='button'
        className={`${NAV_ITEM_STYLE} uppercase hover:bg-slate-100 rounded-lg ml-2 lg:py-3`}
        onClick={() => {
          reset();
          setOpen(!open);
        }}
      >
        Login
      </button>

      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        title='Login'
        className='md:max-w-[700px] lg:max-w-[700px]'
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted
                ? errors.username
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              disabled={isSubmitting}
              className={INPUT_STYLE}
              placeholder='username'
              type='text'
              {...register("username", { required: true })}
            />
            {isSubmitted && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.username ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>

          {errors.username && (
            <span className={ERROR_STYLE}>Username is required</span>
          )}

          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted
                ? errors.password
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='password'
              rules={{
                required: "Password is required"
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  {showPwd ? (
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} !pr-20`}
                      placeholder='password'
                      type='text'
                      onChange={onChange}
                      value={value}
                      autoComplete='off'
                    />
                  ) : (
                    <input
                      disabled={isSubmitting}
                      className={`${INPUT_STYLE} !pr-20`}
                      placeholder='password'
                      type='password'
                      onChange={onChange}
                      value={value}
                      autoComplete='off'
                    />
                  )}
                </>
              )}
            />

            {password.current && (
              <button
                className='block absolute top-2 right-10'
                onClick={() => setShowPwd(!showPwd)}
              >
                <FontAwesomeIcon icon={showPwd ? faEyeSlash : faEye} />
              </button>
            )}
            {isSubmitted && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.password ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.password && (
            <span className={ERROR_STYLE}>{errors.password.message}</span>
          )}

          {isSubmitting ? (
            <div className={`${SUBMIT_STYLE} bg-gray-100`}>
              <Loader width='w-6' height='h-6' text='text-gray-300' />
            </div>
          ) : (
            <input
              type='submit'
              disabled={isSubmitting}
              value='Login'
              className={`${SUBMIT_STYLE} bg-blue-200`}
            />
          )}
        </form>
      </Modal>
    </div>
  );
};

export default Login;
