import { EXAM_HEADERS } from "utils/constants/grade";
import {
  EXAM,
  GRADE_HEADER,
  QUIZZ,
  REPORT_ITEM,
  REPORT_ITEM_DATA
} from "utils/types/grade";
import { LEVEL } from "utils/types/level";

export const formatHeaderQuizz = (level: LEVEL): GRADE_HEADER[] => {
  const headerQuiz = [{ header: "Subject", key: "subject" }];
  for (let index = 1; index <= Number(level.quizz); index++) {
    headerQuiz.push({ header: `Quiz ${index}`, key: `quiz${index}` });
    headerQuiz.push({ header: "Comments", key: `comment${index}` });
  }
  headerQuiz.push({ header: "Quiz Average", key: "quizAverage" });
  return headerQuiz;
};

export const generateEmptyGrades = (level: LEVEL) => {
  const emptyQuizzGrades: QUIZZ[] = level.subjects.map(
    (subject: string, idxSubject: number) => {
      const temp = {
        id: idxSubject,
        subject: subject,
        quizAverage: ""
      };

      for (let index = 1; index < Number(level.quizz); index++) {
        temp[`quiz${index}`] = "";
        temp[`comment${index}`] = "";
      }

      return temp;
    }
  );

  const emptyExamGrades: EXAM[] = level.subjects.map(
    (subject: string, idxSubject: number) => {
      return {
        id: idxSubject,
        subject: subject,
        exam: "",
        comments: "",
        quizAverageExam: "",
        totalAverage: "",
        grade: "",
        generalEffort: "",
        attendance: ""
      };
    }
  );

  const emptyReportData: REPORT_ITEM[] = level.subjects.map(
    (subject: string, idxSubject: number) => {
      return {
        id: idxSubject,
        subject: subject,
        data: [
          { id: 0, skill: "", mark: "" },
          { id: 1, skill: "", mark: "" },
          { id: 2, skill: "", mark: "" },
          { id: 3, skill: "", mark: "" },
          { id: 4, skill: "", mark: "" }
        ]
      };
    }
  );

  return {
    emptyQuizzGrades,
    emptyExamGrades,
    emptyReportData
  };
};

export const formatReportGrades = (reportItems: REPORT_ITEM[]) => {
  const transformedData = {};
  reportItems.forEach((reportItem: REPORT_ITEM, iReportItem: number) => {
    const { data } = reportItem;

    if (reportItem.data) {
      const items = data.map((reportItemData: REPORT_ITEM_DATA) => {
        return {
          skill: reportItemData.skill,
          mark: reportItemData.mark
        };
      });

      transformedData[iReportItem] = { data: items };
    }
    if ((reportItem as any).comments) {
      transformedData[iReportItem] = { comments: (reportItem as any).comments };
    }
  });
  return transformedData;
};

export const formatQuizzGrades = (quizz: QUIZZ[], headers: GRADE_HEADER[]) => {
  const transformedData = {};
  const headerKeys = headers.map((headerItem: GRADE_HEADER) => headerItem.key);

  quizz.forEach((quizzItem: QUIZZ, iQuizz: number) => {
    transformedData[iQuizz] = {};

    headerKeys.forEach((headerKey: string) => {
      if (quizzItem[headerKey]) {
        transformedData[iQuizz][headerKey] = quizzItem[headerKey];
      } else {
        transformedData[iQuizz][headerKey] = "";
      }
    });
  });
  return transformedData;
};

export const formatExamGrades = (exams: EXAM[]) => {
  const transformedData = {};
  const headerKeys = EXAM_HEADERS.map(
    (headerItem: GRADE_HEADER) => headerItem.key
  );

  exams.forEach((examItem: EXAM, iExam: number) => {
    transformedData[iExam] = {};

    headerKeys.forEach((headerKey: string) => {
      if (examItem[headerKey]) {
        transformedData[iExam][headerKey] = examItem[headerKey];
      } else {
        transformedData[iExam][headerKey] = "";
      }
    });
  });
  return transformedData;
};

export const getQuizFields = (headers: GRADE_HEADER[]) => {
  return Object.values(headers)
    .map((headerItem: GRADE_HEADER) => headerItem.key)
    .filter(
      (element: string) => element.includes("quiz") && element !== "quizAverage"
    );
};

export const customRound = (value: number) => {
  const decimalPart = value % 1; // Extracts the decimal part of the number

  if (decimalPart > 0.5) {
    return Math.ceil(value); // Round up
  } else {
    return Math.floor(value); // Round down
  }
};
