import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import { USER } from "utils/types/user";
import { OPTIONS } from "utils/types/general";
import { getUsers } from "utils/api_calls/user";

interface Props {
  onChange: any;
  data?: USER[];
  isMulti?: boolean;
  includeAll?: boolean;
  value?: OPTIONS | OPTIONS[];
  className?: string;
  hideTeachers?: boolean;
  hideStudents?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

function DdlUser(props: Props) {
  const {
    onChange,
    data = undefined,
    isMulti = false,
    includeAll = false,
    value = undefined,
    className = "",
    hideTeachers = false,
    hideStudents = false,
    placeholder = "",
    disabled = false
  } = props;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUsers = async () => {
    setLoading(true);
    const resUsers = await getUsers(
      hideStudents,
      hideTeachers,
      includeAll
    ).then((r) => r);

    const res = await Promise.all([resUsers]);

    setUsers(res[0]);
    setLoading(false);
  };

  const options = useMemo(() => {
    return users.map((user: USER) => {
      const label =
        user.role === "TUTOR" ? user.name : `${user.codeEleve} ${user.name}`;
      return {
        label,
        value: user.codeEleve
      };
    });
  }, [users]);

  useEffect(() => {
    if (!data) {
      fetchUsers();
    } else {
      setUsers(data);
    }
  }, [data]);

  const handleChange = useCallback(
    (e: any) => {
      if (isMulti) {
        const selectedItems = (e || []).map((item: any) => item.value);

        const resultingItems = users.filter((user: USER) =>
          selectedItems.includes(user.codeEleve)
        );
        onChange(resultingItems);
      } else {
        const resultingItem = users.find(
          (user: USER) => e.value === user.codeEleve
        );
        onChange(resultingItem);
      }
    },
    [users]
  );

  return (
    <Select
      closeMenuOnSelect={true}
      isMulti={isMulti}
      options={options}
      isLoading={loading}
      onChange={handleChange}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "1.5rem",
          border: "none",
          padding: ".5rem"
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 10
        })
      }}
      placeholder={
        placeholder
          ? placeholder
          : isMulti
            ? "Select users ..."
            : "Select a user ..."
      }
      value={value}
      className={className}
      isDisabled={disabled}
      isSearchable
    />
  );
}

export default DdlUser;
