import axios from "axios";
import { isAuth } from "utils/context";

const get = async (
  page: number,
  elementsPerPage?: number,
  all?: boolean,
  schoolYear?: string,
  codes?: string[]
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to get Attendances"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}attendances?${
      page ? `page=${page}` : ""
    }${elementsPerPage ? `&element=${elementsPerPage}` : ""}${all ? `&all=${String(all)}` : ""}`;

    try {
      const response = await axios.post(url, { schoolYear, codes });

      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const search = async (
  page: number,
  codeEleve: string,
  elementsPerPage?: number,
  all?: boolean,
  schoolYear?: string
) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search by code"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}attendances/search?${
      page ? `page=${page}` : ""
    }${elementsPerPage ? `&element=${elementsPerPage}` : ""}${all ? `&all=${String(all)}` : ""}`;

    try {
      const response = await axios.post(url, { codeEleve, schoolYear });

      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (attendance) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}attendance`;
    const response = await axios.post(url, attendance);

    return response.data.data;
  }
  return true;
};

const update = async (attendance) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}attendance/${attendance.id}`;
    const response = await axios.put(url, attendance);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}attendance/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

const getStats = async (filters: any) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to get Attendances stats"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}attendance/stats`;
    try {
      const response = await axios.post(url, filters);
      return response.data;
    } catch {
      return error;
    }
  }
  return error;
};

export { get, getStats, search, add, update, deletebyId };
