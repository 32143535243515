import React, { Dispatch, SetStateAction } from "react";

import {
  faExclamationTriangle,
  faPen,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "components/common/DataTable";
import { ACTIONS, PAGINATION } from "utils/types/general";
import { ACTION_BTNS } from "utils/styles";
import { SUBJECT } from "utils/types/subject";
import { SUBJECTS_TABLE_COLUMNS } from "utils/constants/subject";

interface Props {
  subjects: SUBJECT[];
  setSubjects: Dispatch<SetStateAction<SUBJECT[]>>;
  setSelectedItem: Dispatch<SetStateAction<SUBJECT>>;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  pagination?: PAGINATION;
  getData: CallableFunction;
}

const GRID_TEMPLATE = "grid grid-cols-1 md:grid-cols-[30%_55%_15%] border-b";
const CELL = "p-4";

function SubjectsTable(props: Props) {
  const {
    subjects,
    setSubjects,
    setSelectedItem,
    setAction,
    pagination,
    getData
  } = props;
  return (
    <DataTable
      data={subjects}
      setData={setSubjects}
      headers={SUBJECTS_TABLE_COLUMNS}
      gridStyle={`${GRID_TEMPLATE} hidden md:grid`}
      cellStyle={CELL}
      getData={getData}
      pagination={pagination}
    >
      {subjects.map((item: SUBJECT) => (
        <div key={item.id} className={`${GRID_TEMPLATE} py-2`}>
          <div className={`${CELL}`}>
            {item.label ? (
              <p>{item.label}</p>
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className='text-rose-500'
              />
            )}
          </div>
          <div className={`${CELL} gap-2`}>
            <p className='block md:hidden font-bold pb-4'>Teachers</p>
            <div className='flex flex-wrap gap-2'>
              {item.teachers &&
              item.teachers instanceof Array &&
              item.teachers.length > 0 ? (
                item.teachers.sort().map((l) => (
                  <p
                    key={Math.random()}
                    className='bg-slate-200 rounded-lg px-2'
                  >
                    {l.codeEleve}
                  </p>
                ))
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
          </div>
          <div
            className={`${CELL} gap-2 flex items-center justify-end order-first md:order-last`}
          >
            <button
              className={`${ACTION_BTNS} bg-amber-400 hover:bg-amber-200`}
              onClick={() => {
                setSelectedItem(item);
                setAction("edit");
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              className={`${ACTION_BTNS} bg-rose-500 hover:bg-rose-200 text-white`}
              onClick={() => {
                setSelectedItem(item);
                setAction("delete");
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
      ))}
    </DataTable>
  );
}

export default SubjectsTable;
