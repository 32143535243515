import React from "react";
import Card from "components/common/Card";
import { CARD_TITLE } from "utils/styles";

const SchoolYearSchedule = () => {
  return (
    <Card images={[{ src: "/images/school2.jpg", alt: "school schedule" }]}>
      <p className={`${CARD_TITLE} pb-4`}>School year schedule</p>
      <hr />
      <p className='py-4'>For Preschoolers & Elementary students:</p>
      <ul className='list-disc px-6'>
        <li className='pb-2'>
          8:30 AM to 3:00 PM (from Monday to Thursday; until 12:30 PM every
          Wednesday if students don"t have extra-curricular activities)
        </li>
        <li>8:30 AM to 12:00 PM (every Friday)</li>
      </ul>

      <p className='pt-8 pb-4'>For Middle School students:</p>

      <ul className='list-disc px-6 mb-4'>
        <li>
          8:00 AM to 3:00 PM (from Monday to Thursday; no extra-curricular
          activities for Middle School students)
        </li>
      </ul>

      <a
        href='/ESA-2023-2024-Calendar.pdf'
        target='_blank'
        download={true}
        className='text-blue-400 underline'
        rel='noreferrer'
      >
        2023-2024 ESA Calendar
      </a>
    </Card>
  );
};

export default SchoolYearSchedule;
