import React, { useMemo } from "react";
import Carousel from "react-multi-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Image } from "utils/types/general";
import { CAROUSEL_CARDS } from "utils/styles";

interface Props extends React.PropsWithChildren {
  images?: Image[];
}

const Card = (props: Props) => {
  const { images, children } = props;

  const ImageWrapper = useMemo(() => {
    if (images) {
      if (images.length === 1) {
        return (
          <LazyLoadImage
            key={images[0].src}
            src={images[0].src}
            alt={images[0].alt}
            className='w-full object-cover h-60'
          />
        );
      }
      return (
        <Carousel
          swipeable={false}
          draggable={false}
          autoPlay
          infinite
          responsive={CAROUSEL_CARDS}
          containerClass='h-full w-full z-0'
        >
          {images.map((image: Image) => (
            <LazyLoadImage
              key={image.src}
              src={image.src}
              alt={image.alt}
              className='w-full object-cover h-60'
            />
          ))}
        </Carousel>
      );
    }
    return null;
  }, [images]);

  return (
    <div className='rounded-lg shadow-lg overflow-hidden bg-white'>
      {images && (
        <div className='card-image h-60 w-full overflow-hidden'>
          {ImageWrapper}
        </div>
      )}

      <div className='card-content p-4'>{children}</div>
    </div>
  );
};

export default Card;
