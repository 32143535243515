import React, { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Loader from "components/common/Loader";

import { StudentsAPI } from "api";

import {
  ERROR_STYLE,
  FORM_GROUP_STYLE,
  INPUT_STYLE,
  SUBMIT_STYLE
} from "utils/styles";
import { ACTIONS } from "utils/types/general";
import { USER } from "utils/types/user";
import { getArrayOfValues, getDefaultLevelOptions, lpad } from "utils/format";
import { useUser } from "context/user.context";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import DdlLevel from "components/dropdowns/DdlLevel";

interface Props {
  data?: USER;
  action: ACTIONS;
  refetch: any;
  closeModal: any;
}

function StudentForm(props: Props) {
  const { ctxUser } = useUser();
  const { data, action, refetch, closeModal } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    trigger,
    formState: { errors, isSubmitted, isSubmitting }
  } = useForm<USER>({
    mode: "onChange",
    defaultValues: {
      ...data,
      password: data ? data.password : "password",
      active: data ? data.active : true
    }
  });

  const addUpdate = useCallback(
    (dataToSend: any) => {
      if (dataToSend.id || action === "edit") {
        StudentsAPI.update(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      } else {
        StudentsAPI.add(dataToSend)
          .then(() => {
            setLoading(false);
          })
          .finally(() => {
            refetch(1);
            closeModal();
          });
      }
    },
    [action]
  );

  const generateCodeEleve = useCallback(async () => {
    const val = watch("name") || "";

    if (
      val.replaceAll(" ", "").length === 0 ||
      val.replaceAll(" ", "").length < 3
    ) {
      trigger(); // triggers validation but does not submit the form
      toast.error(
        <div className='text-rose-600'>
          <p className='flex flex-row items-start pb-4'>
            <FontAwesomeIcon icon={faCaretRight} />
            <span className='ml-3 flex -mt-1'>Name is required</span>
          </p>
          <p className='flex flex-row items-start'>
            <FontAwesomeIcon icon={faCaretRight} />
            <span className='ml-3 flex -mt-1'>
              Name is must be at least 3 characters
            </span>
          </p>
        </div>,
        {
          autoClose: false, // Prevents the toast from closing automatically
          className: "min-w-64", // Apply custom CSS class
          position: "bottom-right"
        }
      );
      return false;
    } else {
      setLoading(true);
      clearErrors(["name", "codeEleve"]);

      const temp = val.split(" ");

      const allCodes = await StudentsAPI.get(null, null, true, null, null).then(
        (r: any) => {
          return r.data.map((a) => a.codeEleve);
        }
      );

      let strCode =
        temp.length > 1
          ? `${temp[0].substring(0, 2)}${temp[1].substring(0, 2)}`
          : temp[0].substring(0, 4);

      const existingCodes = allCodes
        .filter((a: any) => a.startsWith(strCode.toUpperCase()))
        .map((b: any) => Number(b.substring(4)))
        .sort((c, d) => c - d);

      const lastIndex = lpad(
        existingCodes.length > 0
          ? existingCodes[existingCodes.length - 1] + 1
          : 1,
        3
      );
      strCode = `${strCode}${lastIndex}`.toUpperCase();
      setValue("codeEleve", strCode.toUpperCase());

      if (
        [null, undefined, ""].includes(watch("username")) ||
        action === "add"
      ) {
        setValue("username", strCode.toUpperCase());
      }
      trigger();
      setLoading(false);
    }
  }, [action]);

  const onSubmit: SubmitHandler<USER> = async (data) => {
    setLoading(true);
    addUpdate({ ...data, files: [] });
  };

  useEffect(() => {
    if (action === "edit") {
      trigger(); // triggers validation but does not submit the form
    }
  }, [action]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
        <div>
          <div id='active_generate'>
            <div className='flex items-end justify-between'>
              <div id='active'>
                <p className='font-bold pb-2'>Active</p>
                <div
                  className={`mb-4 ${
                    isSubmitted || errors.active
                      ? errors.active
                        ? "border-rose-600"
                        : "border-lime-500"
                      : ""
                  }`}
                >
                  <input
                    disabled={isSubmitting}
                    className='mr-2'
                    type='checkbox'
                    {...register("active", { required: true })}
                  />
                  <span>
                    {watch("active") && watch("active") === true
                      ? "Active"
                      : "Inactive"}
                  </span>
                </div>
              </div>
              <div id='generate'>
                {isSubmitting || loading ? (
                  <div className='flex items-end justify-end mb-2'>
                    <div
                      className={`${SUBMIT_STYLE} bg-gray-100 min-w-52 ml-auto mr-0`}
                    >
                      <Loader width='w-6' height='h-6' text='text-gray-300' />
                    </div>
                  </div>
                ) : (
                  (action === "add" || watch("codeEleve") === "") && (
                    <div className='flex items-end justify-end mb-2'>
                      <button
                        type='button'
                        className={`${SUBMIT_STYLE} bg-gray-100 min-w-52 ml-auto mr-0`}
                        onClick={() => generateCodeEleve()}
                      >
                        Generate student code
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div id='codeEleve'>
            <p className='font-bold pb-2'>Code</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.codeEleve
                  ? errors.codeEleve
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled
                className={`${INPUT_STYLE} bg-slate-100`}
                placeholder='Code élève'
                type='text'
                {...register("codeEleve", {
                  required: "Please provide a name and generate a student code",
                  validate: (value: string) =>
                    value !== "" || "This field cannot be empty"
                })}
              />
              {(isSubmitted || errors.codeEleve) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.codeEleve ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.codeEleve && (
              <span className={ERROR_STYLE}>{errors.codeEleve.message}</span>
            )}
          </div>

          <div id='name'>
            <p className='font-bold pb-2'>Name</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.name
                  ? errors.name
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting}
                className={INPUT_STYLE}
                placeholder='Name'
                type='text'
                {...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 3,
                    message: "Name is too short"
                  }
                })}
              />
              {(isSubmitted || errors.name) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.name ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.name && (
              <span className={ERROR_STYLE}>{errors.name.message}</span>
            )}
          </div>
        </div>

        <div className='p-4 bg-slate-100 my-4 rounded-xl'>
          <div id='username'>
            <p className='font-bold pb-2'>Username</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.username
                  ? errors.username
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting || (ctxUser && ctxUser.role !== "ADMIN")}
                className={INPUT_STYLE}
                placeholder='Username'
                type='text'
                {...register("username", {
                  required:
                    ctxUser && ctxUser.role !== "ADMIN"
                      ? "Fill student name and generate code to get a username (* required)"
                      : "Username is required"
                })}
              />
              {(isSubmitted || errors.username) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.username ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.username && (
              <span className={ERROR_STYLE}>{errors.username.message}</span>
            )}
          </div>

          <div id='password'>
            <p className='font-bold pb-2'>Password</p>
            <div
              className={`${FORM_GROUP_STYLE} ${
                isSubmitted || errors.password
                  ? errors.password
                    ? "border-rose-600"
                    : "border-lime-500"
                  : ""
              }`}
            >
              <input
                disabled={isSubmitting || (ctxUser && ctxUser.role !== "ADMIN")}
                className={INPUT_STYLE}
                placeholder='Password'
                type='text'
                {...register("password", { required: true })}
              />
              {(isSubmitted || errors.password) && (
                <div
                  className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                    errors.password ? "bg-rose-600" : "bg-lime-500"
                  }`}
                ></div>
              )}
            </div>
            {errors.password && (
              <span className={ERROR_STYLE}>Password is required</span>
            )}
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
        <div id='dob'>
          <p className='font-bold pb-2'>Date of birth</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.dob
                ? errors.dob
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              disabled={isSubmitting}
              className={INPUT_STYLE}
              placeholder='Date of birth'
              type='date'
              {...register("dob", {
                required: "Please provide a date of birth"
              })}
            />
            {(isSubmitted || errors.dob) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.dob ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.dob && (
            <span className={ERROR_STYLE}>{errors.dob.message}</span>
          )}
        </div>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
        <div id='address'>
          <p className='font-bold pb-2'>Address</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.address
                ? errors.address
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <input
              disabled={isSubmitting}
              className={INPUT_STYLE}
              placeholder='Address'
              type='text'
              {...register("address", {
                required: "Please provide an address"
              })}
            />
            {(isSubmitted || errors.address) && (
              <div
                className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                  errors.address ? "bg-rose-600" : "bg-lime-500"
                }`}
              ></div>
            )}
          </div>
          {errors.address && (
            <span className={ERROR_STYLE}>{errors.address.message}</span>
          )}
        </div>

        <div id='Level'>
          <p className='font-bold pb-2'>Level</p>
          <div
            className={`${FORM_GROUP_STYLE} ${
              isSubmitted || errors.level
                ? errors.level
                  ? "border-rose-600"
                  : "border-lime-500"
                : ""
            }`}
          >
            <Controller
              control={control}
              name='level'
              rules={{ required: "Please provide a level" }}
              render={({ field: { onChange, value } }) => (
                <DdlLevel
                  onChange={(e) => {
                    onChange(getArrayOfValues(e));
                  }}
                  value={value ? getDefaultLevelOptions(value) : undefined}
                />
              )}
            />
          </div>
          {errors.level && (
            <span className={ERROR_STYLE}>{(errors as any).level.message}</span>
          )}
        </div>
      </div>
      <div id='parents' className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
        {["dad", "mom"].map((parentKey: string) => {
          return (
            <div
              id={parentKey}
              key={parentKey}
              className='px-3 py-4 bg-slate-100 shadow-md rounded-lg'
            >
              {["name", "email", "tel"].map((fieldKey: string) => (
                <div
                  id={`${parentKey}.${fieldKey}`}
                  key={`${parentKey}.${fieldKey}`}
                >
                  <p className='font-bold pb-2 capitalize'>{`${parentKey} ${fieldKey}`}</p>
                  <div
                    className={`${FORM_GROUP_STYLE} ${
                      isSubmitted || errors?.[parentKey]?.[fieldKey]
                        ? errors?.[parentKey]?.[fieldKey]
                          ? "border-rose-600"
                          : "border-lime-500"
                        : ""
                    }`}
                  >
                    <input
                      disabled={isSubmitting}
                      className={INPUT_STYLE}
                      placeholder={`${parentKey} ${fieldKey}`}
                      type='text'
                      {...register(`${parentKey}.${fieldKey}` as any, {
                        required: {
                          value: true,
                          message: "Required"
                        },
                        minLength: {
                          value: 3,
                          message: `${fieldKey} is too short`
                        }
                      })}
                    />
                    {(isSubmitted || errors?.[parentKey]?.[fieldKey]) && (
                      <div
                        className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                          errors?.[parentKey]?.[fieldKey]
                            ? "bg-rose-600"
                            : "bg-lime-500"
                        }`}
                      ></div>
                    )}
                  </div>
                  {errors?.[parentKey]?.[fieldKey] && (
                    <span className={ERROR_STYLE}>
                      {errors?.[parentKey]?.[fieldKey].message}
                    </span>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>

      <div
        id='emergencyContact'
        className='grid gridl-cols-1 lg:grid-cols-2 gap-4 my-8'
      >
        {["0", "1"].map((_: string, idxKey: number) => {
          return (
            <div
              id={`emergencyContact[${idxKey}]`}
              key={`emergencyContact[${idxKey}]`}
              className='px-3 py-4 bg-slate-100 shadow-md rounded-lg'
            >
              <p className='font-bold pb-2 capitalize mb-4'>{`Emergency contact ${idxKey + 1}`}</p>
              {["name", "email", "phone"].map((fieldKey: string) => (
                <div
                  id={`emergencyContact[${idxKey}].${fieldKey}`}
                  key={`emergencyContact[${idxKey}].${fieldKey}`}
                >
                  <p className='font-bold pb-2 capitalize'>{fieldKey}</p>
                  <div
                    className={`${FORM_GROUP_STYLE} ${
                      isSubmitted ||
                      errors?.[`emergencyContact[${idxKey}]`]?.[fieldKey]
                        ? errors?.[`emergencyContact[${idxKey}]`]?.[fieldKey]
                          ? "border-rose-600"
                          : "border-lime-500"
                        : ""
                    }`}
                  >
                    <input
                      disabled={isSubmitting}
                      className={INPUT_STYLE}
                      placeholder={`Emergency ${idxKey + 1} ${fieldKey}`}
                      type='text'
                      {...register(
                        `emergencyContact[${idxKey}].${fieldKey}` as any,
                        {
                          required: false,
                          minLength: {
                            value: 3,
                            message: `${fieldKey} is too short`
                          }
                        }
                      )}
                    />
                    {(isSubmitted ||
                      errors?.[`emergencyContact[${idxKey}]`]?.[fieldKey]) && (
                      <div
                        className={`block absolute rounded-3xl top-4 right-4 w-2 h-2 ${
                          errors?.[`emergencyContact[${idxKey}]`]?.[fieldKey]
                            ? "bg-rose-600"
                            : "bg-lime-500"
                        }`}
                      ></div>
                    )}
                  </div>
                  {errors?.[`emergencyContact[${idxKey}]`]?.[fieldKey] && (
                    <span className={ERROR_STYLE}>
                      {errors?.emergencyContact[idxKey]?.[fieldKey].message}
                    </span>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>

      {isSubmitting || loading ? (
        <div className={`${SUBMIT_STYLE} bg-gray-100`}>
          <Loader width='w-6' height='h-6' text='text-gray-300' />
        </div>
      ) : (
        <input
          type='submit'
          disabled={isSubmitting}
          value={action === "add" ? "Create student" : "Update student"}
          className={`${SUBMIT_STYLE} bg-blue-200`}
        />
      )}
    </form>
  );
}

export default StudentForm;
