import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "components/common/Loader";
import OtherPrices from "components/prices/OtherPrices";
import TuitionFees from "components/prices/TuitionFees";

import { PriceAPIv2 } from "api";
import { useUser } from "context/user.context";

import { getCurrentSchoolYear } from "utils/dates";
import {
  BG_COLORED,
  CONTAINER,
  CONTAINER_PADDING,
  SUBMIT_STYLE
} from "utils/styles";
import { PRICE_DETAIL, PRICE } from "utils/types/prices";
import { generateEmptyPrice } from "utils/format/prices";

function Page() {
  const { ctxUser, isAuth } = useUser();
  const navigate = useNavigate();
  const [displayContent, setDisplayContent] = useState<string>("");
  const [loading, setLoading] = useState<string>("");
  const [price, setPrice] = useState<PRICE>();
  const [otherPrices, setOtherPrices] = useState<PRICE_DETAIL[]>([]);

  const getPrices = useCallback((schoolYear: string) => {
    setLoading("fetch");
    PriceAPIv2.getPricesV2({
      school_year: schoolYear,
      category: "Ecolage"
    }).then((r: any) => {
      if (r.data && r.data.length > 0) {
        setPrice(r.data[0]);
      } else {
        setPrice(generateEmptyPrice(schoolYear));
      }
      setLoading("");
    });
  }, []);

  const getOherPrices = useCallback(() => {
    setLoading("fetch");
    PriceAPIv2.getPricesV2({ category: "Other" }).then((r: any) => {
      const res = r.data || [];
      setOtherPrices(res);
      setLoading("");
    });
  }, []);

  useEffect(() => {
    if (isAuth) {
      if (ctxUser && !["ADMIN", "SECRETARIAT"].includes(ctxUser.role)) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, [ctxUser, isAuth]);

  return (
    <div id='prices-page' className={`${BG_COLORED} py-8`}>
      <section
        id='prices-header'
        className={`${CONTAINER} ${CONTAINER_PADDING} pb-0`}
      >
        <div className='flex justify-between'>
          <div className='flex gap-4 justify-start'>
            <button
              disabled={loading !== ""}
              className={`${SUBMIT_STYLE} ml-0 mr-0 bg-esaMintyDark hover:bg-esaMinty text-white ${displayContent === "tuition-fees" ? "opacity-100 scale-110" : "opacity-50 scale-95"}`}
              onClick={() => {
                const activateTuitions = displayContent !== "tuition-fees";
                setDisplayContent(activateTuitions ? "tuition-fees" : "");
                if (activateTuitions) {
                  getPrices(getCurrentSchoolYear());
                }
              }}
            >
              Tuition fees
            </button>
            <button
              disabled={loading !== ""}
              className={`${SUBMIT_STYLE} ml-0 mr-0 bg-blue-400 hover:bg-blue-300 text-white ${displayContent === "other-prices" ? "opacity-100 scale-110" : "opacity-50 scale-95"}`}
              onClick={() => {
                const activateOthers = displayContent !== "other-prices";
                setDisplayContent(
                  displayContent === "other-prices" ? "" : "other-prices"
                );
                if (activateOthers) {
                  getOherPrices();
                }
              }}
            >
              Other prices
            </button>
          </div>
          <p className='font-bold'>
            {displayContent === "tuition-fees"
              ? "Tuition fees"
              : displayContent === "other-prices"
                ? "Other prices"
                : ""}
          </p>
        </div>
      </section>

      <section
        id='prices-content'
        className={`${CONTAINER} ${CONTAINER_PADDING} pb-0`}
      >
        {displayContent === "tuition-fees" &&
          (loading !== "" ? (
            <Loader />
          ) : (
            <TuitionFees
              data={price}
              getPrices={getPrices}
              loading={loading}
              setLoading={setLoading}
            />
          ))}
        {displayContent === "other-prices" && (
          <OtherPrices
            data={otherPrices}
            getData={getOherPrices}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </section>
    </div>
  );
}

export default Page;
