import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loader from "components/common/Loader";
import Modal from "components/common/Modal";

import { useUser } from "context/user.context";
import { NewsAPI } from "api";

import { FILE_UPLOADED } from "utils/types/general";
import { NEWS } from "utils/types/news";

interface Props {
  roles: string[];
}

function NewsList(props: Props) {
  const { roles } = props;
  const { ctxUser } = useUser();
  const [news, setNews] = useState<NEWS[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<NEWS>();

  const getNews = useCallback(
    (page: number) => {
      setLoading(true);
      NewsAPI.getNews(page, undefined, true)
        .then((r: any) => {
          const res = roles.includes("ADMIN")
            ? r.data
            : r.data
                .map((item: NEWS) => {
                  const targetArray = item.target;

                  const foundItem = targetArray.find((target: any) =>
                    roles.includes(target.codeEleve)
                  );
                  return foundItem ? item : undefined;
                })
                .filter((a: any) => a);

          res.sort((a, b) =>
            moment(a.lastUpdated, "YYYY-MM-DD HH:mm") >
            moment(b.lastUpdated, "YYYY-MM-DD HH:mm")
              ? -1
              : 1
          );
          setNews(res);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [roles]
  );

  const handleMarkAsRead = useCallback(
    (a: NEWS) => {
      setLoading(true);
      NewsAPI.markAsRead(a, ctxUser.codeEleve)
        .then(() => {
          setSelectedItem(undefined);
        })
        .finally(() => setLoading(false));
    },
    [ctxUser]
  );

  useEffect(() => {
    getNews(1);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        {news.map((item: NEWS) => (
          <article key={item.id} className='bg-white rounded-lg shadow-lg p-4'>
            <div className='min-h-16'>
              <p className='font-bold'>{item.title}</p>
              <p className='text-slate-400 text-sm'>
                {moment(item.lastUpdated, "YYYY-MM-DD HH:mm").format(
                  "DD MMMM YYYY HH:mm"
                )}
              </p>
            </div>

            {ctxUser.role !== "ADMIN" && (
              <>
                {!(item.views || []).includes(ctxUser.codeEleve) && (
                  <div className='text-rose-500 pt-4 pl-2'>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span className='ml-2'>
                      You did not read the information yet
                    </span>
                  </div>
                )}
              </>
            )}

            <button
              className='text-blue-400 pt-4'
              onClick={() => setSelectedItem(item)}
            >
              <span className='underline ml-2'>Read more</span>
            </button>
          </article>
        ))}
      </div>
      {selectedItem && (
        <Modal
          isOpen={true}
          onClose={() => {
            if (
              !selectedItem.views.includes(ctxUser.codeEleve) &&
              ctxUser.role !== "ADMIN"
            ) {
              handleMarkAsRead(selectedItem);
              getNews(1);
            } else {
              setSelectedItem(undefined);
            }
          }}
          title={selectedItem.title}
          className='md:max-w-[700px] lg:max-w-[90%]'
        >
          <p className='text-slate-400 text-sm pb-4'>
            {`Published on: ${moment(
              selectedItem.lastUpdated,
              "YYYY-MM-DD HH:mm"
            ).format("DD MMMM YYYY HH:mm")}`}
          </p>
          <div dangerouslySetInnerHTML={{ __html: selectedItem.description }} />
          {selectedItem.files.length > 0 && (
            <div className='grid'>
              <p className='font-bold pt-6'>Files</p>
              {selectedItem.files.map((file: FILE_UPLOADED) => (
                <a
                  key={file.url}
                  href={file.url}
                  target='_blank'
                  rel='noreferrer'
                  className='flex flex-row items-center justify-between p-2 hover:bg-slate-50'
                >
                  <span>{file.name}</span>
                  <span className='bg-amber-400 px-3 py-1 text-white rounded-lg'>
                    {file.size / 1000} KB
                  </span>
                </a>
              ))}
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export default NewsList;
