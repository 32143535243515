import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { countErrors, validateFormValues } from "utils/constants/term";
import { TERM, WEEK } from "utils/types/term";
import _ from "lodash";

interface Props {
  term: TERM;
}

export interface TermCollapsibleRef {
  submit: () => void;
}

const TermCollapsible = forwardRef<TermCollapsibleRef, Props>((props, ref) => {
  const { term } = props;

  const {
    register,
    unregister,
    handleSubmit,
    trigger,
    watch,
    setError,
    setValue,
    formState: { errors }
  } = useForm<TERM>({
    mode: "onChange",
    defaultValues: term
  });

  const [showContent, setShowContent] = useState<boolean>(false);

  const INPUT_CLASS =
    "focus:shadow-none focus-visible:shadow-none focus-visible:outline-none py-2 px-4 w-full rounded-3xl border";
  const ERROR_CLASS = "px-4 mt-4 text-rose-600 text-sm";

  const onSubmit: SubmitHandler<TERM> = async (data) => {
    return data;
  };

  // Use useImperativeHandle to expose the submit method to the parent component
  useImperativeHandle(ref, () => ({
    submit: async () => {
      setShowContent(true);

      await trigger();
      const values = watch();
      const errorMessages = validateFormValues(values);
      const isValid = Object.keys(errorMessages).length === 0;

      if (isValid) {
        setShowContent(false);
        return onSubmit(values);
      } else {
        for (const [key, value] of Object.entries(errorMessages)) {
          setError(key as keyof TERM, {
            type: "manual",
            message: value
          });
        }
        setShowContent(false);
        return undefined;
      }
    }
  }));

  const [weeks, setWeeks] = useState(term.weeks); // Store weeks in local state

  const handleRemoveWeek = (iWeek: number) => {
    // Unregister fields for the specific week
    unregister(`weeks.${iWeek}.start`);
    unregister(`weeks.${iWeek}.end`);

    // Remove the week from the local weeks array
    const updatedWeeks = weeks.filter((_, index) => index !== iWeek);

    // Update the form state and the local state
    setValue("weeks", updatedWeeks);
    setWeeks(updatedWeeks);

    // Trigger validation for the remaining weeks
    const errorMessages = validateFormValues(watch());
    for (const [key, value] of Object.entries(errorMessages)) {
      setError(key as keyof TERM, {
        type: "manual",
        message: value
      });
    }
  };

  const handleAddWeek = () => {
    // Get the current weeks from form state or the local state
    const currentWeeks = watch("weeks") || [];

    // Generate a new week object, assuming that the new week is one number after the last week's number
    const newWeekNb =
      currentWeeks.length > 0
        ? (
            parseInt(currentWeeks[currentWeeks.length - 1].weekNb, 10) + 1
          ).toString()
        : "1"; // Start from week 1 if no weeks are present

    const newWeek: WEEK = {
      weekNb: newWeekNb,
      start: "",
      end: "",
      files: [] // Add any other properties that you want in the new week
    };

    // Update the weeks array with the new week
    const updatedWeeks = [...currentWeeks, newWeek];

    // Update the form state and local state with the new weeks array
    setValue("weeks", updatedWeeks);
    setWeeks(updatedWeeks);

    // You may want to trigger validation for the updated weeks list
    const errorMessages = validateFormValues(watch());
    for (const [key, value] of Object.entries(errorMessages)) {
      setError(key as keyof TERM, {
        type: "manual",
        message: value
      });
    }
  };

  return (
    <div className='w-full'>
      {watch() && !_.isEqual(watch(), term) && (
        <p className='text-rose-400'>
          Some changes were made to Term {`${term.termNb}`}. Make sure to save
          before leaving this page
        </p>
      )}
      <button
        type='button'
        className='w-full bg-esaBlue text-white rounded-lg my-4 text-left px-4 py-2'
        onClick={() => setShowContent(!showContent)}
      >
        <span>{`Term ${term.termNb}`}</span>
        {countErrors(errors, term) > 0 && (
          <span className='ml-4 bg-rose-600 px-2 py-1 text-sm rounded-lg'>{`${countErrors(errors, term)} errors`}</span>
        )}
      </button>

      {showContent && (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
          <div className='bg-white p-4'>
            <div
              id='term-start-end'
              className='grid grid-cols-1 md:grid-cols-3 p-4 border border-slate-300 border-t-0 border-l-0 border-r-0'
            >
              <p />
              <div className='pb-4 pr-0 md:pr-4'>
                <p>Start</p>
                <input
                  type='date'
                  className={INPUT_CLASS}
                  {...register("start", {
                    required: "Term start date is required",
                    validate: (value) =>
                      value !== "" || "Term start date cannot be empty"
                  })}
                />
                {errors.start && (
                  <span className={ERROR_CLASS}>{errors.start.message}</span>
                )}
              </div>
              <div className='pb-4 pr-0 md:pr-4'>
                <p>End</p>
                <input
                  type='date'
                  className={INPUT_CLASS}
                  {...register("end", {
                    required: "Term end date is required",
                    validate: (value) =>
                      value !== "" || "Term end date cannot be empty"
                  })}
                />
                {errors.end && (
                  <span className={ERROR_CLASS}>{errors.end.message}</span>
                )}
              </div>
            </div>

            {watch("weeks") &&
              watch("weeks")
                .sort((a: WEEK, b: WEEK) => {
                  const weekNbA = parseInt(a.weekNb, 10);
                  const weekNbB = parseInt(b.weekNb, 10);
                  return weekNbA - weekNbB;
                })
                .map((week: WEEK, iWeek: number) => (
                  <div
                    key={`term-${term.termNb}-week${week.weekNb}`}
                    className='grid grid-cols-1 md:grid-cols-3 bg-slate-100 p-4 border border-slate-300 border-t-0 border-l-0 border-r-0'
                  >
                    <div className='flex flex-col justify-start mb-4'>
                      <p className='md:mb-0 font-semibold'>{`Week ${week.weekNb}`}</p>
                      {iWeek > 0 && iWeek === watch("weeks").length - 1 && (
                        <button
                          type='button'
                          className='w-max bg-rose-400 px-2 py-1 rounded-lg'
                          onClick={() => handleRemoveWeek(iWeek)}
                        >
                          <FontAwesomeIcon icon={faTrash} color='white' />
                        </button>
                      )}
                    </div>
                    <div className='pb-4 pr-0 md:pr-4'>
                      <p>Start</p>
                      <input
                        type='date'
                        className={INPUT_CLASS}
                        {...register(`weeks.${iWeek}.start`, {
                          required: "Week start date is required",
                          validate: (value) =>
                            value !== "" || "Week start date cannot be empty"
                        })}
                      />
                      {errors["weeks"] &&
                        errors["weeks"][iWeek] &&
                        errors["weeks"][iWeek].start &&
                        errors["weeks"][iWeek].start.message && (
                          <span className={ERROR_CLASS}>
                            {errors["weeks"][iWeek].start.message}
                          </span>
                        )}
                    </div>
                    <div className='pb-4 pr-0 md:pr-4'>
                      <p>End</p>
                      <input
                        type='date'
                        className={INPUT_CLASS}
                        {...register(`weeks.${iWeek}.end`, {
                          required: "Week end date is required",
                          validate: (value) =>
                            value !== "" || "Week end date cannot be empty"
                        })}
                      />
                      {errors["weeks"] &&
                        errors["weeks"][iWeek] &&
                        errors["weeks"][iWeek].end &&
                        errors["weeks"][iWeek].end.message && (
                          <span className={ERROR_CLASS}>
                            {errors["weeks"][iWeek].end.message}
                          </span>
                        )}
                    </div>
                  </div>
                ))}

            <button
              type='button'
              className='bg-esaGreen p-4 rounded-lg text-white mt-4 float-right'
              onClick={handleAddWeek}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className='ml-2'>{`Add a week to Term ${term.termNb}`}</span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
});

// Set displayName for easier debugging
TermCollapsible.displayName = "TermCollapsible";

export default TermCollapsible;
