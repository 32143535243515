import React, { Dispatch, SetStateAction } from "react";

import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "components/common/DataTable";

import { DASHBOARD_ADMIN_COLUMNS } from "utils/constants/dashboard";
import { ACTIONS, PAGINATION } from "utils/types/general";
import { NEWS } from "utils/types/news";
import { USER } from "utils/types/user";
import moment from "moment";
import { ACTION_BTNS } from "utils/styles";

interface Props {
  news: NEWS[];
  setNews: Dispatch<SetStateAction<NEWS[]>>;
  setSelectedItem: Dispatch<SetStateAction<NEWS>>;
  setAction: Dispatch<SetStateAction<ACTIONS>>;
  pagination?: PAGINATION;
  getData: CallableFunction;
}

const GRID_TEMPLATE =
  "grid grid-cols-1 md:grid-cols-[30%_15%_40%_15%] border-b";
const CELL = "p-4";

function NewsTable(props: Props) {
  const { news, setNews, setSelectedItem, setAction, pagination, getData } =
    props;
  return (
    <DataTable
      data={news}
      setData={setNews}
      headers={DASHBOARD_ADMIN_COLUMNS}
      gridStyle={`${GRID_TEMPLATE} hidden md:grid`}
      cellStyle={CELL}
      getData={getData}
      pagination={pagination}
    >
      {news.map((item: NEWS) => (
        <div key={item.id} className={`${GRID_TEMPLATE} py-2`}>
          <div className={`${CELL}`}>
            <p className='font-bold'>{item.title}</p>
            <p className='text-slate-400 text-sm'>
              {moment(item.lastUpdated, "YYYY-MM-DD HH:mm").format(
                "DD MMMM YYYY HH:mm"
              )}
            </p>
          </div>
          <div className={`${CELL} gap-2`}>
            <p className='block md:hidden font-bold pb-4'>Target</p>
            <div className='flex flex-wrap gap-2'>
              {item.target.map((target: Partial<USER>) => (
                <span
                  key={target.codeEleve}
                  className='bg-slate-100 px-4 rounded-lg py-1.5'
                >
                  {target.codeEleve}
                </span>
              ))}
            </div>
          </div>
          <div className={CELL}>
            <p className='block md:hidden font-bold pb-4'>Viewd by</p>
            <div className='flex flex-wrap gap-2'>
              {item.views.map((codeEleve: string, iView: number) => (
                <span
                  key={`${codeEleve}${iView}`}
                  className='bg-slate-100 px-4 rounded-lg py-1.5'
                >
                  {codeEleve}
                </span>
              ))}
            </div>
          </div>
          <div
            className={`${CELL} gap-2 flex items-center justify-end order-first md:order-last`}
          >
            <button
              className={`${ACTION_BTNS} bg-amber-400 hover:bg-amber-200`}
              onClick={() => {
                setSelectedItem(item);
                setAction("edit");
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              className={`${ACTION_BTNS} bg-rose-500 hover:bg-rose-200 text-white`}
              onClick={() => {
                setSelectedItem(item);
                setAction("delete");
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
      ))}
    </DataTable>
  );
}

export default NewsTable;
