/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";

import { FileUpload } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFileAlt,
  faSave,
  faTrash,
  faTrashAlt,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
// import { ProgressBar } from 'primereact/progressbar';

export interface Props {
  onSubmit: (e: any) => void;
  data: any;
  autoSubmit: boolean;
}

const FileDropZone = ({ onSubmit, data, autoSubmit }: Props) => {
  const fileUploadRef = useRef(null);

  const [totalSize, setTotalSize] = useState(0);
  const [existingFiles, setExistingFiles] = useState<any>(data);
  const [removedFiles, setRemovedFiles] = useState<any>([]);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [disableClear, setDisableClear] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleSubmit = () => {
    // let temp = 0;
    // [...e.files].forEach((file) => { temp += file.size; return temp; });
    // setTotalSize(temp);

    onSubmit({
      uploadedFiles: uploadedFiles || [],
      existingFiles,
      removedFiles
    });
    toast.success("Files saves successfully", { position: "bottom-right" });
  };

  const onTemplateSelect = (e) => {
    // let temp = totalSize;
    // [...e.files].forEach((file) => { temp += file.size; return temp; });
    // setTotalSize(temp);
    const temp = [...uploadedFiles];
    [...e.files].forEach((file) => temp.push(file));
    setDisableSave(false);

    setUploadedFiles(temp);

    if (autoSubmit) {
      onSubmit({
        uploadedFiles: temp.length > 0 ? temp : [],
        existingFiles,
        removedFiles
      });
    }
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
    const files = [...uploadedFiles].filter((a: File) => a.name !== file.name);

    if (autoSubmit) {
      onSubmit({
        uploadedFiles: files.length > 0 ? files : [],
        existingFiles,
        removedFiles
      });
    }
  };

  const onTemplateClear = () => {
    setTotalSize(0);
    setExistingFiles([]);
    setRemovedFiles([]);
    setDisableSave(false);
    setDisableClear(true);

    if (autoSubmit) {
      onSubmit({
        uploadedFiles: [],
        existingFiles: [],
        removedFiles: []
      });
    }
  };

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;
    // const value = totalSize / 10000;
    // const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div
        className={`${className} file-upload-header`}
        style={{ backgroundColor: "transparent" }}
      >
        <div className='flex flex-wrap'>
          {chooseButton}
          {!autoSubmit && (
            <button
              type='button'
              className='w-full md:w-max h-12 text-white bg-esaGreen rounded-lg px-6 mb-2 my-4 md:my-0 ml-0 md:ml-2 flex justify-between items-center'
              onClick={handleSubmit}
              disabled={disableSave}
            >
              <FontAwesomeIcon icon={faSave} />
              <span className='ml-4 w-full text-center'>Save files</span>
            </button>
          )}
          <button
            type='button'
            className='w-full md:w-max h-12 text-white bg-esaRed min-h-12 rounded-lg px-6 mb-2 my-4 md:my-0 ml-0 md:ml-2 flex justify-between items-center'
            onClick={onTemplateClear}
            disabled={disableClear}
          >
            <FontAwesomeIcon icon={faClose} />
            <span className='ml-4 w-full text-center'>Clear</span>
          </button>
        </div>
        {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} /> */}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className='flex items-center justify-between py-2 px-0 border-b'>
        <p>{file.name}</p>

        <div className='flex flex-wrap items-center justify-center'>
          <p className='bg-amber-400 px-3 py-2 mr-3 text-white rounded-lg'>
            {props.formatSize}
          </p>
          <button
            className='min-w-9 w-9 h-9 flex items-center justify-center rounded-full bg-rose-500 hover:bg-rose-200 text-white'
            onClick={() => onTemplateRemove(file, props.onRemove)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className='flex flex-col items-center justify-center'>
        <FontAwesomeIcon icon={faFileAlt} className='text-3xl' />
        <p className='py-3 text-slate-500'>Drag and Drop Files Here</p>
      </div>
    );
  };

  const chooseOptions = {
    icon: <FontAwesomeIcon icon={faUpload} color='white' className='mr-2' />,
    iconOnly: false,
    className: "w-full md:w-max h-12",
    label: "Choose from computer"
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-save",
    iconOnly: false,
    className: "custom-upload-btn p-button-success",
    label: "Save changes"
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: false,
    className: "custom-cancel-btn p-button-danger",
    label: "Clear"
  };

  return (
    <div className='file-drop-zone'>
      <FileUpload
        ref={fileUploadRef}
        name='files'
        multiple
        accept='*'
        customUpload
        uploadHandler={handleSubmit}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        className='file-upload-multiple'
      />
      <div className='h-6' />
      <p>Existing files:</p>
      {existingFiles &&
        existingFiles.length > 0 &&
        existingFiles.map((b: any, idx: number) => (
          <div
            key={`file-${idx}`}
            className='flex flex-wrap justify-between border items-center p-4 my-2 rounded-lg bg-white'
          >
            <p>
              {b.name}
              {/* <small className="pl-3">{new Date().toLocaleDateString()}</small> */}
            </p>

            <div className='item-actions'>
              <Tag
                value={`${b.size / 100} KB`}
                severity='warning'
                className='px-3 py-2 mr-4'
              />
              <button
                type='button'
                onClick={() => {
                  const exists = [...existingFiles];
                  exists.splice(idx, 1);
                  setExistingFiles(exists);

                  const removed = [...removedFiles];
                  removed.push(b);
                  setRemovedFiles(removed);

                  if (autoSubmit) {
                    onSubmit({
                      uploadedFiles,
                      existingFiles: exists,
                      removedFiles: removed
                    });
                  }
                  setDisableSave(false);
                }}
                className='border border-esaRed rounded-full text-esaRed px-4 py-2'
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FileDropZone;
