import React, { Dispatch, SetStateAction } from "react";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PAGINATION } from "utils/types/general";

import DataTable from "components/common/DataTable";
import { ATTENDANCE } from "utils/types/attendance";
import { ATTENDANCE_TABLE_COLUMNS } from "utils/constants/attendance";
import moment from "moment";

interface Props {
  attendances: ATTENDANCE[];
  setAttendances: Dispatch<SetStateAction<ATTENDANCE[]>>;
  pagination?: PAGINATION;
  getData: CallableFunction;
}

const GRID_TEMPLATE =
  "grid grid-cols-1 md:grid-cols-[15%_20%_25%_10%_20%_10%] border-b";
const CELL = "p-4";

function AttendanceParentTable(props: Props) {
  const { attendances, setAttendances, pagination, getData } = props;

  return (
    <DataTable
      data={attendances}
      setData={setAttendances}
      headers={ATTENDANCE_TABLE_COLUMNS}
      gridStyle={`${GRID_TEMPLATE} hidden md:grid`}
      cellStyle={CELL}
      getData={getData}
      pagination={pagination}
    >
      {attendances.length > 0 ? (
        attendances.map((item: ATTENDANCE) => (
          <div key={item.id} className={`${GRID_TEMPLATE} py-2`}>
            <div className={`${CELL}`}>
              <p className='block md:hidden font-bold pb-4'>Date</p>
              {item.date ? (
                <p>{`${moment(item.date).format("DD/MM/YYYY")}`}</p>
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
            <div className={`${CELL}`}>
              <p className='block md:hidden font-bold pb-4'>Code élève</p>
              {item.codeEleve ? (
                <p>{item.codeEleve}</p>
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
            <div className={`${CELL}`}>
              <p className='block md:hidden font-bold pb-4'>Reason</p>
              {item.reason ? (
                <p>{item.reason}</p>
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
            <div className={`${CELL}`}>
              <p className='block md:hidden font-bold pb-4'>Term</p>
              {item.term ? (
                <p>{item.term}</p>
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
            <div className={`${CELL}`}>
              <p className='block md:hidden font-bold pb-4'>School Year</p>
              {item.schoolYear ? (
                <p>{item.schoolYear}</p>
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
            <div className={`${CELL}`}>
              <p className='block md:hidden font-bold pb-4'>Type</p>
              {item.type ? (
                <p className='capitalize'>{item.type}</p>
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className='text-rose-500'
                />
              )}
            </div>
          </div>
        ))
      ) : (
        <p className='pt-4 pl-4'>No records to show</p>
      )}
    </DataTable>
  );
}

export default AttendanceParentTable;
