import axios from "axios";
import { isAuth } from "utils/context";

const getPricesV2 = async (params?: any) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  const url = `${process.env.REACT_APP_API_URL}prices/v2`;
  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch {
    return error;
  }
};

const getInvoicePricesV2 = async () => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  const url = `${process.env.REACT_APP_API_URL}prices/v2/invoices`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch {
    return error;
  }
};

const search = async (options: any) => {
  const error = {
    error: true,
    status: 403,
    message: "Failed to search"
  };

  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}prices/v2/search`;
    try {
      const response = await axios.post(url, options);
      return response.data.data;
    } catch {
      return error;
    }
  }
  return error;
};

const add = async (price) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}prices/v2`;
    const response = await axios.post(url, price);

    return response.data.data;
  }
  return true;
};

const update = async (price) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}prices/v2/${price.id}`;
    const response = await axios.put(url, price);

    return response.data.data;
  }
  return true;
};

const deletebyId = async (id: string) => {
  if (isAuth()) {
    const url = `${process.env.REACT_APP_API_URL}prices/v2/${id}`;
    const response = await axios.delete(url);

    return response;
  }
  return true;
};

export { getPricesV2, search, add, update, deletebyId, getInvoicePricesV2 };
